import { useMediaQuery } from "react-responsive";

interface MediaProps {
  children: JSX.Element;
}

export function Desktop({ children }: MediaProps): JSX.Element | null {
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  return isDesktop ? children : null;
}

export function Tablet({ children }: MediaProps): JSX.Element | null {
  const isTablet = useMediaQuery({ minWidth: 781, maxWidth: 1023 });
  return isTablet ? children : null;
}

export function Mobile({ children }: MediaProps): JSX.Element | null {
  const isMobile = useMediaQuery({ maxWidth: 780 });
  return isMobile ? children : null;
}
