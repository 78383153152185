export const ContentData = [
  {
    title: '아이디어 제시',
    desc: '아이디어를 eEdu에 기록하여,\n전문가를 포함한 여러 사람들과\n함께 의견을 구체화해 보세요!',
    img: 'main_a.png'
  },
  {
    title: '채택',
    desc: '아이디어에 투표하고,\n토론방에 입장해서 의견을 나눠보세요!\n의견이 채택되면 해당 NFT의\n지분을 가질 수 있어요.',
    img: 'main_b.png'
  },
  {
    title: 'NFT 발급',
    desc: '최종적으로 아이디어가 채택되면\nNFT가 발급됩니다.\n원제시자는 50%의 지분을 가질 수 있어요!',
    img: 'main_c.png'
  }
];
