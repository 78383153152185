import styled from 'styled-components';
import { useState } from 'react';
import { Link } from 'react-router-dom';

function SignUp(): JSX.Element {
  const [expertIsHover, setExpertIsHover] = useState(false);
  const [generalIsHover, setGeneralIsHover] = useState(false);

  return (
    <Wrapper>
      <Container>
        <Title>회원가입 구분</Title>
        <Type>
          <TypeBox
            to="/sign-up/general"
            onMouseOver={() => setGeneralIsHover(true)}
            onMouseOut={() => setGeneralIsHover(false)}
          >
            <TypeLogo>
              <img
                src={
                  generalIsHover
                    ? require('assets/clicked-general-member.png')
                    : require('assets/general-member.png')
                }
                alt="member"
                height={100}
              />
            </TypeLogo>
            <TypeText>일반 회원</TypeText>
            <SignText>
              가입하기&nbsp;
              <svg
                width="10"
                height="10"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.4766 6.16664L6.00665 1.69664L7.18498 0.518311L13.6666 6.99998L7.18498 13.4816L6.00665 12.3033L10.4766 7.83331H0.333313V6.16664H10.4766Z"
                  fill={generalIsHover ? '#FFFFFF' : '#000000'}
                />
              </svg>
            </SignText>
          </TypeBox>
          <TypeBox
            to="/sign-up/expert"
            onMouseOver={() => setExpertIsHover(true)}
            onMouseOut={() => setExpertIsHover(false)}
          >
            <TypeLogo>
              <img
                src={
                  expertIsHover
                    ? require('assets/clicked-expert-member.png')
                    : require('assets/expert-member.png')
                }
                alt="member"
                height={100}
              />
            </TypeLogo>
            <TypeText>전문가 회원</TypeText>
            <SignText>
              가입하기&nbsp;
              <svg
                width="10"
                height="10"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.4766 6.16664L6.00665 1.69664L7.18498 0.518311L13.6666 6.99998L7.18498 13.4816L6.00665 12.3033L10.4766 7.83331H0.333313V6.16664H10.4766Z"
                  fill={expertIsHover ? '#FFFFFF' : '#000000'}
                />
              </svg>
            </SignText>
          </TypeBox>
        </Type>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  ${({ theme }) => theme.media.mobile} {
    width: 60%;
    margin: 0px;
    padding: 40px;
  }

  width: 750px;
  height: 500px;
  display: flex;
  margin: 100px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 15px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 3px 5px 0px ${({ theme }) => theme.colors.gray2};
`;

const Type = styled.div`
  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
  }

  display: flex;
  flex-direction: row;
`;

const Title = styled.div`
  display: flex;
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
  color: ${({ theme }) => theme.colors.main};
`;

const TypeBox = styled(Link)`
  ${({ theme }) => theme.media.mobile} {
    width: 200px;
    height: 180px;
    margin: 15px;
  }

  width: 220px;
  height: 210px;
  margin: 50px;
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  color: black;
  text-decoration: none;
  border-radius: 15px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 3px 5px 0px ${({ theme }) => theme.colors.gray2};
  :hover {
    background-color: ${({ theme }) => theme.colors.main};
    color: white;
  }
`;

const TypeLogo = styled.div`
  display: flex;
  align-items: center;
`;

const TypeText = styled.div`
  display: flex;
  padding-top: 18px;
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
`;

const SignText = styled.div`
  ${({ theme }) => theme.media.mobile} {
    margin: 20px 0px 0px 130px;
  }

  margin: 40px 0px 0px 150px;
  display: flex;
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.xxs};
`;

export default SignUp;
