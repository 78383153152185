import styled from 'styled-components';

import { PostProps } from './IdeaProps';
import { LabelItems } from 'components/sign-up/CategoryItems';
import { useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';

const Idea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 206px;
  height: 305px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 5px 3px 11px 0px rgba(17, 38, 146, 0.08);
`;

const CardHeader = styled.div`
  display: flex;
  width: 190px;
  height: 28pxp;
  justify-content: space-between;
`;

const Profile = styled.img`
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 30px;
  border: 0.5px solid ${({ theme }) => theme.colors.gray2};
`;

const Info = styled.div``;

const InfoDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4px;
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 31px;
  height: 28px;

  color: ${({ theme }) => theme.colors.gray5};
  background: #29bc49;
  border-radius: 15px;
  :hover {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.blue1};
  }
`;

const DebateSvg = styled.svg`
  fill: ${({ theme }) => theme.colors.white};
  width: 15px;
  height: 15px;
`;

const Category = styled.div`
  color: ${({ theme }) => theme.colors.gray6};
  font-size: 11px;
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
`;

const Writer = styled.div`
  color: ${({ theme }) => theme.colors.gray3};
  font-size: 11px;
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
`;

const Thumbnail = styled.img`
  margin: 10px 0;
  width: 190px;
  height: 185px;
  object-fit: cover;
  border-radius: 15px;
`;

const SummaryContainer = styled.div`
  display: grid;
  width: 100%;
  margin-top: 10px;
  grid-template-columns: 1fr 1fr 3fr;
`;

const Summary = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${({ theme }) => theme.colors.gray5};
  font-size: ${({ theme }) => theme.fonts.size.xxs};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};

  span {
    margin: 2px 20px 0 0;
    color: ${({ theme }) => theme.colors.gray3};
    font-size: ${({ theme }) => theme.fonts.size.xs};
  }
`;

const Svg = styled.svg`
  margin-right: 5px;
  width: 16px;
  height: 16px;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.gray6};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
`;

function Cards({
  id,
  post,
  debateId,
  user,
}: {
  id: string;
  post: PostProps;
  debateId: number;
  user?: { nickname: string; profile: string };
}): JSX.Element {
  const [isHover, setIsHover] = useState<boolean>(false);
  const navigate = useNavigate();

  // createdAt으로 마감 날짜 계산
  let dueDate = new Date(post.createdAt);
  dueDate.setDate(dueDate.getDate() + 14);
  const dueDateStr =
    dueDate.getFullYear() +
    (Number(dueDate.getMonth()) + 1 > 9 ? '-' : '-0') +
    (Number(dueDate.getMonth()) + 1) +
    (Number(dueDate.getDate()) > 9 ? '-' : '-0') +
    dueDate.getDate();

  const UserDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  const CardContentDiv = styled.div`
    width: 190px;
  `;

  return (
    <Idea>
      <CardContentDiv>
        <CardHeader>
          <UserDiv>
            {user ? (
              <Profile
                src={
                  user.profile.length > 0
                    ? process.env.REACT_APP_S3_BUCKET_URL + user.profile
                    : require('@assets/clicked-general-member.png')
                }
              />
            ) : (
              <Profile
                src={
                  post.author.profile
                    ? process.env.REACT_APP_S3_BUCKET_URL + post.author.profile
                    : require('@assets/clicked-general-member.png')
                }
              />
            )}
            <InfoDetailContainer>
              <Category>
                {post.interest_id
                  ? LabelItems.slice(1)[post.interest_id - 1].title
                  : post?.interestField.fieldName}
              </Category>
              <Writer>{user ? user.nickname : post.author.nickname}</Writer>
            </InfoDetailContainer>
          </UserDiv>

          <Info>
            {debateId && (
              <Link key={post.id} to={`/forum/idea?id=${debateId}`}>
                <Button
                  onMouseOver={() => setIsHover(true)}
                  onMouseOut={() => setIsHover(false)}
                >
                  {isHover ? (
                    <DebateSvg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.3998 0H9.59976C7.05368 0 4.61188 1.03736 2.81153 2.88387C1.01118 4.73038 -0.000245094 7.23479 -0.000245094 9.84615C-0.000245094 12.4575 1.01118 14.9619 2.81153 16.8084C4.61188 18.6549 7.05368 19.6923 9.59976 19.6923V24C15.5998 21.5385 23.9998 17.8462 23.9998 9.84615C23.9998 7.23479 22.9883 4.73038 21.188 2.88387C19.3876 1.03736 16.9458 0 14.3998 0V0ZM11.9998 17.2308H9.59976C8.65424 17.2308 7.71798 17.0398 6.84443 16.6687C5.97089 16.2975 5.17717 15.7536 4.50859 15.0679C3.84 14.3821 3.30966 13.5681 2.94782 12.6721C2.58599 11.7762 2.39976 10.8159 2.39976 9.84615C2.39976 8.87639 2.58599 7.91613 2.94782 7.02018C3.30966 6.12424 3.84 5.31017 4.50859 4.62444C5.17717 3.93872 5.97089 3.39477 6.84443 3.02366C7.71798 2.65255 8.65424 2.46154 9.59976 2.46154H14.3998C16.3093 2.46154 18.1407 3.23956 19.4909 4.62444C20.8412 6.00933 21.5998 7.88763 21.5998 9.84615C21.5998 14.2892 18.6454 17.1889 11.9998 20.2831V17.2308Z"
                        fill="white"
                      />
                      <ellipse
                        cx="7.19961"
                        cy="9.43595"
                        rx="1.6"
                        ry="1.64103"
                        fill="white"
                      />
                      <ellipse
                        cx="11.9999"
                        cy="9.43595"
                        rx="1.6"
                        ry="1.64103"
                        fill="white"
                      />
                      <ellipse
                        cx="16.7997"
                        cy="9.43595"
                        rx="1.6"
                        ry="1.64103"
                        fill="white"
                      />
                    </DebateSvg>
                  ) : (
                    <DebateSvg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      {/* <path
                        d="M14.3998 0H9.59976C7.05368 0 4.61188 1.03736 2.81153 2.88387C1.01118 4.73038 -0.000245094 7.23479 -0.000245094 9.84615C-0.000245094 12.4575 1.01118 14.9619 2.81153 16.8084C4.61188 18.6549 7.05368 19.6923 9.59976 19.6923V24C15.5998 21.5385 23.9998 17.8462 23.9998 9.84615C23.9998 7.23479 22.9883 4.73038 21.188 2.88387C19.3876 1.03736 16.9458 0 14.3998 0V0ZM11.9998 17.2308H9.59976C8.65424 17.2308 7.71798 17.0398 6.84443 16.6687C5.97089 16.2975 5.17717 15.7536 4.50859 15.0679C3.84 14.3821 3.30966 13.5681 2.94782 12.6721C2.58599 11.7762 2.39976 10.8159 2.39976 9.84615C2.39976 8.87639 2.58599 7.91613 2.94782 7.02018C3.30966 6.12424 3.84 5.31017 4.50859 4.62444C5.17717 3.93872 5.97089 3.39477 6.84443 3.02366C7.71798 2.65255 8.65424 2.46154 9.59976 2.46154H14.3998C16.3093 2.46154 18.1407 3.23956 19.4909 4.62444C20.8412 6.00933 21.5998 7.88763 21.5998 9.84615C21.5998 14.2892 18.6454 17.1889 11.9998 20.2831V17.2308Z"
                        fill="#3C3C3C"
                      />
                      <ellipse
                        cx="7.19961"
                        cy="9.43595"
                        rx="1.6"
                        ry="1.64103"
                        fill="#3C3C3C"
                      />
                      <ellipse
                        cx="11.9999"
                        cy="9.43595"
                        rx="1.6"
                        ry="1.64103"
                        fill="#3C3C3C"
                      />
                      <ellipse
                        cx="16.7997"
                        cy="9.43595"
                        rx="1.6"
                        ry="1.64103"
                        fill="#3C3C3C"
                      /> */}
                      <path
                        d="M14.3998 0H9.59976C7.05368 0 4.61188 1.03736 2.81153 2.88387C1.01118 4.73038 -0.000245094 7.23479 -0.000245094 9.84615C-0.000245094 12.4575 1.01118 14.9619 2.81153 16.8084C4.61188 18.6549 7.05368 19.6923 9.59976 19.6923V24C15.5998 21.5385 23.9998 17.8462 23.9998 9.84615C23.9998 7.23479 22.9883 4.73038 21.188 2.88387C19.3876 1.03736 16.9458 0 14.3998 0V0ZM11.9998 17.2308H9.59976C8.65424 17.2308 7.71798 17.0398 6.84443 16.6687C5.97089 16.2975 5.17717 15.7536 4.50859 15.0679C3.84 14.3821 3.30966 13.5681 2.94782 12.6721C2.58599 11.7762 2.39976 10.8159 2.39976 9.84615C2.39976 8.87639 2.58599 7.91613 2.94782 7.02018C3.30966 6.12424 3.84 5.31017 4.50859 4.62444C5.17717 3.93872 5.97089 3.39477 6.84443 3.02366C7.71798 2.65255 8.65424 2.46154 9.59976 2.46154H14.3998C16.3093 2.46154 18.1407 3.23956 19.4909 4.62444C20.8412 6.00933 21.5998 7.88763 21.5998 9.84615C21.5998 14.2892 18.6454 17.1889 11.9998 20.2831V17.2308Z"
                        fill="white"
                      />
                      <ellipse
                        cx="7.19961"
                        cy="9.43595"
                        rx="1.6"
                        ry="1.64103"
                        fill="white"
                      />
                      <ellipse
                        cx="11.9999"
                        cy="9.43595"
                        rx="1.6"
                        ry="1.64103"
                        fill="white"
                      />
                      <ellipse
                        cx="16.7997"
                        cy="9.43595"
                        rx="1.6"
                        ry="1.64103"
                        fill="white"
                      />
                    </DebateSvg>
                  )}
                </Button>
              </Link>
            )}
          </Info>
        </CardHeader>
        <Thumbnail src={post.thumbnail || 'error'} />
        <Title>
          {post.title.length > 14
            ? `${post.title.slice(0, 23)}...`
            : post.title}
        </Title>
        <SummaryContainer>
          <Summary>
            <Svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_709_543)">
                <path
                  d="M21.4094 15.0703L21.3646 14.9896L20.1722 12.802L18.9888 10.6413L18.9708 10.6055C18.666 10.0317 18.0743 9.67304 17.4377 9.66407L18.1191 8.98269C18.4688 8.62407 18.4688 8.05924 18.1191 7.70959L11.5653 1.1558C11.2156 0.806141 10.6508 0.806141 10.3012 1.1558L6.01565 5.44131C5.66599 5.79097 5.66599 6.36476 6.01565 6.71442L8.9653 9.66407H8.60668C8.02392 9.66407 7.49496 9.942 7.16323 10.3992C7.10944 10.462 7.06461 10.5337 7.02875 10.6055L5.82737 12.802L4.63496 14.9896L4.59013 15.0703C4.51841 15.1958 4.48254 15.3392 4.48254 15.4917V24.4572C4.48254 24.9592 4.88599 25.3537 5.3791 25.3537H20.6205C21.1136 25.3537 21.517 24.9592 21.517 24.4572V15.4917C21.517 15.3392 21.4812 15.1958 21.4094 15.0703ZM7.91634 6.07787L10.9288 3.06545L16.2184 8.34614L14.9005 9.66407L13.197 11.3586L7.91634 6.07787ZM8.60668 11.4572H10.7674L12.5694 13.2592C12.9191 13.6089 13.4839 13.6089 13.8336 13.2592L15.6356 11.4572H17.4019L19.1053 14.5951H6.89427L8.60668 11.4572ZM19.7239 23.5606H6.27565V16.3882H19.7239V23.5606Z"
                  fill="#8E8E8E"
                />
                <path
                  d="M16.1377 12.8018C16.1377 13.3039 15.7342 13.6984 15.2411 13.6984H10.7584C10.6239 13.6984 10.4894 13.6715 10.3729 13.6087C10.1846 13.528 10.0411 13.3756 9.95147 13.1873C9.89768 13.0708 9.86182 12.9453 9.86182 12.8018C9.86182 12.3087 10.2653 11.9053 10.7584 11.9053H11.2156L12.5694 13.2591C12.9191 13.6087 13.4839 13.6087 13.8335 13.2591L15.1873 11.9053H15.2411C15.7342 11.9053 16.1377 12.3087 16.1377 12.8018Z"
                  fill="#8E8E8E"
                />
              </g>
              <defs>
                <clipPath id="clip0_709_543">
                  <rect
                    width="17.0345"
                    height="24.4579"
                    fill="white"
                    transform="translate(4.48254 0.895508)"
                  />
                </clipPath>
              </defs>
            </Svg>

            <span>{post.votes ? post.votes.length : post.voteCount}</span>
          </Summary>
          <Summary />
          <>
            {post.dueDate ? (
              <Summary>
                마감까지
                {` ${post?.dueDate[0]}일 ${post?.dueDate[1]}시간 ${post?.dueDate[2]}분 `}
                남음
              </Summary>
            ) : (
              <Summary>{`채택마감 :${dueDateStr}`}</Summary>
            )}
          </>
        </SummaryContainer>
      </CardContentDiv>
    </Idea>
  );
}

export default Cards;
