import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { lock, unlock, clearBodyLocks } from 'tua-body-scroll-lock';
import styled from 'styled-components';
import { useLogout } from 'hooks/UseLogout';
import { useRecoilState } from 'recoil';
import { LoginState } from 'atoms/LoginState';
import { useCookies } from 'react-cookie';

import { NavItems } from './NavItems';

const Container = styled.div<{ opened: boolean }>`
  position: absolute;
  z-index: 100;
  /* background-color: #1f56f1; */
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: ${({ opened }) => (opened ? '130px' : '0px')};
  transition: right 0.3s ease-in-out;
`;

const Hamburger = styled.svg`
  cursor: pointer;
  path {
    fill: none;
    stroke: black;
    strokewidth: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
`;

const Line1 = styled.path<{ opened: boolean }>`
  stroke-dasharray: ${({ opened }) => (opened ? '90 207' : '60 207')};
  stroke-dashoffset: ${({ opened }) => (opened ? -134 : 0)};
`;

const Line2 = styled.path<{ opened: boolean }>`
  stroke-dasharray: ${({ opened }) => (opened ? '1 60' : '60 60')};
  stroke-dashoffset: ${({ opened }) => (opened ? -30 : 0)};
`;

const Line3 = styled.path<{ opened: boolean }>`
  stroke-dasharray: ${({ opened }) => (opened ? '90 207' : '60 207')};
  stroke-dashoffset: ${({ opened }) => (opened ? -134 : 0)};
`;

const Blur = styled.div`
  z-index: 50;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  backdrop-filter: blur(1.5px);
  background-color: rgb(0, 0, 0);
  transition: background-color 0.2s ease-in-out;
  animation: opacity 0.3s ease-in-out forwards;
  @keyframes opacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 33%;
    }
  }
`;

const MenuContainer = styled.div<{ opened: boolean }>`
  height: 100vh;
  width: 200px;
  right: ${({ opened }) => (opened ? 0 : '-300px')};
  z-index: 99;
  position: absolute;
  top: 0;
  /* background-color: ${({ theme }) => theme.colors.white}; */
  background-color: #1f56f1;
  transition: right 0.3s ease-in-out;
  overflow: hidden;
`;

const UserSection = styled.section`
  height: 80px;
  display: flex;
  padding-right: 20px;
  align-items: center;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  background-color: #1f56f1;
  font-size: ${({ theme }) => theme.fonts.size.xl};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  color: ${({ theme }) => theme.colors.gray2};
`;

const NavSection = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const NavBlock = styled.li`
  width: 100%;
`;

const NavLink = styled(Link)`
  display: flex;
  margin: 20px;
  align-items: center;
  justify-content: flex-start;
  color: black;
  font-size: ${({ theme }) => theme.fonts.size.md};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  :hover {
    color: #fff;
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
  }
`;

const LoginButton = styled(Link)`
  display: flex;
  color: black;
  font-size: ${({ theme }) => theme.fonts.size.md};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  :hover {
    color: #fff;
  }
`;

const LogoutButton = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.gray5};
  font-size: ${({ theme }) => theme.fonts.size.md};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  :hover {
    color: ${(props) => props.theme.colors.main};
  }
  cursor: pointer;
`;

const Profile = styled.img`
  width: 35px;
  height: 35px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 30px;
  border: 0.5px solid ${({ theme }) => theme.colors.gray2};
  cursor: pointer;
`;

function MobileNavigation(): JSX.Element {
  const [opened, setOpened] = useState<boolean>(false);
  const navRef = useRef(null);
  const { logout } = useLogout();
  const [loginState, setLoginState] = useRecoilState(LoginState);
  const [cookies] = useCookies(['USER-INFORMATION', 'ISADMIN']);
  const navigate = useNavigate();

  // ios 스크롤락에 대한 부분
  useEffect(() => {
    if (navRef.current) {
      if (opened) {
        lock(navRef.current);
      } else {
        unlock(navRef.current);
      }
    }
    return () => {
      clearBodyLocks();
    };
  }, [opened, navRef]);

  const handleLogout = () => {
    logout();
    setLoginState(false);
  };

  return (
    <>
      <Container opened={opened}>
        <Hamburger
          onClick={() => setOpened((prev) => !prev)}
          width="36"
          height="36"
          viewBox="0 0 100 100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Line1
            opened={opened}
            d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
          />
          <Line2 opened={opened} d="M 20,50 H 80" />
          <Line3
            opened={opened}
            d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
          />
        </Hamburger>
      </Container>
      {opened && <Blur onClick={() => setOpened(false)} />}
      <MenuContainer ref={navRef} opened={opened}>
        <UserSection>
          {loginState ? (
            <>
              <Profile
                src={
                  cookies['ISADMIN'] === 'true'
                    ? require('@assets/clicked-general-member.png')
                    : cookies['USER-INFORMATION']
                    ? process.env.REACT_APP_S3_BUCKET_URL +
                      cookies['USER-INFORMATION'].profile
                    : require('@assets/clicked-general-member.png')
                }
                onClick={() => {
                  navigate(
                    cookies['ISADMIN'] === 'true'
                      ? '/admin/user-management?page=1'
                      : `/mypage/idea?id=${cookies['USER-INFORMATION']?.id}&page=1`
                  );
                }}
              />
              <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
            </>
          ) : (
            <LoginButton to="/login" onClick={() => setOpened(false)}>
              Login
            </LoginButton>
          )}
        </UserSection>
        <NavSection>
          {NavItems.map((item) => (
            <NavBlock key={item.id}>
              <NavLink to={item.path}>{item.title}</NavLink>
            </NavBlock>
          ))}
          <div style={{ width: '100%', height: 80 }} />
        </NavSection>
      </MenuContainer>
    </>
  );
}

export default MobileNavigation;
