import { Avatar } from 'antd';
import axios from 'axios';
import { useRef, useState, KeyboardEvent, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import qs from 'qs';
import Modal from 'components/mypage/Modal';
import ApplicationModal from './ApplicationModal';
import { useCookies } from 'react-cookie';
import imageCompression from 'browser-image-compression';
import { useLogout } from 'hooks/UseLogout';
import { UserProps, ExpertProps } from './PrivacyInterface';
import ApplyExpertModal from './ApplyExpertModal';
import { useRecoilState } from 'recoil';
import { ApplyExpertModalState, InformationFormState } from 'atoms/ModalState';
import { FileProps } from './PrivacyInterface';
import { LabelItems } from '@components/sign-up/CategoryItems';

import UploadBox from '@components/community/UploadBox';

//userID
import { UserIdState } from 'atoms/UserIdState';

interface SvgProps {
  children?: JSX.Element | JSX.Element[];
}

const passwordRegex =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/;

function Privacy(): JSX.Element {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const fileInput = useRef<HTMLInputElement>(null);
  const [userId, setUserId] = useState<string>('');
  const [informationForm, setInformationForm] = useState<UserProps>();
  const [expertInformation, setExpertInformation] = useState<ExpertProps[]>([]);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenApplication, setIsOpenApplication] = useRecoilState<boolean>(
    ApplyExpertModalState
  );

  const [userIdState, setUserIdState] = useRecoilState(UserIdState);

  const [isPatched, setIsPatched] = useState<boolean>(false);
  const [cookies, setCookie] = useCookies(['TOKEN', 'USER-INFORMATION']);
  const { logout } = useLogout();

  //modal
  // const [isOpenApplication, setIsOpenApplication] = useState(false);
  const [files, setFiles] = useState<FileProps[]>([]);
  const [field, setField] = useState<string>();
  const [fieldId, setFieldId] = useState<number>(0);
  const [genderState, setGenderState] = useState('F');

  const [countList, setCountList] = useState<number[]>([0]);

  const [passwordState, setPasswordState] = useState({
    checkPassword: '',
    errMsg: '',
    isAvailable: false,
    verified: false,
  });

  const [profileImage, setProfileImage] = useState<string>();
  const [profileFile, setProfileFile] = useState<File>();

  const [nicknameState, setNicknameState] = useState({
    success: false,
    message: '',
  });

  console.log('informationForm', informationForm?.gender);

  const handleRequestExpert = async () => {
    const form = new FormData();

    if (files.length === 0) {
      return [alert('파일을 등록해주세요.'), false];
    }

    form.append('interestField', fieldId?.toString());
    for (let i = 0; i < files.length; i++) {
      form.append('attachments', files[i].file);
    }
    form.append('userId', userId);

    await axios
      .post('/expert', form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        setIsOpenApplication(!isOpenApplication);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getFieldId = (field: string) => {
    LabelItems.slice(1).forEach((value, index) => {
      if (value.title === field) {
        setFieldId(value.id);
      }
    });
  };

  const onAddHandler = () => {
    if (files.length !== countList.length) {
      alert('파일을 첨부한 후 클릭하세요.');
      return;
    }
    let countArr = [...countList];
    let counter = countArr.slice(-1)[0];
    counter += 1;
    countArr.push(counter);
    setCountList(countArr);
  };

  const onRemove = (idx: number) => {
    let countArr = countList.filter((countList) => countList !== idx);
    countArr.forEach((value, index) => {
      countArr[index] = index;
    });
    setCountList(countArr);
  };

  const checkFiles = (files: FileProps[]) => {
    setFiles(files);
  };

  const patchUserInformation = async () => {
    if (validate()) {
      const form = new FormData();

      if (profileFile) {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1024,
        };
        const compressedFile = await imageCompression(profileFile, options);
        form.append('profile', compressedFile);
      }
      form.append('nickname', String(informationForm?.nickname));
      form.append('password', String(informationForm?.password));
      form.append('gender', genderState);

      // 아래 gender항목 form-data에 추가해서 요청 보내주세요 ("F", "M" 둘만 선택 가능)
      // form.append("gender", "F");

      try {
        const { data } = await axios({
          method: 'patch',
          url: `/user/${userId}`,
          data: form,
          headers: {
            Authorization: `Bearer ${cookies['TOKEN']}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        if (data.success) {
          setNicknameState({
            success: false,
            message: '',
          });
          setPasswordState({
            checkPassword: '',
            errMsg: '',
            isAvailable: false,
            verified: false,
          });
          setIsPatched(!isPatched);
          alert('변경되었습니다.');
          patchCookie();
        } else {
          alert('변경 중 오류가 발생하였습니다.');
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const patchCookie = async () => {
    const { data } = await axios({
      method: 'get',
      url: `user/${cookies['USER-INFORMATION'].id}`,
    });
    let copiedInfo = { ...cookies['USER-INFORMATION'] };
    copiedInfo.nickname = data.data.nickname;
    copiedInfo.profile = data.data.profile;
    setCookie('USER-INFORMATION', copiedInfo);
  };

  const getUserInformation = useCallback(
    async (userId: string) => {
      try {
        const { data } = await axios({
          method: 'get',
          url: `/user/${userId}`,
        });
        setInformationForm(data.data);
      } catch {
        navigate(-1);
      }
    },
    [navigate]
  );

  const getExpertInformation = useCallback(
    async (userId: string) => {
      try {
        const { data } = await axios({
          method: 'get',
          url: `/expert/${cookies['USER-INFORMATION'].id}`,
        });
        setExpertInformation(data.data);
      } catch (e) {
        navigate(-1);
        console.log(e);
      }
    },
    [navigate]
  );

  const withdrawal = useCallback(async () => {
    try {
      if (window.confirm('탈퇴하시겠습니까?')) {
        const { data } = await axios({
          method: 'delete',
          url: `user/${cookies['USER-INFORMATION'].id}`,
          headers: {
            Authorization: `Bearer ${cookies['TOKEN']}`,
          },
        });
        logout();
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    navigate(`/mypage/privacy?id=${cookies['USER-INFORMATION'].id}&page=1`);
  }, [navigate]);

  const handleProfileImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = (e.currentTarget.files as FileList)[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setProfileFile(file);
      setProfileImage(reader.result as string);
    };
  };

  const handlePasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setInformationForm((prev: any) => ({ ...prev, password: value }));
    setPasswordState((prev) => ({
      ...prev,
      isAvailable: passwordRegex.test(value) ? true : false,
    }));
  };

  const handleCheckPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setPasswordState((prev) => ({ ...prev, checkPassword: value }));

    if (value === informationForm?.password && passwordRegex.test(value)) {
      setPasswordState((prev) => ({
        ...prev,
        errMsg: '비밀번호가 일치합니다.',
        verified: true,
      }));
    } else {
      setPasswordState((prev) => ({
        ...prev,
        errMsg: '비밀번호가 일치하지 않습니다.',
        verified: false,
      }));
    }
  };

  const handleNicknameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setInformationForm((prev: any) => ({ ...prev, nickname: value }));
  };

  const checkNickname = async () => {
    const { data } = await axios({
      method: 'post',
      url: `/user/check`,
      data: qs.stringify({
        nickname: informationForm?.nickname,
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    if (data.data) {
      setNicknameState({ success: false, message: '중복된 닉네임입니다.' });
    } else {
      setNicknameState({
        success: true,
        message: '사용 가능한 닉네임입니다.',
      });
    }
  };

  const handleNicknameCheckPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      checkNickname();
    }
  };

  const validate = (): boolean => {
    if (informationForm?.profile === '') {
      alert('프로필 사진을 첨부해주세요.');
      return false;
    }
    if (informationForm?.password === '') {
      alert('비밀번호를 입력해주세요.');
      return false;
    } else if (!passwordState.isAvailable || !passwordState.verified) {
      alert('비밀번호를 확인해주세요.');
      return false;
    }
    if (informationForm?.nickname === '') {
      alert('닉네임을 입력해주세요.');
      return false;
    } else if (!nicknameState.success && nicknameState.message !== '') {
      alert('닉네임을 확인해주세요.');
      return false;
    }
    return true;
  };

  useEffect(() => {
    const userId = String(searchParams.get('id'));
    setUserId(userId);
    getUserInformation(userId);
    getExpertInformation(userId);
    setUserIdState(userId);
  }, [searchParams, isPatched, getUserInformation, getExpertInformation]);

  useEffect(() => {
    getExpertInformation(userId);
  }, [isOpenApplication, getExpertInformation]);

  const genderColor = () => {
    if (informationForm?.gender === 'F') {
      return 'theme.colors.gray0';
    }
    if (informationForm?.gender === 'M') {
      return 'theme.colors.gray0';
    }
  };

  const handleGender = (e: any) => {
    setGenderState(e.target.value);
  };

  return (
    <>
      {isOpenApplication && (
        <ApplicationModal>
          <ApplicationModalContainer>
            <Svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setIsOpenApplication(!isOpenApplication)}
            >
              <g clipPath="url(#clip0_837_1146)">
                <path
                  d="M12.0002 10.5857L16.9502 5.63574L18.3642 7.04974L13.4142 11.9997L18.3642 16.9497L16.9502 18.3637L12.0002 13.4137L7.05023 18.3637L5.63623 16.9497L10.5862 11.9997L5.63623 7.04974L7.05023 5.63574L12.0002 10.5857Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_837_1146">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </Svg>
            <ApplicationBody>
              <Title>전문가신청</Title>
              <Description>
                전문성 분야를 선택하여 고객님의 전문성을 인증받으세요.
              </Description>
              <InnerContainer>
                <FieldContainer>
                  <Column>분야</Column>
                  <ApplicationInputContainer>
                    <Select
                      name="field"
                      value={field}
                      onChange={({ currentTarget }) => {
                        setField(currentTarget.value);
                        getFieldId(currentTarget.value);
                      }}
                    >
                      <option disabled value="" defaultValue="">
                        {`분야를 선택해주세요.`}
                      </option>
                      {LabelItems?.map((item, idx) => {
                        return (
                          <option key={idx} value={item.title}>
                            {item.title}
                          </option>
                        );
                      })}
                    </Select>
                    <Dropdown
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_592_2077)">
                        <path
                          d="M12 13.1722L16.95 8.22217L18.364 9.63617L12 16.0002L5.63599 9.63617L7.04999 8.22217L12 13.1722Z"
                          fill="#8E8E8E"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_592_2077">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </Dropdown>
                  </ApplicationInputContainer>
                </FieldContainer>
                <WarningText>
                  ⚠️ 허위 서류를 첨부하는 경우 법적 처벌을 받을 수 있습니다.
                </WarningText>
                <UploadBox
                  countList={countList}
                  onRemove={onRemove}
                  getFiles={checkFiles}
                />
                <AddButton onClick={onAddHandler}>
                  + 더 많은 파일을 첨부하려면 여기를 클릭하세요.
                </AddButton>
              </InnerContainer>
              <ButtonContainer>
                <Button onClick={() => handleRequestExpert()}>확인</Button>
              </ButtonContainer>
            </ApplicationBody>
          </ApplicationModalContainer>
        </ApplicationModal>
      )}
      {isOpenModal && (
        <Modal>
          <>
            <ModalContainer isExpert={expertInformation[0]?.userId.isExpert}>
              <Svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setIsOpenModal(!isOpenModal)}
              >
                <g clipPath="url(#clip0_837_1146)">
                  <path
                    d="M12.0002 10.5857L16.9502 5.63574L18.3642 7.04974L13.4142 11.9997L18.3642 16.9497L16.9502 18.3637L12.0002 13.4137L7.05023 18.3637L5.63623 16.9497L10.5862 11.9997L5.63623 7.04974L7.05023 5.63574L12.0002 10.5857Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_837_1146">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </Svg>
              <ModalBody>
                <FormTitle>전문가 여부</FormTitle>
                <CheckContainer>
                  <ModalInputContainer readOnly={false}>
                    <Input
                      value={informationForm?.expertField ? 'O' : 'X'}
                      readOnly
                    />
                  </ModalInputContainer>
                  <ButtonContainer>
                    <CheckButton
                      onClick={() => {
                        setIsOpenModal(!isOpenModal);
                        setIsOpenApplication(!isOpenApplication);
                      }}
                      disabled={informationForm?.expertField ? true : false}
                      expert={informationForm?.expertField ? true : false}
                    >
                      전문가 인증
                    </CheckButton>
                  </ButtonContainer>
                </CheckContainer>
                {expertInformation[0]?.state === 2 ? (
                  <>
                    <FormTitle>전문가 분야</FormTitle>
                    <ModalInputContainer readOnly={false}>
                      <Input
                        value={informationForm?.expertField.fieldName}
                        readOnly
                      />
                    </ModalInputContainer>
                  </>
                ) : (
                  <>
                    <FormTitle>상태</FormTitle>
                    <ModalInputContainer readOnly={false}>
                      <Input
                        value={
                          expertInformation[0].state === 1
                            ? '전문가 승인 처리중'
                            : '전문가 승인 거절'
                        }
                        readOnly
                      />
                    </ModalInputContainer>
                    {expertInformation[0].state === 3 && (
                      <>
                        <FormTitle>승인 거절 사유</FormTitle>
                        <ModalInputContainer readOnly={false}>
                          <Input value={expertInformation[0].reject} readOnly />
                        </ModalInputContainer>
                      </>
                    )}
                  </>
                )}
              </ModalBody>
            </ModalContainer>
          </>
        </Modal>
      )}

      <Wrapper>
        <PrivacyDiv>
          <Header>
            <Title>내 정보 수정</Title>
            <Description>
              내가 가입한 정보를 확인하고 수정할 수 있습니다.
            </Description>
          </Header>
          <Body>
            <Container>
              <Title>회원정보</Title>
              <ImageContainer>
                <ImageBox>
                  <ProfileImage
                    src={
                      profileImage
                        ? profileImage
                        : `${process.env.REACT_APP_S3_BUCKET_URL}${informationForm?.profile}`
                    }
                    size={120}
                  />
                  <CameraButton
                    src={require('@assets/camera-button.png')}
                    onClick={() => {
                      fileInput.current?.click();
                    }}
                  />
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    accept="image/*"
                    name="profile_img"
                    onChange={handleProfileImage}
                    ref={fileInput}
                  />
                </ImageBox>
              </ImageContainer>
              <FormContainer>
                <FormTitle>아이디</FormTitle>
                <InputContainer readOnly={true}>
                  <Input value={informationForm?.userId || ''} readOnly />
                </InputContainer>
                <FormTitle>비밀번호</FormTitle>
                <InputContainer readOnly={false}>
                  <Input
                    type="password"
                    placeholder="비밀번호를 입력해 주세요."
                    value={informationForm?.password || ''}
                    onChange={handlePasswordInput}
                  />
                </InputContainer>
                <FormTitle>비밀번호 확인</FormTitle>
                <div>
                  <InputContainer readOnly={false}>
                    <Input
                      type="password"
                      placeholder="비밀번호를 한 번 더 입력해 주세요."
                      value={passwordState.checkPassword || ''}
                      onChange={handleCheckPassword}
                    />
                  </InputContainer>
                  <PasswordDescription
                    isAvailable={
                      informationForm?.password
                        ? passwordState.isAvailable
                        : true
                    }
                  >
                    {passwordState.isAvailable ? (
                      passwordState.checkPassword && (
                        <Message success={passwordState.verified}>
                          {passwordState.errMsg}
                        </Message>
                      )
                    ) : (
                      <span>
                        8자 이상 ~ 20자 이내로 영문자와 숫자, 특수문자를
                        포함해주세요.
                      </span>
                    )}
                  </PasswordDescription>
                </div>
                <FormTitle>닉네임</FormTitle>
                <CheckContainer>
                  <SubContainer>
                    <InputContainer readOnly={false}>
                      <Input
                        placeholder="닉네임을 입력해 주세요."
                        value={informationForm?.nickname || ''}
                        onChange={handleNicknameInput}
                        onKeyPress={handleNicknameCheckPress}
                      />
                    </InputContainer>
                    <Message success={nicknameState.success}>
                      {nicknameState.message}
                    </Message>
                  </SubContainer>
                  <ButtonContainer>
                    <CheckButton onClick={checkNickname} expert={false}>
                      중복확인
                    </CheckButton>
                  </ButtonContainer>
                </CheckContainer>
                <FormTitle>이름</FormTitle>
                <InputContainer readOnly={true}>
                  <Input value={informationForm?.name || ''} readOnly />
                </InputContainer>
                <FormTitle>전문가 여부</FormTitle>
                <CheckContainer>
                  <InputContainer readOnly={false}>
                    <Input
                      value={informationForm?.expertField ? 'O' : 'X'}
                      readOnly
                    />
                  </InputContainer>
                  <ButtonContainer>
                    <CheckButton
                      onClick={() => {
                        expertInformation.length > 0
                          ? setIsOpenModal(!isOpenModal)
                          : setIsOpenApplication(!isOpenApplication);
                      }}
                      expert={false}
                    >
                      {expertInformation.length > 0
                        ? '확인 및 수정'
                        : '신청하기'}
                    </CheckButton>
                  </ButtonContainer>
                </CheckContainer>
                <FormTitle>성별</FormTitle>
                <GenderInputContainer>
                  <Select onChange={handleGender}>
                    <option selected hidden>
                      {informationForm?.gender === 'F' ? '여자' : '남자'}
                    </option>

                    <option value="M">남자</option>
                    <option value="F">여자</option>
                  </Select>
                </GenderInputContainer>
                <FormTitle>생년월일</FormTitle>
                <InputContainer readOnly={true}>
                  <Input
                    value={
                      informationForm?.birth.replace(
                        /(\d{2})(\d{2})(\d{2})/,
                        '$1-$2-$3'
                      ) || ''
                    }
                    readOnly
                  />
                </InputContainer>
                <FormTitle>이메일</FormTitle>
                <InputContainer readOnly={true}>
                  <Input value={informationForm?.email || ''} readOnly />
                </InputContainer>
                <FormTitle>휴대폰번호</FormTitle>
                <InputContainer readOnly={true}>
                  <Input
                    value={
                      informationForm?.phonenumber.replace(
                        /(\d{3})(\d{4})(\d{4})/,
                        '$1-$2-$3'
                      ) || ''
                    }
                    readOnly
                  />
                </InputContainer>
              </FormContainer>
              <ButtonContainer>
                <Button onClick={() => patchUserInformation()}>수정</Button>
              </ButtonContainer>
            </Container>
          </Body>
          <Footer>
            <WithdrawalConatiner>
              <WithdrawalButton onClick={withdrawal}>회원탈퇴</WithdrawalButton>
            </WithdrawalConatiner>
          </Footer>
        </PrivacyDiv>
      </Wrapper>
    </>
  );
}

const PrivacyDiv = styled.div`
  width: 884px;
  margin-top: 80px;
  margin-bottom: 80px;
`;

const Header = styled.header`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
`;

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }
  ${({ theme }) => theme.media.tablet} {
    width: 450px;
  }
  width: 600px;
  display: flex;
  margin: 30px 0;
  padding: 40px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 15px;
  flex-direction: column;
  box-shadow: 2px 3px 5px 0px ${({ theme }) => theme.colors.gray2};
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.lg};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  color: ${({ theme }) => theme.colors.gray6};
  margin-bottom: 10px;
`;

const Description = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.base};
  color: ${({ theme }) => theme.colors.gray4};
`;

const FormContainer = styled.div`
  display: grid;
  position: relative;
  margin: 20px;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: 55px 55px 65px 65px 55px 65px 55px 55px 55px 55px;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.media.mobile} {
    grid-template-columns: 1.5fr 4fr;
    margin: 20px -10px;
  }
`;

const GenderInputContainer = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray1};
  align-items: center;
  justify-content: center;
`;

const FormTitle = styled.span`
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  font-size: ${({ theme }) => theme.fonts.size.sm};
`;

const InputContainer = styled.div<{ readOnly: any }>`
  display: flex;
  background-color: ${({ theme, readOnly }) =>
    readOnly ? theme.colors.gray0 : theme.colors.white};
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray1};
  align-items: center;
  justify-content: center;
`;

const ModalInputContainer = styled.div<{ readOnly: boolean }>`
  display: flex;
  background-color: ${({ theme, readOnly }) =>
    readOnly ? theme.colors.gray0 : theme.colors.white};
  width: 100%;
  height: 40px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
`;

const Gender = styled.select<any>`
  all: unset;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 40px;
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray1};
  color: #838383;
  font-size: ${({ theme }) => theme.fonts.size.xs};
  background-color: ${(props) => props.bgColor};
  option {
    font-size: 10px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const Input = styled.input.attrs({
  required: true,
})`
  all: unset;
  height: 40px;
  width: 100%;
  padding: 0 10px;
  transition: width 0.2s ease-in-out;
  color: ${({ theme }) => theme.colors.gray6};
  font-size: ${({ theme }) => theme.fonts.size.xs};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageBox = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
`;

const ProfileImage = styled(Avatar)`
  display: flex;
  margin: 20px;
  width: 130px;
  height: 130px;
  border: 2px solid ${({ theme }) => theme.colors.gray2};
`;

const CameraButton = styled.img`
  position: absolute;
  width: 30px;
  height: 30px;
  top: 105px;
  left: 105px;
  cursor: pointer;
`;

const PasswordDescription = styled.div<{ isAvailable: boolean }>`
  font-size: ${({ theme }) => theme.fonts.size.xxs};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  color: ${({ isAvailable, theme }) =>
    isAvailable ? theme.colors.gray3 : theme.colors.error};
  margin-top: 5px;
  span {
    margin-left: 10px;
  }
`;

const Message = styled.span<{ success: boolean }>`
  margin-left: 10px;
  font-size: ${({ theme }) => theme.fonts.size.xxs};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  color: ${({ success, theme }) =>
    success ? theme.colors.blue2 : theme.colors.error};
`;

const CheckContainer = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
`;

const SubContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CheckButton = styled.button<{ expert: boolean }>`
  display: flex;
  white-space: nowrap;
  height: 34px;
  padding: 10px;
  margin-left: 10px;
  background-color: ${(props) =>
    props.expert
      ? ({ theme }) => theme.colors.gray0
      : ({ theme }) => theme.colors.gray1};
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid
    ${(props) =>
      props.expert
        ? ({ theme }) => theme.colors.gray1
        : ({ theme }) => theme.colors.gray2};
  color: ${(props) =>
    props.expert
      ? ({ theme }) => theme.colors.gray3
      : ({ theme }) => theme.colors.gray6};
  font-size: ${({ theme }) => theme.fonts.size.xs};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  cursor: ${(props) => (props.expert ? 'default' : 'pointer')};
  :hover {
    background-color: ${(props) =>
      props.expert
        ? ({ theme }) => theme.colors.gray0
        : ({ theme }) => theme.colors.gray2};
  }
`;

const ModalContainer = styled.div<{ isExpert: any }>`
  display: flex;
  width: 80%;
  height: ${({ isExpert }) => (isExpert ? '200px' : '250px')};
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Svg = styled.svg`
  display: flex;
  width: 24px;
  height: 24px;
  position: fixed;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

const ModalBody = styled.div`
  width: 100%;
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  justify-content: center;
  div {
    margin: 5px 0;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WithdrawalConatiner = styled.div`
  ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }
  ${({ theme }) => theme.media.tablet} {
    width: 530px;
  }
  width: 680px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const WithdrawalButton = styled.div`
  color: ${({ theme }) => theme.colors.gray4};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  cursor: pointer;
  margin: 0 10px;
`;

const Button = styled.button`
  width: 200px;
  height: 40px;
  border-radius: 10px;
  cursor: pointer;
  border: 0px;
  background-color: ${({ theme }) => theme.colors.main};
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.media.mobile} {
    width: 80px;
  }
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.sm};
`;

//모달

const ApplicationModalContainer = styled.div<SvgProps>`
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ApplicationBody = styled.div`
  ${({ theme }) => theme.media.mobile} {
    width: 240px;
  }
  width: 650px;
  display: flex;
  border-radius: 15px;
  flex-direction: column;
`;

const Column = styled.div`
  display: flex;
  place-content: flex-start;
  place-items: center;
  font-size: ${({ theme }) => theme.fonts.size.md};
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
`;

const ApplicationInputContainer = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
`;

const Select = styled.select`
  all: unset;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  background-color: transparent;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray1};
  display: block;
  align-items: center;
  justify-content: flex-start;
  &:focus {
    border-color: ${({ theme }) => theme.colors.main};
  }
  z-index: 3;
`;

const Dropdown = styled.svg`
  margin-left: -24px;
  width: 24px;
  height: 24px;
`;

const WarningText = styled.span`
  font-size: ${({ theme }) => theme.fonts.size.xs};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  color: ${({ theme }) => theme.colors.gray3};
`;

const AddButton = styled.div`
  display: flex;
  margin-left: 130px;
  width: 270px;
  color: ${({ theme }) => theme.colors.gray4};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  cursor: pointer;
  ${({ theme }) => theme.media.mobile} {
    margin-left: 0px;
    margin-top: 10px;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 30px;
`;

const FieldContainer = styled.div`
  display: grid;
  margin: 20px 0;
  grid-template-columns: 1fr 3fr 1fr;
`;

export default Privacy;
