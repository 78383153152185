import styled from "styled-components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import AuthorityModal from "../AuthorityModal";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 45px 0 80px 0;
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ContainerBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 420px;
  background: ${({ theme }) => theme.colors.gray7};
  padding: 30px;
  border-radius: 10px;
`;

const Stamp = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  opacity: 0.7;
`;

const Title = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
  font-size: ${({ theme }) => theme.fonts.size.md};
  margin-bottom: 10px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

const Logo = styled.div`
  display: flex;
`;

const ExplanationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0 0 20px;
`;

const TokenNumber = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  font-size: ${({ theme }) => theme.fonts.size.md};
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
`;

const Button = styled.button`
  height: 35px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.blue1};
  width: 150px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.blue1};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.sm};
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const DetailRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: space-between;
`;

const DetailTitle = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
  font-size: ${({ theme }) => theme.fonts.size.base};
`;

const DetailContent = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.base};
  color: ${({ theme }) => theme.colors.gray3};
  margin-left: 30px;
`;

const EtherButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  margin-top: 10px;
`;

const EtherButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blue1};
  width: 100px;
  color: ${({ theme }) => theme.colors.main};
  background-color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.sm};
`;

const Svg = styled.div`
  color: ${({ theme }) => theme.colors.main};
  margin-right: 2px;
`;

function AdoptedIdea({
  debate,
  ownerProps,
}: {
  debate: any;
  ownerProps: string;
}): JSX.Element {
  const navigate = useNavigate();
  const [cookies] = useCookies(["TOKEN", "USER-INFORMATION"]);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const checkAccessibleUser = async () => {
    try {
      const { data } = await axios({
        method: "get",
        url: `/debate-access/one/${debate.tokenId}`,
        headers: {
          Authorization: `Bearer ${cookies["TOKEN"]}`,
        },
      });

      if (data.success === true && data.data === true) {
        navigate(`/forum/idea?id=${debate.tokenId}`);
      } else {
        setIsOpenModal(!isOpenModal);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {isOpenModal && (
        <AuthorityModal
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
        />
      )}
      <Wrapper>
        <Box>
          <ContainerBox>
            <Container>
              <Title>채택이 완료된 아이디어 입니다!</Title>
              <Stamp>
                <img
                  src={require("assets/adopted-mark.png")}
                  alt="member"
                  height={140}
                />
              </Stamp>
              <ContentContainer>
                <Logo>
                  <img
                    src={require("assets/rectangle-logo.png")}
                    alt="member"
                    height={140}
                  />
                </Logo>
                <ExplanationContainer>
                  <TokenNumber>IDEA TOKEN #{debate.tokenId}</TokenNumber>
                  <ButtonContainer>
                    <Button
                      onClick={() => {
                        checkAccessibleUser();
                      }}
                    >
                      토론방 바로가기
                    </Button>
                  </ButtonContainer>
                  <DetailContainer>
                    <DetailRowContainer>
                      <DetailTitle>Owned by</DetailTitle>
                      <DetailContent>{ownerProps}</DetailContent>
                    </DetailRowContainer>
                    <DetailRowContainer>
                      <DetailTitle>Minted at</DetailTitle>
                      <DetailContent>
                        {debate.createdAt.substring(0, 4) +
                          "년 " +
                          debate.createdAt.substring(5, 7) +
                          "월 " +
                          debate.createdAt.substring(8, 10) +
                          "일"}
                      </DetailContent>
                    </DetailRowContainer>
                  </DetailContainer>
                </ExplanationContainer>
              </ContentContainer>
            </Container>
          </ContainerBox>
          <EtherButtonContainer>
            <EtherButton
              onClick={() =>
                window.open(
                  `https://goerli.etherscan.io/tx/${debate.txHash}`,
                  "_blank"
                )
              }
            >
              <Svg>
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 101 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M80.8074 93.8876H7.47404V18.8876H47.474V12.2209H0.807373V100.554H87.474V53.8876H80.8074V93.8876Z"
                    fill="#1F56F1"
                  />
                  <path
                    d="M60.8074 0.554199V7.22087H89.4267L36.7838 59.864L41.4976 64.5777L94.1407 11.9348V40.5542H100.807V0.554199H60.8074Z"
                    fill="#1F56F1"
                  />
                </svg>
              </Svg>
              <div>EtherScan</div>
            </EtherButton>
          </EtherButtonContainer>
        </Box>
      </Wrapper>
    </>
  );
}

export default AdoptedIdea;
