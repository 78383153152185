import styled from "styled-components";
import { useState, useEffect } from "react";

import { LabelProps } from "./FieldProps";
import { LabelItems } from "./CategoryItems";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  ${({ theme }) => theme.media.mobile} {
    width: 240px;
  }
  padding-top: 30px;
  padding: 40px;
  width: 650px;
  display: flex;
  margin: 100px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 15px;
  flex-direction: column;
  box-shadow: 2px 3px 5px 0px ${({ theme }) => theme.colors.gray2};
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.md};
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
`;

const Explanation = styled.div`
  padding: 10px 0;
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  color: ${({ theme }) => theme.colors.gray5};
`;

const TagContainer = styled.div`
  padding: 30px 10px 80px 10px;
  ${({ theme }) => theme.media.mobile} {
    padding: 0px;
  }
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin: 15px 0;
`;

const TagLabel = styled.label<{ check: boolean }>`
  padding: 5px 10px;
  margin: 3px;
  font-size: ${({ theme }) => theme.fonts.size.xs};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.colors.blue3};
    color: ${({ theme }) => theme.colors.white};
  }
  background-color: ${({ check, theme }) =>
    check ? theme.colors.blue1 : theme.colors.gray1};
  color: ${({ check, theme }) => check && theme.colors.white};
`;

const Tag = styled.input`
  appearance: none;
`;

const Buttons = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

const NextButton = styled.button`
  height: 40px;
  margin: 0 5px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.main};
  width: 140px;
  margin-right: 10px;
  ${({ theme }) => theme.media.mobile} {
    width: 130px;
  }
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.main};
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.sm};
`;

const CancelButton = styled.button`
  width: 70px;
  height: 40px;
  margin: 0 5px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.gray3};
  ${({ theme }) => theme.media.mobile} {
    width: 80px;
  }
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.sm};
`;

function Field(props: any): JSX.Element {
  const [category, setCategory] = useState<LabelProps[]>(LabelItems.slice(1));

  const handleCheckedLabel = (idx: number) => {
    let copiedItems = [...category];
    copiedItems[idx].checked = !copiedItems[idx].checked;
    setCategory(copiedItems);
  };

  useEffect(() => {
    let interests: number[] = [];
    category.forEach((value) => {
      if (value.checked) {
        interests = [...interests, value.id];
      }
    });
    props.getInterests(interests);
  }, [category]);

  return (
    <Wrapper>
      <Container>
        <Title>분야선택</Title>
        <Explanation>
          관심있는 분야를 선택하여 고객님의 관심사에 맞는 정보를 받아보세요.
        </Explanation>
        <TagContainer>
          <Tags>
            {category?.map((item, idx) => {
              return (
                <TagLabel key={idx} check={item.checked}>
                  {item.title}
                  <Tag
                    type="radio"
                    name="label"
                    value={item.title}
                    onChange={() => handleCheckedLabel(idx)}
                  />
                </TagLabel>
              );
            })}
          </Tags>
        </TagContainer>
        <Buttons>
          <CancelButton onClick={props.onPrevHandler}>이전</CancelButton>
          <NextButton onClick={props.onNextHandler}>다음으로</NextButton>
        </Buttons>
      </Container>
    </Wrapper>
  );
}

export default Field;
