import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Fade } from "react-awesome-reveal";
import useIntersectionObsever from "hooks/UseIntersectionObserver";
import { HomeProps } from "./HomeProps";

const StyledBox = styled.div`
  .container {
    scroll-behavior: smooth;
    height: 100vh;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
  }
  .container::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  .container > div {
    scroll-snap-align: start;
  }
`;

const Section = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  background-image: url(${require("assets/home.png")});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 100px;
`;

const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  font-size: ${({ theme }) => theme.fonts.size.xxl};
  color: ${({ theme }) => theme.colors.white};
  line-height: 45px;
`;

const HeaderExplanation = styled.div`
  display: flex;
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
  font-size: ${({ theme }) => theme.fonts.size.base};
  color: ${({ theme }) => theme.colors.white};
  margin-top: 30px;
`;

const MoreButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 56px;
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
  font-size: ${({ theme }) => theme.fonts.size.base};
  color: ${({ theme }) => theme.colors.main};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 28px;
  border: none;
  margin-top: 40px;
  :hover {
    background: ${({ theme }) => theme.colors.blue5};
  }
  cursor: pointer;
`;

const Svg = styled.svg`
  margin-right: 5px;
`;

const LogoContainer = styled.div`
  position: relative;
  padding: 50px;
`;

const MainLogo = styled.img`
  position: absolute;
  top: 55%;
  left: 150px;
  width: 118px;
  height: 103px;
`;

const SubLogo = styled.img`
  position: absolute;
  width: 63px;
  height: 83px;
  left: 250px;
  top: 45%;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
  justify-content: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 60px 0;
`;

const ContentImage = styled.img`
  margin: 0 70px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: 0 70px;
`;

const ContentTitle = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  font-size: ${({ theme }) => theme.fonts.size.lg};
  color: ${({ theme }) => theme.colors.main};
`;

const ContentExplanation = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  font-size: ${({ theme }) => theme.fonts.size.base};
  color: ${({ theme }) => theme.colors.gray6};
  margin-top: 30px;
  line-height: 23px;
`;

const Footer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  align-items: center;
  background: ${({ theme }) => theme.colors.blue1};
`;

const FooterElement = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  margin: 0 100px;
`;

const FooterCount = styled.div`
  position: absolute;
  text-align: center;
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  font-size: ${({ theme }) => theme.fonts.size.xxl};
  color: ${({ theme }) => theme.colors.white};
`;

const FooterTitle = styled.div`
  position: absolute;
  top: 30px;
  text-align: center;
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  font-size: ${({ theme }) => theme.fonts.size.base};
  color: ${({ theme }) => theme.colors.white};
`;

function easeOutExpo(t: number): number {
  return t === 1 ? 1 : 1 - Math.pow(2, -10 * t);
}

function TabletHome({ countList }: { countList: HomeProps }): JSX.Element {
  const navigate = useNavigate();
  const [scroll, setScroll] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const isInViewport = useIntersectionObsever(ref);

  const start = 0;
  const duration = 2000;
  const [ideaCount, setIdeaCount] = useState<number>(start);
  const [nftCount, setNftCount] = useState<number>(start);
  const [debateCount, setDebateCount] = useState<number>(start);
  const frameRate = 1000 / 60;
  const totalFrame = Math.round(duration / frameRate);

  useEffect(() => {
    let currentNumber = start;
    const counter = setInterval(() => {
      const progress = easeOutExpo(++currentNumber / totalFrame);
      setIdeaCount(Math.round(countList.ideaCount * progress));
      setNftCount(Math.round(countList.nftCount * progress));
      setDebateCount(Math.round(countList.ongoingDebateCount * progress));

      if (progress === 1) {
        clearInterval(counter);
      }
    }, frameRate);

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll); //clean up
    };
  }, [isInViewport, scroll]);

  const handleScroll = () => {
    if (isInViewport) {
      setScroll(true);
      return;
    }
  };

  return (
    <StyledBox>
      <div className="container">
        <Section>
          <Header>
            <HeaderContainer>
              <HeaderTitle>
                <div>탈 중앙 집단지성 기반</div>
                <div>아이디어 서비스</div>
              </HeaderTitle>
              <HeaderExplanation>
                의견을 다른 사람과 함께 완성하고 블록체인에 기록하세요
              </HeaderExplanation>
              <MoreButton onClick={() => navigate("/community?page=1")}>
                <Svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.172 6.99992L6.808 1.63592L8.222 0.221924L16 7.99992L8.222 15.7779L6.808 14.3639L12.172 8.99992H0V6.99992H12.172Z"
                    fill="#1F56F1"
                  />
                </Svg>
                아이디어 구경하기
              </MoreButton>
            </HeaderContainer>
            <LogoContainer>
              <MainLogo src={require("assets/main-logo1.png")} />
              <SubLogo src={require("assets/main-logo2.png")} />
            </LogoContainer>
          </Header>
        </Section>
        <Section>
          <ContentContainer>
            <Fade direction={"up"} duration={500} triggerOnce>
              <ContentImage
                src={require("assets/home-content1.png")}
                height={200}
              />
            </Fade>
            <Content>
              <ContentTitle>아이디어 제시</ContentTitle>
              <Fade cascade damping={0.1}>
                <ContentExplanation>
                  {`머리에만 갖고있는 아이디어를 cEdu에 기록해보세요. 전문가를
                  포함한 여러 사람들과 함께 의견을 구체화 해보세요! 집단지성의
                  능력을 cEdu가 보여줄게요.`}
                </ContentExplanation>
              </Fade>
            </Content>
          </ContentContainer>
        </Section>
        <Section>
          <ContentContainer>
            <Fade direction={"up"} duration={500} triggerOnce>
              <ContentImage
                src={require("assets/home-content2.png")}
                height={150}
              />
            </Fade>
            <Content>
              <ContentTitle>채택</ContentTitle>
              <Fade cascade damping={0.1}>
                <ContentExplanation>
                  내 아이디어 뿐만이 아니라 다른 사람들의 맘에드는 아이디어에
                  투표하고 토론방에 입장해서 의견을 나눠보세요. 토론에서 의견이
                  채택되면 해당 NFT의 지분을 가질 수 있어요.
                </ContentExplanation>
              </Fade>
            </Content>
          </ContentContainer>
        </Section>
        <Section>
          <ContentContainer>
            <Fade direction={"up"} duration={500} triggerOnce>
              <ContentImage
                src={require("assets/home-content3.png")}
                height={120}
              />
            </Fade>
            <Content>
              <ContentTitle>NFT 발급</ContentTitle>
              <Fade cascade damping={0.1}>
                <ContentExplanation>
                  최종으로 아이디어가 채택이 되면 NFT가 발급이 됩니다.
                  원제시자는 50%의 지분을 가질 수 있어요!
                </ContentExplanation>
              </Fade>
            </Content>
          </ContentContainer>
        </Section>
        <Section>
          <Footer>
            <FooterElement>
              <FooterCount ref={ref}>{ideaCount}+</FooterCount>
              <FooterTitle>제안된 아이디어</FooterTitle>
            </FooterElement>
            <FooterElement>
              <FooterCount>{nftCount}+</FooterCount>
              <FooterTitle>발급된 아이디어 NFT</FooterTitle>
            </FooterElement>
            <FooterElement>
              <FooterCount>{debateCount}+</FooterCount>
              <FooterTitle>현재 토론 진행 중인 아이디어</FooterTitle>
            </FooterElement>
          </Footer>
        </Section>
      </div>
    </StyledBox>
  );
}

export default TabletHome;
