import styled from 'styled-components';
import { useState, useEffect, useCallback, Fragment } from 'react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';

import PageController from 'components/global/PageController';
import Expert from '@components/admin/EachExpert';
import { userProps } from 'components/admin/UserProps';
import { useCookies } from 'react-cookie';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-itmes: center;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px;
  max-width: 1290px;
  width: 100%;
`;

const CategoryTitle = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.md};
  color: ${({ theme }) => theme.colors.gray4};
  margin-bottom: 10px;
`;

const CategoryDetailTitle = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.xl};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  color: ${({ theme }) => theme.colors.gray6};
  margin-bottom: 40px;
`;

const BoardContainer = styled.div`
  width: 100%;
  box-shadow: 0px 3px 3px 3px rgba(0, 0, 0, 0.05);
`;

const BoardHead = styled.div`
  width: 100%;
  border-collapse: collapse;
`;

const Row = styled.div<{ idx: number }>`
  width: 100%;
  height: 70px;
  display: grid;
  grid-template-columns: 0.6fr 1.3fr 1.8fr 1.7fr 1.2fr 1.5fr;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray1};
  text-align: center;
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  color: ${({ theme }) => theme.colors.gray5};
  background: ${(props) =>
    props.idx % 2 === 0
      ? ({ theme }) => theme.colors.white
      : ({ theme }) => theme.colors.gray0};
  div {
    display: flex;
    place-items: center;
    &:first-child {
      place-content: center;
    }
    &:last-child {
      border-right: none;
      margin-right: 2px;
    }
    margin-right: 5px;
  }
  &:first-child {
    font-size: ${({ theme }) => theme.fonts.size.base};
    font-weight: ${({ theme }) => theme.fonts.weight.medium};
    color: ${({ theme }) => theme.colors.gray4};
    background: ${({ theme }) => theme.colors.gray7};
    height: 44px;
    border-bottom: none;
  }
`;

const Page = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

const StateContainer = styled.div``;

const State = styled.div<{ state: number }>`
  border-radius: 5px;
  background-color: ${(props) =>
    props.state === 1
      ? 'rgba(142, 142, 142, 0.1);'
      : props.state === 3
      ? 'rgba(226, 82, 65, 0.1);'
      : 'rgba(31, 86, 241, 0.1);'};
  padding: 8px;
`;

const DotContainer = styled.div`
  padding-right: 4px;
`;

const Dot = styled.div<{ state: number }>`
  background-color: ${(props) =>
    props.state === 1
      ? 'rgba(142, 142, 142);'
      : props.state === 3
      ? 'rgba(226, 82, 65);'
      : 'rgba(31, 86, 241);'};
  width: 8px;
  height: 8px;
  border-radius: 4px;
`;

const MoreViewButtonWrapper = styled.div<{ isDrop: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Svg = styled.svg`
  ${({ theme }) => theme.media.mobile} {
    width: 10px;
    height: 10px;
  }
  width: 16px;
  height: 16px;
`;

const ViewContainer = styled.div<{ isDrop: boolean }>`
  overflow: hidden;
`;

const ExpertState = styled.div`
  width: 94px;
  height: 21px;
  border-radius: 5px;
  background-color: red;
`;

function ExpertPage(): JSX.Element {
  const [cookies] = useCookies(['TOKEN']);
  const [boardsCount, setBoardsCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [userInfo, setUserInfo] = useState<userProps[]>([]);
  const [searchParams] = useSearchParams();
  const [state, setState] = useState<boolean>(false);

  const getList = useCallback(
    async (page: Number) => {
      try {
        const { data } = await axios({
          method: 'get',
          url: `/expert`,
          params: { page: page },
          headers: {
            Authorization: `Bearer ${cookies['TOKEN']}`,
          },
        });

        let users: userProps[] = [];
        data.data.data.forEach((item: userProps) => {
          let newItem = Object.assign(item, { isDrop: false });
          users.push(newItem);
        });
        setUserInfo(users);
        setBoardsCount(data.data.count);
      } catch (e) {
        console.log(e);
      }
    },
    [cookies]
  );

  useEffect(() => {
    let page = Number(searchParams.get('page'));
    setPage(Number(searchParams.get('page')));
    getList(page);
  }, [searchParams, getList]);

  useEffect(() => {
    getList(page);
  }, [state]);

  const onClickDropButton = (idx: number) => {
    let copiedInfo = [...userInfo];
    copiedInfo[idx].isDrop = !copiedInfo[idx].isDrop;
    setUserInfo(copiedInfo);
  };

  return (
    <Wrapper>
      <Container>
        <CategoryTitle>회원</CategoryTitle>
        <CategoryDetailTitle>전문가회원 승인</CategoryDetailTitle>
        <BoardContainer>
          <BoardHead>
            <Row idx={-1}>
              <div></div>
              <div>User ID</div>
              <div>이름/생년월일</div>
              <div>전문 분야</div>
              <div>증빙내역</div>
              <div>처리상황</div>
            </Row>
          </BoardHead>
          {userInfo.map((item, idx) => (
            <Fragment key={idx}>
              <Row key={idx} idx={idx}>
                <MoreViewButtonWrapper isDrop={item.isDrop}>
                  {item.isDrop ? (
                    <Svg
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      cursor={'pointer'}
                      onClick={() => onClickDropButton(idx)}
                    >
                      <path
                        d="M7.00026 2.82734L2.05026 7.77734L0.636257 6.36334L7.00026 -0.000656128L13.3643 6.36334L11.9503 7.77734L7.00026 2.82734Z"
                        fill="black"
                      />
                    </Svg>
                  ) : (
                    <Svg
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      cursor={'pointer'}
                      onClick={() => onClickDropButton(idx)}
                    >
                      <path
                        d="M6.99974 5.17266L11.9497 0.222656L13.3637 1.63666L6.99974 8.00066L0.635742 1.63666L2.04974 0.222656L6.99974 5.17266Z"
                        fill="black"
                      />
                    </Svg>
                  )}
                </MoreViewButtonWrapper>
                <div>{item.userId.userId}</div>
                <div>
                  {item.userId.name}/{item.userId.birth}
                </div>
                <div>{item.interestField.fieldName}</div>
                <div>{item.attachments.length}건</div>
                <StateContainer>
                  <State state={item.state}>
                    <DotContainer>
                      <Dot state={item.state}></Dot>
                    </DotContainer>
                    {item.state === 1
                      ? '승인 미완료'
                      : item.state === 2
                      ? '승인 완료'
                      : '승인 거절'}
                  </State>
                </StateContainer>
              </Row>
              {item.isDrop && (
                <ViewContainer isDrop={item.isDrop}>
                  <Expert
                    privacyInfos={item}
                    state={state}
                    setState={setState}
                  />
                </ViewContainer>
              )}
            </Fragment>
          ))}
        </BoardContainer>
        <Page>
          <PageController
            postCount={boardsCount}
            currentPage={page}
            pagePostCount={9}
            uri={'admin/expert-approval'}
          />
        </Page>
      </Container>
    </Wrapper>
  );
}

export default ExpertPage;
