import axios from "axios";
import { useEffect, useState, useCallback } from "react";
import { useCookies } from "react-cookie";
import { useSearchParams } from "react-router-dom";

function UserAuth(props: any): JSX.Element {
  const [searchParams] = useSearchParams();
  const [isAccess, setIsAccess] = useState<boolean>(false);
  const [isAccessible, setIsAccessible] = useState<boolean>(false);
  const [cookies] = useCookies(["TOKEN", "USER-INFORMATION", "ISADMIN"]);

  const getCurrentPost = useCallback(async (postId: number) => {
    try {
      const { data } = await axios({
        method: "get",
        url: `/idea/token/${postId}`,
      });
      // 접근 권한 확인
      const access = await axios({
        method: "get",
        url: `/debate-access/one/${postId}`,
        headers: {
          Authorization: `Bearer ${cookies["TOKEN"]}`,
        },
      });
      if (!access.data.data) {
        setIsAccessible(false);

        // 원아이디어에 투표한 유저인지 확인
        const check = await axios({
          method: "get",
          url: `/vote/${data.data.id}`,
        });

        check.data.data.voters.forEach((user: string) => {
          if (user === cookies["USER-INFORMATION"].id) {
            setIsAccessible(true);
          }
        });
        return;
      }
      setIsAccess(access.data.data);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    const postId = Number(searchParams.get("id"));
    getCurrentPost(postId);
  }, [searchParams, getCurrentPost, isAccess]);

  return isAccess || cookies.ISADMIN === "true"
    ? props.forumPost
    : props.noAuth(isAccessible, setIsAccess);
}

export default UserAuth;
