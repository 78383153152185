import { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { useMediaQuery } from 'react-responsive';

import { IdeaListProps } from 'components/post/IdeaProps';
import { LabelItems } from 'components/sign-up/CategoryItems';
import PageController from 'components/global/PageController';
import Cards from 'components/community/idea/Cards';
import { LoginState } from 'atoms/LoginState';
import Modal from 'components/community/Modal';
import NoItem from 'components/community/idea/NoItem';

import AgreeModal from '@components/community/AgreeModal';
import { agreeModalState } from 'atoms/ModalState';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  /* margin: 20px; */
  text-align: center;
  color: ${({ theme }) => theme.colors.gray6};
  font-size: ${({ theme }) => theme.fonts.size.xl};
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
`;

const CommunitySelect = styled.div`
  display: flex;
  width: 1024px;
  margin-bottom: 20px;
  justify-content: space-between;

  ${({ theme }) => theme.media.desktop} {
    width: 1024px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  gap: 20px;

  /* background-color: black; */
`;

const WriteButton = styled(Link)`
  display: flex;
  width: 105px;
  height: 30px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.main};
  align-items: center;
  justify-content: center;

  span {
    margin: 2px 0 0 5px;
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fonts.size.sm};
    font-weight: ${({ theme }) => theme.fonts.weight.regular};
  }

  ${({ theme }) => theme.media.mobile} {
    width: 80px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SortContainer = styled.div`
  display: flex;
  margin-right: 20px;
  width: 70px;
  height: 30px;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
`;

const FieldContainer = styled.div`
  display: flex;
  margin-right: 20px;
  width: 180px;
  height: 30px;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  ${({ theme }) => theme.media.mobile} {
    width: 140px;
  }
`;

const Select = styled.select`
  all: unset;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  background-color: transparent;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  display: block;
  align-items: center;
  justify-content: flex-start;
  &:focus {
    border-color: ${({ theme }) => theme.colors.main};
  }
  z-index: 3;
  cursor: pointer;
`;

const Arrow = styled.svg`
  margin-left: -20px;
  width: 24px;
  height: 24px;
`;

const Page = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 80px;
`;

const ModalLogo = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 30px;
`;

const ModalDescription = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.md};
  margin: 10px;
`;

const ModalButton = styled.button`
  width: 80%;
  height: 45px;
  cursor: pointer;
  border-radius: 10px;
  margin: 20px;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  background-color: ${({ theme }) => theme.colors.main};
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
  font-size: ${({ theme }) => theme.fonts.size.sm};
`;

const SelectContainer = styled.div`
  display: flex;
`;

function Community(): JSX.Element {
  const sortBy = [
    { label: '최신순', value: 'latest' },
    { label: '추천순', value: 'vote' },
    { label: '조회순', value: 'view' },
  ];
  const [posts, setPosts] = useState<IdeaListProps[]>();

  const [sort, setSort] = useState<string>('최신순');
  const [order, setOrder] = useState<string>('latest');
  const [field, setField] = useState<string>('');
  const [fieldId, setFieldId] = useState<number>();
  const [boardsCount, setBoardsCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [searchParams] = useSearchParams();
  const [loginState] = useRecoilState(LoginState);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 780 });
  const [modalMessage, setModalMessage] =
    useState<string>('로그인 후 이용해주세요.');

  const [isOpenAgreeModal, setIsOpenAgreeModal] =
    useRecoilState(agreeModalState);
  const [isAgree, setIsAgree] = useState(true);

  console.log('isOpen', isOpenAgreeModal);
  const getPosts = useCallback(
    async (page: number) => {
      const params = field
        ? { page: page, field: fieldId, order: order }
        : { page: page, order: order, viewcount: 12 };

      try {
        const { data } = await axios({
          method: 'get',
          url: `/idea`,
          params: params,
        });
        setPosts(data.data.data);
        setBoardsCount(data.data.count);
        console.log('data', data.data);
      } catch (e) {
        console.error(e);
      }
    },
    [field, fieldId, order]
  );

  useEffect(() => {
    let page = Number(searchParams.get('page'));
    setPage(Number(searchParams.get('page')));
    getPosts(page);
  }, [searchParams, order, page, fieldId, getPosts]);

  useEffect(() => {
    navigate('/community?page=1');
  }, [navigate, fieldId, sort]);

  const getOrder = (title: string) => {
    sortBy.forEach((item) => {
      if (item.label === title) {
        setOrder(item.value);
      }
    });
  };

  const getFieldId = (field: string) => {
    LabelItems.forEach((value) => {
      if (value.title === field) {
        setFieldId(value.id);
      }
    });
  };

  return (
    <>
      {isOpenModal && (
        <Modal>
          <>
            <ModalLogo>
              <img
                src={require('assets/modal-logo.png')}
                alt="modal logo"
                height={150}
              />
            </ModalLogo>
            <ModalDescription>{modalMessage}</ModalDescription>
            <ModalButton onClick={() => setIsOpenModal(false)}>
              확인
            </ModalButton>
          </>
        </Modal>
      )}

      <Wrapper>
        <CommunityDiv>
          <CommunitySelect>
            <SelectContainer>
              <SortContainer>
                <Select
                  name="sort"
                  value={sort}
                  onChange={({ currentTarget }) => {
                    setSort(currentTarget.value);
                    getOrder(currentTarget.value);
                  }}
                >
                  {sortBy?.map((item, idx) => {
                    return (
                      <option key={idx} value={item.label}>
                        {item.label}
                      </option>
                    );
                  })}
                </Select>
                <Arrow
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_592_2077)">
                    <path
                      d="M12 13.1722L16.95 8.22217L18.364 9.63617L12 16.0002L5.63599 9.63617L7.04999 8.22217L12 13.1722Z"
                      fill="#8E8E8E"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_592_2077">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </Arrow>
              </SortContainer>
              <FieldContainer>
                <Select
                  name="field"
                  value={field}
                  onChange={({ currentTarget }) => {
                    setField(currentTarget.value);
                    getFieldId(currentTarget.value);
                  }}
                >
                  <option disabled value="" defaultValue="">
                    {`분야를 선택해주세요.`}
                  </option>
                  {LabelItems?.map((item, idx) => {
                    return (
                      <option key={idx} value={item.title}>
                        {item.title}
                      </option>
                    );
                  })}
                </Select>
                <Arrow
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_592_2077)">
                    <path
                      d="M12 13.1722L16.95 8.22217L18.364 9.63617L12 16.0002L5.63599 9.63617L7.04999 8.22217L12 13.1722Z"
                      fill="#8E8E8E"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_592_2077">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </Arrow>
              </FieldContainer>
            </SelectContainer>

            <ButtonContainer>
              <WriteButton
                onClick={() => {
                  if (!loginState || isMobile) {
                    setIsOpenModal(true);
                    setModalMessage(
                      isMobile
                        ? '아이디어 작성은 PC / 태블릿에서만 가능합니다.'
                        : '로그인 후 이용해주세요.'
                    );
                  }
                }}
                to={
                  loginState && !isMobile
                    ? `/community/editor`
                    : `/community?page=${page}`
                }
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_842_1517)">
                    <path
                      d="M6.64867 12H7.33334V8.66665H8.66667V12H9.35134C9.43934 11.1986 9.848 10.5373 10.5113 9.81532C10.5867 9.73398 11.066 9.23732 11.1227 9.16665C11.5935 8.5784 11.8887 7.86913 11.9741 7.1205C12.0595 6.37187 11.9317 5.61434 11.6055 4.93514C11.2793 4.25594 10.7678 3.6827 10.1301 3.28143C9.49233 2.88016 8.75421 2.66719 8.00072 2.66703C7.24724 2.66686 6.50903 2.87953 5.87111 3.28052C5.23319 3.68152 4.72151 4.25454 4.39498 4.9336C4.06845 5.61266 3.94036 6.37014 4.02547 7.1188C4.11057 7.86747 4.4054 8.57687 4.876 9.16532C4.93334 9.23665 5.414 9.73398 5.488 9.81465C6.152 10.5373 6.56067 11.1986 6.64867 12ZM6.66667 13.3333V14H9.33334V13.3333H6.66667ZM3.836 9.99998C3.20813 9.21551 2.81464 8.26963 2.70085 7.2713C2.58706 6.27296 2.7576 5.26279 3.19284 4.35715C3.62808 3.4515 4.31029 2.68723 5.16091 2.15237C6.01152 1.61751 6.99593 1.33382 8.00072 1.33398C9.00552 1.33415 9.98984 1.61815 10.8403 2.15328C11.6907 2.68841 12.3727 3.4529 12.8076 4.35869C13.2426 5.26447 13.4128 6.27469 13.2987 7.27299C13.1846 8.27129 12.7908 9.21705 12.1627 10.0013C11.7493 10.516 10.6667 11.3333 10.6667 12.3333V14C10.6667 14.3536 10.5262 14.6927 10.2761 14.9428C10.0261 15.1928 9.68696 15.3333 9.33334 15.3333H6.66667C6.31305 15.3333 5.97391 15.1928 5.72386 14.9428C5.47381 14.6927 5.33334 14.3536 5.33334 14V12.3333C5.33334 11.3333 4.25 10.516 3.836 9.99998Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_842_1517">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span>New Idea</span>
              </WriteButton>
            </ButtonContainer>
          </CommunitySelect>

          {boardsCount > 0 ? (
            <>
              <Container>
                {posts?.map((post, id) => (
                  <Link
                    key={post.id}
                    onClick={() => {
                      if (!loginState) {
                        setIsOpenModal(true);
                      }
                    }}
                    to={
                      loginState
                        ? `/community/idea?id=${post.id}`
                        : `/community?page=${page}`
                    }
                  >
                    <Cards id={post.id} post={post} />
                  </Link>
                ))}
              </Container>
              <Page>
                <PageController
                  postCount={boardsCount}
                  currentPage={page}
                  uri={'community'}
                  pagePostCount={12}
                />
              </Page>
            </>
          ) : (
            <NoItem description={'존재하는 아이디어가 없습니다.'} />
          )}
        </CommunityDiv>
      </Wrapper>
    </>
  );
}

const CommunityDiv = styled.div`
  top: 300px;
  width: 1024px;
  margin-top: 100px;
`;

export default Community;
