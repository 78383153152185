import { DefaultTheme } from "styled-components";

export const theme: DefaultTheme = {
  colors: {
    error: "#E25241",
    main: "#1F56F1",
    blue1: "#3577FC",
    blue2: "#74ABFF",
    blue3: "#90BFFF",
    blue4: "#BDD8FF",
    blue5: "#DAE8FF",
    blue6: "#EEF5FF",
    blue7: "#1B3D82",
    gray0: "#FBFCFC",
    gray1: "#EBEBEB",
    gray2: "#D6D6D6",
    gray3: "#838383",
    gray4: "#8E8E8E",
    gray5: "#3C3C3C",
    gray6: "#141414",
    gray7: "#F5F6FA",
    white: "#FFFFFF",
    background: "#F5F5F5",
  },
  fonts: {
    weight: {
      thin: 100,
      light: 300,
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
      black: 900,
    },
    size: {
      xxs: "0.65rem",
      xs: "0.75rem",
      sm: "0.875rem",
      base: "1rem",
      md: "1.125rem",
      lg: "1.25rem",
      xl: "1.5rem",
      xxl: "2rem",
      x3xl: "3rem",
      x4xl: "4rem",
      x5xl: "5rem",
      x6xl: "6rem",
      x7xl: "7rem",
      x8xl: "8rem",
      x9xl: "9rem",
      x10xl: "10rem",
    },
  },
  media: {
    mobile: "@media (max-width: 780px)",
    tablet: "@media (min-width: 781px) and (max-width: 1279px)",
    desktop: "@media (min-width: 1280px)",
  },
};
