import styled from "styled-components";
import axios from "axios";
import { useCookies } from "react-cookie";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: fit-content;
  padding: 26px;
  ${({ theme }) => theme.media.mobile} {
    height: 230px;
  }
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 10px;
`;

const Period = styled.div`
  color: ${({ theme }) => theme.colors.gray6};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  span {
    color: ${({ theme }) => theme.colors.main};
    margin-left: 5px;
  }
`;

const Title = styled.div`
  margin-top: 20px;
  color: ${({ theme }) => theme.colors.gray6};
  font-size: ${({ theme }) => theme.fonts.size.md};
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
`;

const Meter = styled.meter`
  ::-webkit-meter-bar {
    background: ${({ theme }) => theme.colors.gray2};
    outline: none;
    border: none;
    border-radius: 10px;
  }
  /* margin: 5px; */
  padding: 0 50px;
  height: 15px;
  width: 100%;
  ${({ theme }) => theme.media.mobile} {
    width: 80%;
    height: 10px;
  }
  ::-webkit-meter-optimum-value {
    border-radius: 10px;
    background-image: ${({ theme }) =>
      `linear-gradient(to right,${theme.colors.main}  0% , ${theme.colors.blue1} 100% )`};
  }
  span {
    animation: move 2s linear infinite;
    overflow: hidden;
  }
  @keyframes move {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 50px 50px;
    }
  }
`;

const Text = styled.span`
  margin: 10px;
  color: ${({ theme }) => theme.colors.gray3};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
`;

const VotingButton = styled.button`
  border: none;
  margin-top: 16px;
  width: 90px;
  height: 35px;
  border-radius: 5px;
  /* margin: 20px; */
  background: ${(props) =>
    props.value === "active"
      ? ({ theme }) => theme.colors.main
      : ({ theme }) => theme.colors.gray2};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  cursor: pointer;
`;

function Voting({
  count,
  users,
  period,
  guage,
  ideaId,
  completed,
  setCompleted,
}: {
  count: number;
  users: number;
  period: number;
  guage: number;
  ideaId: string;
  completed: boolean;
  setCompleted: (completed: boolean) => void;
}): JSX.Element {
  const qs = require("qs");
  const [cookies] = useCookies(["TOKEN"]);

  const handleVoting = async () => {
    try {
      const { data } = await axios({
        method: "post",
        url: `/vote`,
        data: qs.stringify({
          ideaId: `${ideaId}`,
        }),
        headers: {
          Authorization: `Bearer ${cookies["TOKEN"]}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      if (data.success) {
      } else {
        alert("something's wrong!");
      }
    } catch (e) {
      console.log(e);
    }
    setCompleted(!completed);
  };

  return (
    <>
      {period < 1 ? (
        <Wrapper>
          <Title style={{ marginBottom: "20px" }}>아이디어 채택하기</Title>
          <Meter min={0} max={100} value={String((guage / users) * 100)} />
          <Text>마감된 아이디어입니다.</Text>
        </Wrapper>
      ) : (
        <Wrapper>
          <Period>
            투표 종료까지
            <span>{period}</span>일
          </Period>
          <Title>아이디어 채택하기</Title>
          <Text>투표에 참여한 회원만 아이디어 개선 활동을 할 수 있어요!</Text>
          <Meter min={0} max={100} value={String((guage / users) * 100)} />
          {users > count ? (
            <>
              <Text style={{ marginTop: "4px" }}>
                아이디어 채택을 위한 남은 투표 수는 {users - count}개 입니다.
              </Text>
            </>
          ) : (
            <Text style={{ marginTop: "4px" }}>
              {(guage / users) * 100}% 달성 중
            </Text>
          )}

          <VotingButton
            value={completed ? "inactive" : "active"}
            onClick={handleVoting}
          >
            {completed ? "채택 완료" : "채택"}
          </VotingButton>
        </Wrapper>
      )}
    </>
  );
}

export default Voting;
