import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';

import { Desktop, Mobile, Tablet } from 'hooks/MediaQuery';
import { useLogout } from 'hooks/UseLogout';
import { useRecoilState } from 'recoil';
import { LoginState } from 'atoms/LoginState';
import { NavItems } from './NavItems';
import MobileNavigation from './MobileNavigation';
import { useCookies } from 'react-cookie';
import { useState } from 'react';

import { useLocation } from 'react-router-dom';

// components
import Button from '@components/home/Button';
import Guide from '@components/home/Guide';

const HeaderDiv = styled.nav<{ headerBgColor: any }>`
  height: 76px;
  width: 100%;
  background-color: #2057f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* position: sticky; */
  position: fixed;
  top: 0;
  z-index: 98;
  user-select: none;
`;

const InnerNav = styled.div`
  width: 100%;
  min-width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  width: 180px;
  padding: 20px 10px;
`;

const Ul = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  color: white;
`;

const Li = styled.li`
  height: 100%;
  /* width: 180px; */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.1s ease-in-out;
  margin: 10px;
`;

const NavLink = styled.div<{
  border: any;
  borderRadius: any;
  headerFontColor: any;
}>`
  /* color: ${(props) => props.theme.colors.gray5}; */
  color: ${(props) => props.headerFontColor};
  border: ${(props) => props.border};
  border-radius: ${(props) => props.borderRadius};
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 118px;
  height: 35px;

  :hover {
    font-weight: 700;
    border: 1.5px solid #fff;
    border-radius: 17.5px;
    width: 118px;
    height: 35px;
  }
`;

const MypageButton = styled(Link)`
  color: ${(props) => props.theme.colors.gray5};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  :hover {
    color: ${(props) => props.theme.colors.main};
  }
`;

const LoginButton = styled(Link)`
  display: flex;
  color: ${(props) => props.theme.colors.gray5};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  :hover {
    color: ${(props) => props.theme.colors.main};
  }
  cursor: pointer;
`;

const LogoutButton = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.gray5};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  :hover {
    color: ${(props) => props.theme.colors.main};
  }
  cursor: pointer;
`;

const UserContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Profile = styled.img<any>`
  width: 35px;
  height: 35px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 30px;
  border: 0.5px solid ${({ theme }) => theme.colors.gray2};
  cursor: pointer;
`;

function Header({ page }: { page: string }): JSX.Element {
  const [isChecked, setIsChecked] = useState<number>();
  const [isDisabled, setIsDisabled] = useState(false);

  const { logout } = useLogout();
  const [loginState, setLoginState] = useRecoilState(LoginState);
  const [cookies] = useCookies(['USER-INFORMATION', 'ISADMIN']);
  const navigate = useNavigate();

  const location = useLocation();

  const headerBgColor = () => {
    if (location.pathname === '/') {
      return `#3939ff`;
    } else {
      return `#FBFBFD`;
    }
  };

  const headerFontColor = () => {
    if (location.pathname === '/') {
      return `#fff`;
    } else {
      return `#3C3C3C`;
    }
  };

  // const [page, setPage] = useState<number>(1);

  const activeButton = (idx: number) => {
    if (idx === isChecked) {
      return '1.5px solid #fff';
    } else {
      return 'none';
    }
  };

  const radiusActiveButton = (idx: number) => {
    if (idx === isChecked) {
      return '20px';
    } else {
      return 'none';
    }
  };

  const activeButtonHandler = (idx: number) => {
    setIsChecked(idx);
  };

  const handleLogout = () => {
    logout();
    setLoginState(false);
  };

  function ImgFile() {
    if (cookies['ISADMIN'] === 'true') {
      return require('@assets/clicked-general-member.png');
    }
    if (cookies['USER-INFORMATION']) {
      if (cookies['USER-INFORMATION']?.profile === null) {
        return require('@assets/clicked-general-member.png');
      }
      if (cookies['USER-INFORMATION']?.profile != null) {
        return (
          process.env.REACT_APP_S3_BUCKET_URL +
          cookies['USER-INFORMATION']?.profile
        );
      }
    }
  }

  if (page === 'user') {
    return (
      <HeaderDiv headerBgColor={headerBgColor}>
        <InnerNav>
          <Desktop>
            <Link to="/">
              <Logo>
                <img
                  src={require('assets/cedu-logo.png')}
                  alt="logo"
                  height={40}
                />
              </Logo>
            </Link>
          </Desktop>
          <Tablet>
            <MobileNavigation />
          </Tablet>
          <Mobile>
            <>
              <MobileNavigation />
            </>
          </Mobile>
        </InnerNav>
      </HeaderDiv>
    );
  } else {
    return (
      <HeaderDiv headerBgColor={headerBgColor}>
        <InnerNav>
          <Link to="/">
            <Logo>
              <img
                src={require('assets/cedu-logo.png')}
                alt="logo"
                height={40}
              />
            </Logo>
          </Link>
          <Desktop>
            <>
              <Ul>
                {NavItems.map((item, idx) => (
                  <Li key={idx} onClick={() => setIsChecked(idx)}>
                    <Link to={item.path}>
                      <NavLink
                        border={activeButton(idx)}
                        headerFontColor={headerFontColor}
                        borderRadius={radiusActiveButton(idx)}
                      >
                        {item.title}
                      </NavLink>
                    </Link>
                  </Li>
                ))}
              </Ul>

              <Li>
                <UserContainer>
                  <Guide />
                  {loginState && (
                    <>
                      <div>
                        <Profile
                          src={ImgFile()}
                          onClick={() =>
                            navigate(
                              cookies['ISADMIN'] === 'true'
                                ? '/admin/user-management?page=1'
                                : `/mypage/idea?id=${cookies['USER-INFORMATION']?.id}&page=1`
                            )
                          }
                        />
                      </div>

                      <MypageButton
                        to={
                          cookies['ISADMIN'] === 'true'
                            ? '/admin/user-management?page=1'
                            : `/mypage/idea?id=${cookies['USER-INFORMATION']?.id}&page=1`
                        }
                      >
                        {cookies['ISADMIN'] === 'true' ? 'Admin' : 'MyPage'}
                      </MypageButton>
                    </>
                  )}
                </UserContainer>
                {loginState ? (
                  <>
                    <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
                  </>
                ) : (
                  <>
                    <Link to="/login">
                      <Button
                        bg="black"
                        color="white"
                        width="74px"
                        height="40px"
                        size="16px"
                        radius="20px"
                        border="none"
                        cursor
                        display="flex"
                        margin="0px 12px 0px 0px"
                      >
                        Sign In
                      </Button>
                    </Link>

                    <Link to="/sign-up">
                      <Button
                        bg="black"
                        color="white"
                        width="74px"
                        height="40px"
                        size="16px"
                        radius="20px"
                        border="none"
                        cursor
                        display="flex"
                      >
                        Sign Up
                      </Button>
                    </Link>
                  </>
                )}
              </Li>
            </>
          </Desktop>
          <Tablet>
            <MobileNavigation />
          </Tablet>
          <Mobile>
            <MobileNavigation />
          </Mobile>
        </InnerNav>
      </HeaderDiv>
    );
  }
}

export default Header;
