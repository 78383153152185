import { CategoryProps, LabelProps } from "./FieldProps";

export const CategoryItems: CategoryProps[] = [
  {
    id: 1,
    category: "분야1",
    label: [
      { id: 1, title: "유머", checked: false },
      { id: 2, title: "사진", checked: false },
      { id: 3, title: "상품", checked: false },
      { id: 4, title: "문신", checked: false },
      { id: 5, title: "기술", checked: false },
      { id: 6, title: "여행", checked: false },
      { id: 7, title: "음식 및 음료", checked: false },
      { id: 8, title: "헤어 및 뷰티", checked: false },
    ],
  },
  {
    id: 2,
    category: "분야2",
    label: [
      { id: 9, title: "유머", checked: false },
      { id: 10, title: "사진", checked: false },
      { id: 11, title: "상품", checked: false },
      { id: 12, title: "문신", checked: false },
      { id: 13, title: "기술", checked: false },
      { id: 14, title: "여행", checked: false },
      { id: 15, title: "음식 및 음료", checked: false },
      { id: 16, title: "헤어 및 뷰티", checked: false },
      { id: 17, title: "과학 및 자연", checked: false },
      { id: 18, title: "명절 및 이벤트", checked: false },
      { id: 19, title: "동물 및 반려동물", checked: false },
      { id: 20, title: "건강 및 피트니스", checked: false },
      { id: 21, title: "남성 패션", checked: false },
    ],
  },
  {
    id: 3,
    category: "분야3",
    label: [
      { id: 22, title: "유머", checked: false },
      { id: 23, title: "사진", checked: false },
      { id: 24, title: "상품", checked: false },
      { id: 25, title: "문신", checked: false },
      { id: 26, title: "기술", checked: false },
      { id: 27, title: "여행", checked: false },
      { id: 28, title: "음식 및 음료", checked: false },
      { id: 29, title: "헤어 및 뷰티", checked: false },
    ],
  },
  {
    id: 4,
    category: "분야4",
    label: [
      { id: 30, title: "유머", checked: false },
      { id: 31, title: "사진", checked: false },
      { id: 32, title: "상품", checked: false },
      { id: 33, title: "문신", checked: false },
      { id: 34, title: "기술", checked: false },
      { id: 35, title: "여행", checked: false },
      { id: 36, title: "음식 및 음료", checked: false },
      { id: 37, title: "헤어 및 뷰티", checked: false },
      { id: 38, title: "과학 및 자연", checked: false },
      { id: 39, title: "명절 및 이벤트", checked: false },
      { id: 40, title: "동물 및 반려동물", checked: false },
      { id: 41, title: "건강 및 피트니스", checked: false },
      { id: 42, title: "남성 패션", checked: false },
    ],
  },
  {
    id: 5,
    category: "분야5",
    label: [
      { id: 43, title: "유머", checked: false },
      { id: 44, title: "사진", checked: false },
      { id: 45, title: "상품", checked: false },
      { id: 46, title: "문신", checked: false },
      { id: 47, title: "기술", checked: false },
      { id: 48, title: "여행", checked: false },
      { id: 49, title: "음식 및 음료", checked: false },
      { id: 50, title: "헤어 및 뷰티", checked: false },
      { id: 51, title: "과학 및 자연", checked: false },
      { id: 52, title: "명절 및 이벤트", checked: false },
      { id: 53, title: "동물 및 반려동물", checked: false },
      { id: 54, title: "건강 및 피트니스", checked: false },
      { id: 55, title: "남성 패션", checked: false },
    ],
  },
  {
    id: 6,
    category: "분야6",
    label: [
      { id: 56, title: "유머", checked: false },
      { id: 57, title: "사진", checked: false },
      { id: 58, title: "상품", checked: false },
      { id: 59, title: "문신", checked: false },
      { id: 60, title: "기술", checked: false },
      { id: 61, title: "여행", checked: false },
      { id: 62, title: "음식 및 음료", checked: false },
      { id: 63, title: "헤어 및 뷰티", checked: false },
      { id: 64, title: "과학 및 자연", checked: false },
      { id: 65, title: "명절 및 이벤트", checked: false },
      { id: 66, title: "동물 및 반려동물", checked: false },
      { id: 67, title: "건강 및 피트니스", checked: false },
      { id: 68, title: "남성 패션", checked: false },
    ],
  },
];

export const LabelItems: LabelProps[] = [
  { id: 0, title: "전체", checked: false },
  { id: 1, title: "농업/어업/임업", checked: false },
  { id: 2, title: "광업", checked: false },
  { id: 3, title: "제조업", checked: false },
  { id: 4, title: "전기/가스", checked: false },
  { id: 5, title: "수도/하수/폐기물", checked: false },
  { id: 6, title: "건설업", checked: false },
  { id: 7, title: "도/소매", checked: false },
  { id: 8, title: "운수/창고", checked: false },
  { id: 9, title: "숙박/음식점", checked: false },
  { id: 10, title: "정보통신", checked: false },
  { id: 11, title: "금융/보험", checked: false },
  { id: 12, title: "부동산", checked: false },
  { id: 13, title: "과학/기술서비스", checked: false },
  { id: 14, title: "시설/임대서비스", checked: false },
  { id: 15, title: "공공행정/국방/사회보장", checked: false },
  { id: 16, title: "교육서비스", checked: false },
  { id: 17, title: "보건/복지", checked: false },
  { id: 18, title: "예술/스포츠/여가", checked: false },
  { id: 19, title: "협회/단체/개인 서비스", checked: false },
  { id: 20, title: "기타", checked: false },
];
