import styled from "styled-components";
import { MenuItems } from "./MenuItems";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";

const MenuContainer = styled.div`
  display: flex;
  width: 120px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const NavSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const MainPath = styled(Link)`
  display: flex;
  align-items: center;
  margin-top: 10px;
  height: 40px;
`;

const Icon = styled.img<{ isClicked: boolean }>`
  height: ${({ isClicked }) => (isClicked ? "30px" : "50px")};
  margin: ${({ isClicked }) => (isClicked ? "0 0 0 0" : "5px 0 0 7px")};
`;

const UserInformation = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Nickname = styled.div`
  margin: 10px;
  font-size: ${({ theme }) => theme.fonts.size.base};
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
  color: ${({ theme }) => theme.colors.gray6};
  text-align: center;
`;

const UserType = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.xs};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  color: ${({ theme }) => theme.colors.gray4};
`;

const Profile = styled.img`
  width: 40px;
  height: 40px;
  margin: 10px 0;
  object-fit: cover;
  border-radius: 40px;
  border: 2px solid ${({ theme }) => theme.colors.main};
  background: ${({ theme }) => theme.colors.white};
`;

interface MouseProps {
  id: number;
  state: number; // 1: unclicked, 2: clicked, 3: hover
}

function MobileBar(): JSX.Element {
  const [isClicked, setIsClicked] = useState<MouseProps[]>([
    {
      id: 0,
      state: 1,
    },
    {
      id: 1,
      state: 1,
    },
    {
      id: 2,
      state: 1,
    },
    {
      id: 3,
      state: 1,
    },
  ]);
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState<string>(
    location.pathname.split("/")[2]
  );
  const [cookies] = useCookies(["USER-INFORMATION"]);

  const icons = [
    {
      unclicked: require("@assets/unclicked-idea.png"),
      clicked: require("@assets/clicked-idea.png"),
      hover: require("@assets/hover-idea.png"),
    },
    {
      unclicked: require("@assets/unclicked-vote.png"),
      clicked: require("@assets/clicked-vote.png"),
      hover: require("@assets/hover-vote.png"),
    },
    {
      unclicked: require("@assets/unclicked-nft.png"),
      clicked: require("@assets/clicked-nft.png"),
      hover: require("@assets/hover-nft.png"),
    },
    {
      unclicked: require("@assets/unclicked-user.png"),
      clicked: require("@assets/clicked-user.png"),
      hover: require("@assets/hover-user.png"),
    },
  ];

  const handleClick = (id: number, state: number) => {
    let copiedItems = [...isClicked];
    copiedItems[id].state = state;
    setIsClicked(copiedItems);
  };

  return (
    <MenuContainer>
      <UserInformation>
        <Profile
          src={
            cookies["USER-INFORMATION"]
              ? process.env.REACT_APP_S3_BUCKET_URL +
                cookies["USER-INFORMATION"].profile
              : require("@assets/clicked-general-member.png")
          }
        />
        <Nickname>{cookies["USER-INFORMATION"].nickname}</Nickname>
        <UserType>
          {cookies["USER-INFORMATION"].isExpert ? "전문가 회원" : "일반 회원"}
        </UserType>
      </UserInformation>

      <NavSection>
        {MenuItems.map((item, index) => (
          <MainPath
            key={index}
            to={item.path + `?id=${cookies["USER-INFORMATION"].id}&page=1`}
            onMouseOver={() => handleClick(index, 3)}
            onMouseOut={() => handleClick(index, 1)}
            onClick={() => setCurrentPage(item.title.toLowerCase())}
          >
            <Icon
              src={
                currentPage === item.title.toLowerCase()
                  ? icons[index].clicked
                  : isClicked[index].state === 1
                  ? icons[index].unclicked
                  : icons[index].hover
              }
              isClicked={currentPage === item.title.toLowerCase()}
            />
          </MainPath>
        ))}
      </NavSection>
    </MenuContainer>
  );
}

export default MobileBar;
