import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import axios from 'axios';
import Cards from 'components/post/Cards';
import { PostProps } from 'components/post/IdeaProps';
import { useCookies } from 'react-cookie';
import NoItem from 'components/community/idea/NoItem';
import PageController from 'components/global/PageController';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
`;

const CategoryTitle = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.lg};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  color: ${({ theme }) => theme.colors.gray6};
  margin-bottom: 10px;
`;

const CategoryExplanation = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.base};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  color: ${({ theme }) => theme.colors.gray4};
  margin-bottom: 40px;
`;

const SortList = styled.div`
  display: flex;
`;

const Sort = styled.div<{ checked: boolean }>`
  padding: 1px 15px;
  color: ${(props) =>
    props.checked
      ? ({ theme }) => theme.colors.gray6
      : ({ theme }) => theme.colors.gray3};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  cursor: pointer;
  border-right: 2px solid ${({ theme }) => theme.colors.blue4};
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    border: 0;
  }
`;

const Page = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

function Idea(): JSX.Element {
  let sortList = [
    {
      label: '채택된 아이디어',
      value: 'selected',
    },
    {
      label: '투표중인 아이디어',
      value: 'ongoing',
    },
    {
      label: '미채택된 아이디어',
      value: 'unselected',
    },
  ];

  let { id } = useParams();

  console.log('id', id);

  const [searchParams] = useSearchParams();
  const [sort, setSort] = useState({
    label: '채택된 아이디어',
    value: 'selected',
  });
  const [posts, setPosts] = useState<PostProps[]>();
  const [page, setPage] = useState<number>(1);
  const [boardsCount, setBoardsCount] = useState<number>(0);
  const [cookies] = useCookies(['TOKEN', 'USER-INFORMATION']);
  const location = useLocation();
  const navigate = useNavigate();

  const getPosts = useCallback(
    async (page: number) => {
      setBoardsCount(0);
      const params = { page: page, isSelected: sort.value };

      try {
        const { data } = await axios({
          method: 'get',
          url: `/user/mypage/idea`,
          params: params,
          headers: {
            Authorization: `Bearer ${cookies['TOKEN']}`,
          },
        });
        setPosts(data.data.data);
        setBoardsCount(data.data.count);
      } catch (e) {
        console.error(e);
      }
    },
    [cookies, sort]
  );

  useEffect(() => {
    let page = Number(searchParams.get('page'));
    setPage(page);
    getPosts(page);
  }, [searchParams, sort, getPosts]);

  useEffect(() => {
    navigate(`/mypage/idea?id=${cookies['USER-INFORMATION'].id}&page=1`);
  }, [navigate, sort]);

  return (
    <Wrapper>
      <IdeaDiv>
        <CategoryTitle>My 아이디어 정보</CategoryTitle>
        <CategoryExplanation>
          등록한 아이디어 정보를 볼 수 있습니다.
        </CategoryExplanation>
        <SortList>
          {sortList.map((item, id) => {
            return (
              <Sort
                key={id}
                checked={item.label === sort.label}
                onClick={() => setSort(item)}
              >
                {item.label}
              </Sort>
            );
          })}
        </SortList>
        {boardsCount > 0 ? (
          <>
            <Container>
              {posts?.map((post, id) => (
                <Link key={post.id} to={`/community/idea?id=${post.id}`}>
                  <Cards
                    key={id}
                    id={post.id}
                    post={post}
                    debateId={post.debate?.tokenId}
                  />
                </Link>
              ))}
            </Container>
            <Page>
              <PageController
                postCount={boardsCount}
                currentPage={page}
                uri={location.pathname + location.search.split('&')[0]}
                pagePostCount={6}
              />
            </Page>
          </>
        ) : (
          <NoItem description={'존재하는 아이디어가 없습니다.'} />
        )}
      </IdeaDiv>
    </Wrapper>
  );
}

const IdeaDiv = styled.div`
  margin-top: 80px;
  width: 884px;
  display: flex;
  flex-direction: column;
`;
export default Idea;
