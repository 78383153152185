import Block from "components/global/Block";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 1000px;
  height: 95vh;
  padding: 20px;
`;

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Logo = styled.div`
  display: flex;
  flex-direction: row;
  align-item: center;
  justify-content: space-between;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.fonts.size.x4xl};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  color: ${({ theme }) => theme.colors.blue7};
  margin-left: 50px;
  ${({ theme }) => theme.media.mobile} {
    margin-left: 20px;
  }
`;

const Subtitle = styled.h2`
  font-size: ${({ theme }) => theme.fonts.size.md};
  color: ${({ theme }) => theme.colors.gray4};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  line-height: 30px;
  text-align: center;
  margin-bottom: 30px;
`;

const BacktoHome = styled(Link).attrs({ to: "/" })`
  background-color: ${({ theme }) => theme.colors.main};
  padding: 10px 20px;
  border-radius: 25px;
  color: ${({ theme }) => theme.colors.white};
`;

function NotFound(): JSX.Element {
  return (
    <Container>
      <InnerContainer>
        <Logo>
          <img
            src={require("assets/modal-logo.png")}
            alt="modal logo"
            height={150}
          />
          <Title>404 Not Found</Title>
        </Logo>
        <Subtitle>
          죄송합니다. 페이지를 찾을 수 없습니다.
          <br />
          존재하지 않는 주소를 입력하셨거나,
          <br />
          요청하신 페이지의 주소가 변경, 삭제되어 찾을 수 없습니다.
        </Subtitle>
        <BacktoHome>홈으로</BacktoHome>
      </InnerContainer>
    </Container>
  );
}

export default NotFound;
