import { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import Agreement from 'components/sign-up/Agreement';
import Information from 'components/sign-up/Information';
import Field from 'components/sign-up/Field';
import Application from 'components/sign-up/Application';
import Completed from 'components/sign-up/Completed';
import {
  Container as StepContainer,
  Wrapper as StepWrapper,
  StepStyle,
  StepCount,
  StepsLabelContainer,
  StepLabel,
} from 'components/sign-up/StepBarComponents';

import ExpertModal from './ExpertModal';
import { useRecoilState } from 'recoil';
import { ExpertModalState } from '../../atoms/ModalState';
import { emailAddressState } from 'atoms/SignIn';

import Modal from '@components/community/Modal';
interface UserProps {
  // profileImage: File;
  userId: string;
  password: string;
  nickname: string;
  name: string;
  gender: string;
  birth: string;
  email_id: string;
  email_address: string;
  phonenumber: string;
  interest: number[];
}

interface ExpertProps {
  interestField: number;
  attachments: File[];
  userId: string;
}

function ExpertMember(): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useRecoilState(ExpertModalState);
  const [activeStep, setActiveStep] = useState(1);
  const [emailAddress, setEmailAddress] = useRecoilState(emailAddressState);
  const [signUpForm, setSignUpForm] = useState<UserProps>({
    // profileImage: require('@assets/user-default.png'),
    userId: '',
    password: '',
    nickname: '',
    name: '',
    gender: 'M',
    birth: '',
    email_id: '',
    email_address: emailAddress,
    phonenumber: '',
    interest: [],
  });
  const [expertForm, setExpertForm] = useState<ExpertProps>({
    interestField: 2,
    attachments: [],
    userId: '',
  });

  const handleSignUp = async () => {
    const form = new FormData();
    const birthStr = signUpForm.birth.split('/').join('');

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1024,
    };
    // const compressedFile = await imageCompression(
    //   signUpForm.profileImage,
    //   options
    // );

    // form.append('profile', compressedFile);
    form.append('userId', signUpForm.userId);
    form.append('password', signUpForm.password);
    form.append('nickname', signUpForm.nickname);
    form.append('name', signUpForm.name);
    form.append('gender', signUpForm.gender);
    form.append('birth', birthStr);
    form.append('email_id', signUpForm.email_id);
    form.append('email_address', signUpForm.email_address);
    form.append('phonenumber', signUpForm.phonenumber);
    form.append('interest', '[' + signUpForm.interest.toString() + ']');

    await axios
      .post('/user/join', form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        if (response.data.success) {
          setExpertForm((prev) => ({ ...prev, userId: response.data.data.id }));
          setActiveStep(activeStep + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRequestExpert = () => {
    const form = new FormData();

    form.append('interestField', expertForm.interestField.toString());
    for (let i = 0; i < expertForm.attachments.length; i++) {
      form.append('attachments', expertForm.attachments[i]);
    }
    form.append('userId', expertForm.userId);

    axios
      .post('/expert', form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        if (response.data.success) {
          setActiveStep(activeStep + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const prevStep = () => {
    setActiveStep(activeStep - 1);
  };

  const nextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const getInformation = (informations: UserProps) => {
    setSignUpForm(informations);
  };

  const getInterests = (interests: number[]) => {
    setSignUpForm((prev) => ({ ...prev, interest: interests }));
  };

  const getExpertsInfo = (interestField: number, attachments: File[]) => {
    setExpertForm((prev) => ({
      ...prev,
      interestField: interestField,
      attachments: attachments,
    }));
  };

  const totalSteps = steps.length;

  const width = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`;

  return (
    <>
      {!isModalOpen && (
        <Modal>
          <ExpertModal />
        </Modal>
      )}
      <Wrapper>
        <Title>전문가 회원가입</Title>
        <Header>
          <StepContainer width={width}>
            {steps.map(({ step, label }) => (
              <StepWrapper key={step}>
                <StepStyle
                  step={activeStep >= step ? 'completed' : 'incomplete'}
                >
                  <StepCount>{step}</StepCount>
                </StepStyle>
                <StepsLabelContainer>
                  <StepLabel
                    key={step}
                    step={activeStep >= step ? 'completed' : 'incomplete'}
                  >
                    {label}
                  </StepLabel>
                </StepsLabelContainer>
              </StepWrapper>
            ))}
          </StepContainer>
        </Header>
        {
          {
            1: <Agreement onNextHandler={nextStep} />,
            2: (
              <Information
                onPrevHandler={prevStep}
                onNextHandler={nextStep}
                getInformation={getInformation}
              />
            ),
            3: (
              <Field
                onPrevHandler={prevStep}
                onNextHandler={handleSignUp}
                getInterests={getInterests}
              />
            ),
            4: (
              <Application
                onPrevHandler={prevStep}
                onNextHandler={handleRequestExpert}
                getExpertsInfo={getExpertsInfo}
              />
            ),
            5: <Completed user={'expert'} userName={signUpForm.nickname} />,
          }[activeStep]
        }
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 30px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.span`
  color: ${({ theme }) => theme.colors.main};
  font-size: ${({ theme }) => theme.fonts.size.xl};
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
`;

const Header = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 0 16px;
  margin-top: 40px;
  ${({ theme }) => theme.media.mobile} {
    width: 300px;
  }
`;

const steps = [
  {
    label: '약관동의',
    step: 1,
  },
  {
    label: '정보입력',
    step: 2,
  },
  {
    label: '분야선택',
    step: 3,
  },
  {
    label: '전문가신청',
    step: 4,
  },
  {
    label: '가입완료',
    step: 5,
  },
];

export default ExpertMember;
