import styled from 'styled-components';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';

import { userProps } from 'components/admin/UserProps';
import FileBox from './FileBox';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-divs: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.gray7};
  padding: 50px 0;
`;

const Container = styled.div`
  ${({ theme }) => theme.media.mobile} {
    width: 90%;
  }
  width: 80%;
  display: flex;
  flex-direction: row;
`;

const PrivacyContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
  div {
    margin: 8px 0;
    &:first-child {
      font-weight: ${({ theme }) => theme.fonts.weight.bold};
    }
  }
`;

const Column = styled.div`
  ${({ theme }) => theme.media.mobile} {
    width: 50px;
  }
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  margin-top: 12px;
  width: 90px;
`;

const PrivacyInfo = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  flex-wrap: wrap;
  word-break: break-all;
`;

const QualificationContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  margin-left: 20px;
  margin-top: 8px;
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
`;

const AttachmentRow = styled.div`
  margin-top: 7px;
  display: flex;
  flex-direction: column;
`;

const ManagementRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const SelectContainer = styled.div`
  ${({ theme }) => theme.media.mobile} {
    width: 60px;
  }
  margin-top: 10px;
  display: flex;
  width: 150px;
  height: 28px;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
`;

const ReasonSelectContainer = styled.div`
  ${({ theme }) => theme.media.mobile} {
    width: 100px;
  }
  margin-top: 10px;
  display: flex;
  width: 210px;
  height: 28px;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
`;

const ReasonContainer = styled.div`
  margin-top: 12px;
  display: flex;
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.sm};
`;

const Select = styled.select`
  ${({ theme }) => theme.media.mobile} {
    width: 90%;
  }
  all: unset;
  width: 110%;
  height: 28px;
  padding: 0 10px;
  background-color: transparent;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  display: block;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.blue5};
  &:focus {
    border-color: ${({ theme }) => theme.colors.blue3};
  }
  z-index: 3;
  cursor: pointer;
`;

const Arrow = styled.svg`
  margin-left: -20px;
  width: 24px;
  height: 24px;
`;

const ManageButtonContainer = styled.div`
  ${({ theme }) => theme.media.mobile} {
    width: 40px;
  }
  width: 50px;
  align-items: center;
  justify-content: center;
`;

const ManageButton = styled.button`
  margin-top: 10px;
  width: 100%;
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  background: ${({ theme }) => theme.colors.main};
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
  font-size: ${({ theme }) => theme.fonts.size.xs};
`;

const ExpertState = styled.div<any>`
  width: 92px;
  height: 21px;
  border-radius: 5px;
  background-color: ${(props) =>
    props.color === 1
      ? 'rgba(142, 142, 142, 0.10)'
      : props.color === 2
      ? 'rgba(31, 86, 241, 0.10)'
      : 'rgba(226, 82, 65, 0.10)'};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  gap: 10px;
`;

const Circle = styled.div<any>`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.color === 1 ? '#8E8E8E' : props.color === 2 ? '#1F56F1' : '#E25241'};
`;

const Content = styled.div<any>`
  font-size: 12px;
  color: ${(props) =>
    props.color === 1 ? '#8E8E8E' : props.color === 2 ? '#1F56F1' : '#E25241'};
`;

function EachExpert({
  privacyInfos,
  state,
  setState,
}: {
  privacyInfos: userProps;
  state: boolean;
  setState: (state: boolean) => void;
}) {
  const qs = require('qs');
  const [cookies] = useCookies(['TOKEN']);

  const [managementState, setManagementState] = useState<string>('승인');
  const [managementReason, setManagementReason] =
    useState<string>('관련 서류 미비');
  const [stateList] = useState<string[]>(['거절', '승인']);
  const [reasonList] = useState<string[]>([
    '관련 서류 미비',
    '위조 서류',
    '기타',
  ]);

  const onClickChangeButton = async () => {
    let data = {};

    if (managementState === '거절') {
      data = {
        state: 3,
        reject: managementReason,
      };
    } else if (managementState === '승인') {
      data = {
        state: 2,
      };
    }

    try {
      const res = await axios({
        method: 'patch',
        url: `/expert/${privacyInfos.id}`,
        data: qs.stringify(data),
        headers: {
          Authorization: `Bearer ${cookies['TOKEN']}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      if (res.data.success) {
        alert('변경 완료');
        setState(!state);
      } else {
        alert("something's wrong!");
      }
    } catch (e: any) {
      alert(e.response.data.error);
    }
  };

  return (
    <Wrapper>
      <Container>
        <PrivacyContainer>
          <div>개인정보</div>
          <div></div>
          <Column>User ID</Column>
          <PrivacyInfo>{privacyInfos.userId.userId}</PrivacyInfo>
          <Column>이름 / 생년월일</Column>
          <PrivacyInfo>
            {privacyInfos.userId.name}/{privacyInfos.userId.birth}
          </PrivacyInfo>
          <Column>신청 분야</Column>
          <PrivacyInfo>{privacyInfos.interestField.fieldName}</PrivacyInfo>
          <Column>지갑 주소</Column>
          <PrivacyInfo>
            <span>{privacyInfos.userId.walletAddress}</span>
          </PrivacyInfo>
          <Column>신청 일시</Column>
          <PrivacyInfo>{privacyInfos.createdAt.substring(0, 10)}</PrivacyInfo>
          <Column>처리 상황</Column>
          <ExpertState color={privacyInfos.state}>
            <Circle color={privacyInfos.state}></Circle>
            <Content color={privacyInfos.state}>
              {privacyInfos.state === 1
                ? '승인 미완료'
                : privacyInfos.state === 2
                ? '승인 완료'
                : '승인 거절'}
            </Content>
          </ExpertState>
        </PrivacyContainer>
        <QualificationContainer>
          <div>자격증빙내역</div>
          <div></div>
          <Column>첨부서류</Column>
          <AttachmentRow>
            {privacyInfos.attachments.map((item, id) => {
              return <FileBox key={id} detail={item.fileName} />;
            })}
          </AttachmentRow>
          {privacyInfos.state === 3 && (
            <>
              <Column>거절 사유</Column>
              <ReasonContainer>{privacyInfos.reject}</ReasonContainer>
            </>
          )}
          {privacyInfos.state === 1 && (
            <>
              <Column>승인 처리</Column>
              <ManagementRow>
                <SelectContainer>
                  <Select
                    name="managementState"
                    value={managementState}
                    onChange={({ currentTarget }) =>
                      setManagementState(currentTarget.value)
                    }
                  >
                    {stateList?.map((item, idx) => {
                      return (
                        <option key={idx} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </Select>
                  <Arrow
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_592_2077)">
                      <path
                        d="M12 13.1722L16.95 8.22217L18.364 9.63617L12 16.0002L5.63599 9.63617L7.04999 8.22217L12 13.1722Z"
                        fill="#8E8E8E"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_592_2077">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </Arrow>
                </SelectContainer>
                <ManageButtonContainer>
                  <ManageButton onClick={onClickChangeButton}>
                    변경
                  </ManageButton>
                </ManageButtonContainer>
              </ManagementRow>
              {managementState === '거절' && (
                <>
                  <Column>거절 사유</Column>
                  <ReasonSelectContainer>
                    <Select
                      name="managementReason"
                      value={managementReason}
                      onChange={({ currentTarget }) =>
                        setManagementReason(currentTarget.value)
                      }
                    >
                      {reasonList?.map((item, idx) => {
                        return (
                          <option key={idx} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </Select>
                    <Arrow
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_592_2077)">
                        <path
                          d="M12 13.1722L16.95 8.22217L18.364 9.63617L12 16.0002L5.63599 9.63617L7.04999 8.22217L12 13.1722Z"
                          fill="#8E8E8E"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_592_2077">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </Arrow>
                  </ReasonSelectContainer>
                </>
              )}
            </>
          )}
        </QualificationContainer>
      </Container>
    </Wrapper>
  );
}

export default EachExpert;
