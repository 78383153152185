import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { LabelItems } from './CategoryItems';
import UploadBox from './UploadBox';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  ${({ theme }) => theme.media.mobile} {
    width: 240px;
  }
  padding-top: 30px;
  padding: 40px;
  width: 650px;
  display: flex;
  margin: 100px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 15px;
  flex-direction: column;
  box-shadow: 2px 3px 5px 0px ${({ theme }) => theme.colors.gray2};
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.md};
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
`;

const Explanation = styled.div`
  padding: 10px 0;
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  color: ${({ theme }) => theme.colors.gray5};
`;

const InnerContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const FieldContainer = styled.div`
  display: grid;
  margin: 20px 0;
  grid-template-columns: 1fr 3fr 1fr;
`;

const Column = styled.div`
  display: flex;
  place-content: flex-start;
  place-items: center;
  font-size: ${({ theme }) => theme.fonts.size.md};
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
`;

const Select = styled.select`
  all: unset;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  background-color: transparent;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray1};
  display: block;
  align-items: center;
  justify-content: flex-start;
  &:focus {
    border-color: ${({ theme }) => theme.colors.main};
  }
  z-index: 3;
`;

const Svg = styled.svg`
  margin-left: -24px;
  width: 24px;
  height: 24px;
`;

const WarningText = styled.span`
  font-size: ${({ theme }) => theme.fonts.size.xs};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  color: ${({ theme }) => theme.colors.gray3};
`;

const AddButton = styled.div`
  display: flex;
  margin-left: 130px;
  width: 270px;
  color: ${({ theme }) => theme.colors.gray4};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  cursor: pointer;
  ${({ theme }) => theme.media.mobile} {
    margin-left: 0px;
    margin-top: 10px;
  }
`;

const Buttons = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

const NextButton = styled.button`
  height: 40px;
  margin: 0 5px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.main};
  width: 140px;
  margin-right: 10px;
  ${({ theme }) => theme.media.mobile} {
    width: 130px;
  }
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.main};
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.sm};
`;

const CancelButton = styled.button`
  width: 70px;
  height: 40px;
  margin: 0 5px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.gray3};
  ${({ theme }) => theme.media.mobile} {
    width: 80px;
  }
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.sm};
`;

interface FileProps {
  id: number;
  file: File;
  name: string;
}

function Application(props: any): JSX.Element {
  const [field, setField] = useState<string>();
  const [fieldId, setFieldId] = useState<number>();
  // const [form, setForm] = useState<FormData>();
  const [files, setFiles] = useState<FileProps[]>([]);
  const [countList, setCountList] = useState<number[]>([0]);

  const onAddHandler = () => {
    if (files.length !== countList.length) {
      alert('파일을 첨부한 후 클릭하세요.');
      return;
    }
    let countArr = [...countList];
    let counter = countArr.slice(-1)[0];
    counter += 1;
    countArr.push(counter);
    setCountList(countArr);
  };

  const onRemove = (idx: number) => {
    let countArr = countList.filter((countList) => countList !== idx);
    countArr.forEach((value, index) => {
      countArr[index] = index;
    });
    setCountList(countArr);
  };

  const checkFiles = (files: FileProps[]) => {
    setFiles(files);
  };

  useEffect(() => {
    let attachments: File[] = [];
    files.forEach((value) => {
      attachments = [...attachments, value.file];
    });

    props.getExpertsInfo(fieldId, attachments);
  }, [fieldId, files]);

  const getFieldId = (field: string) => {
    LabelItems.slice(1).forEach((value) => {
      if (value.title === field) {
        setFieldId(value.id);
      }
    });
  };

  return (
    <Wrapper>
      <Container>
        <Title>전문가신청</Title>
        <Explanation>
          전문성 분야를 선택하여 고객님의 전문성을 인증받으세요.
        </Explanation>
        <InnerContainer>
          <FieldContainer>
            <Column>분야</Column>
            <InputContainer>
              <Select
                name="field"
                value={field}
                onChange={({ currentTarget }) => {
                  setField(currentTarget.value);
                  getFieldId(currentTarget.value);
                }}
              >
                <option disabled value="" defaultValue="">
                  {`분야를 선택해주세요.`}
                </option>
                {LabelItems?.map((item, idx) => {
                  return (
                    <option key={idx} value={item.title}>
                      {item.title}
                    </option>
                  );
                })}
              </Select>
              <Svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_592_2077)">
                  <path
                    d="M12 13.1722L16.95 8.22217L18.364 9.63617L12 16.0002L5.63599 9.63617L7.04999 8.22217L12 13.1722Z"
                    fill="#8E8E8E"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_592_2077">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </Svg>
            </InputContainer>
          </FieldContainer>
          <WarningText>
            ⚠️ 허위 서류를 첨부하는 경우 법적 처벌을 받을 수 있습니다.
          </WarningText>
          <UploadBox
            countList={countList}
            onRemove={onRemove}
            getFiles={checkFiles}
          />
          <AddButton onClick={onAddHandler}>
            + 더 많은 파일을 첨부하려면 여기를 클릭하세요.
          </AddButton>
        </InnerContainer>
        <Buttons>
          <CancelButton onClick={props.onPrevHandler}>이전</CancelButton>
          <NextButton onClick={props.onNextHandler}>다음으로</NextButton>
        </Buttons>
      </Container>
    </Wrapper>
  );
}

export default Application;
