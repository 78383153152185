import styled from "styled-components";
import { useState } from "react";
import { useCookies } from "react-cookie";
import qs from "qs";
import axios from "axios";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 730px;
  padding: 40px 30px;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 10px 13px rgba(17, 38, 146, 0.05);
  ${({ theme }) => theme.media.tablet} {
    padding: 40px 20px;
  }
  ${({ theme }) => theme.media.mobile} {
    padding: 40px 20px;
  }
`;

const Title = styled.div`
  display: flex;
  padding-left: 10px;
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
  font-size: ${({ theme }) => theme.fonts.size.md};
`;

const Explanation = styled.div`
  padding-left: 10px;
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  color: ${({ theme }) => theme.colors.gray5};
  margin-top: 15px;
`;

const ScoreContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr 1fr;
  div {
    display: flex;
    margin: 8px 0;
  }
  margin-top: 20px;
  align-items: center;
`;

const ScoreTitleContainer = styled.div`
  padding-right: 20px;
`;

const ScoreTitle = styled.div`
  width: 100%;
  justify-content: flex-end;
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  font-size: ${({ theme }) => theme.fonts.size.md};
  ${({ theme }) => theme.media.tablet} {
    font-size: ${({ theme }) => theme.fonts.size.base};
  }
`;

const InputContainer = styled.div`
  display: flex;
  width: 90%;
  height: 40px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray1};
  align-items: center;
`;

const Input = styled.input.attrs({
  required: true,
})`
  all: unset;
  height: 40px;
  width: 100%;
  padding: 0 10px;
  transition: width 0.2s ease-in-out;
  color: ${({ theme }) => theme.colors.gray6};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.sm};
`;

const TotalScore = styled.div`
  color: ${({ theme }) => theme.colors.gray6};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.base};
  padding-left: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
`;

const SubmitButton = styled.button`
  height: 40px;
  margin: 0 5px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.main};
  width: 140px;
  margin-right: 10px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.main};
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.sm};
`;

const CancelButton = styled.button`
  width: 70px;
  height: 40px;
  margin: 0 5px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.gray3};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.sm};
`;

interface ScoreProps {
  price: string;
  technique: string;
  period: string;
  creativity: string;
  size: string;
}

function Evaluation({
  tokenId,
  handleCancle,
}: {
  tokenId: number;
  handleCancle: () => void;
}): JSX.Element {
  const [cookies] = useCookies(["TOKEN"]);
  const [score, setScore] = useState<ScoreProps>({
    price: "",
    technique: "",
    period: "",
    creativity: "",
    size: "",
  });

  const handlePriceScore = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setScore((prev) => ({ ...prev, price: value }));
  };
  const handleTechniqueScore = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setScore((prev) => ({ ...prev, technique: value }));
  };
  const handlePeriodScore = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setScore((prev) => ({ ...prev, period: value }));
  };
  const handleCreativityScore = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setScore((prev) => ({ ...prev, creativity: value }));
  };
  const handleSizeScore = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setScore((prev) => ({ ...prev, size: value }));
  };

  const handleSubmit = async () => {
    await axios({
      method: "post",
      url: `/debate-evaluation`,
      data: qs.stringify({
        tokenId: tokenId,
        price: score.price,
        tech: score.technique,
        time: score.period,
        unique: score.creativity,
        market: score.size,
      }),
      headers: {
        Authorization: `Bearer ${cookies["TOKEN"]}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    handleCancle();
  };

  return (
    <Wrapper>
      <Container>
        <Title>전문가 평가</Title>
        <Explanation>최종 발행될 아이디어에 대해 평가해주세요.</Explanation>
        <ScoreContainer>
          <ScoreTitleContainer>
            <ScoreTitle>가격</ScoreTitle>
          </ScoreTitleContainer>
          <InputContainer>
            <Input
              placeholder="점수를 입력해 주세요."
              value={score?.price}
              onChange={handlePriceScore}
            />
          </InputContainer>
          <TotalScore>/ 100</TotalScore>
          <ScoreTitleContainer>
            <ScoreTitle>기술 분석</ScoreTitle>
          </ScoreTitleContainer>
          <InputContainer>
            <Input
              placeholder="점수를 입력해 주세요."
              value={score?.technique}
              onChange={handleTechniqueScore}
            />
          </InputContainer>
          <TotalScore>/ 100</TotalScore>
          <ScoreTitleContainer>
            <ScoreTitle>제품화 기간</ScoreTitle>
          </ScoreTitleContainer>
          <InputContainer>
            <Input
              placeholder="점수를 입력해 주세요."
              value={score?.period}
              onChange={handlePeriodScore}
            />
          </InputContainer>
          <TotalScore>/ 100</TotalScore>
          <ScoreTitleContainer>
            <ScoreTitle>독창성</ScoreTitle>
          </ScoreTitleContainer>
          <InputContainer>
            <Input
              placeholder="점수를 입력해 주세요."
              value={score?.creativity}
              onChange={handleCreativityScore}
            />
          </InputContainer>
          <TotalScore>/ 100</TotalScore>
          <ScoreTitleContainer>
            <ScoreTitle>시장 규모</ScoreTitle>
          </ScoreTitleContainer>
          <InputContainer>
            <Input
              placeholder="점수를 입력해 주세요."
              value={score?.size}
              onChange={handleSizeScore}
            />
          </InputContainer>
          <TotalScore>/ 100</TotalScore>
        </ScoreContainer>
        <ButtonContainer>
          <CancelButton onClick={handleCancle}>닫기</CancelButton>
          <SubmitButton onClick={handleSubmit}>평가 제출</SubmitButton>
        </ButtonContainer>
      </Container>
    </Wrapper>
  );
}

export default Evaluation;
