import React from 'react';
import styled from 'styled-components';
import axios from 'axios';

const Guide = () => {
  const downloadFile = async () => {
    axios({
      url: `/download/guide`, // 파일 다운로드 요청 URL
      method: 'GET', // 혹은 'POST'
      responseType: 'blob', // 응답 데이터 타입 정의
    }).then((response) => {
      const blob = new Blob([response.data]);

      // blob을 사용해 객체 URL을 생성합니다.
      const fileObjectUrl = window.URL.createObjectURL(blob);

      // blob 객체 URL을 설정할 링크를 만듭니다.
      const link = document.createElement('a');
      link.href = fileObjectUrl;
      link.style.display = 'none';

      // 다운로드 파일 이름을 지정 할 수 있습니다.
      // 일반적으로 서버에서 전달해준 파일 이름은 응답 Header의 Content-Disposition에 설정됩니다.

      // 다운로드 파일 이름을 추출하는 함수
      const extractDownloadFilename = (response: any) => {
        const disposition = response.headers['content-disposition'];
        const fileName = decodeURI(
          disposition
            ?.split('/')[1]
            ?.substring(14)
            ?.slice(0, -Number(disposition.length) - 1)
        );

        return fileName;
      };

      link.download = extractDownloadFilename(response);

      // 다운로드 파일의 이름은 직접 지정 할 수 있습니다.
      link.download = '가이드.hwp';

      // 링크를 body에 추가하고 강제로 click 이벤트를 발생시켜 파일 다운로드를 실행시킵니다.
      document.body.appendChild(link);
      link.click();
      link.remove();

      // 다운로드가 끝난 리소스(객체 URL)를 해제합니다.
      window.URL.revokeObjectURL(fileObjectUrl);
    });
  };

  return (
    <GuideDiv
      onClick={async () => {
        downloadFile();
      }}
    >
      사용설명서
    </GuideDiv>
  );
};

const GuideDiv = styled.div`
  font-size: 14px;
  color: #fff;
  width: 140px;
  margin: 0 20px;
  text-decoration: underline;
  cursor: pointer;
`;

export default Guide;
