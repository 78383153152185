import axios from "axios";
import styled from "styled-components";
import AdoptedIdea from "components/forum/idea/AdoptedIdea";
import { AdoptedIdeaProps } from "components/forum/idea/AdoptedIdeaProps";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Desktop, Mobile, Tablet } from "@hooks/MediaQuery";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
`;

const Container = styled.div`
  display: flex;
  width: 60%;
  padding: 0 20px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${({ theme }) => theme.media.mobile} {
    width: 100%;
    padding: 0;
  }
  ${({ theme }) => theme.media.tablet} {
    width: 90%;
  }
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: flex-start;
`;

const BackButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 45px;
  border-radius: 50px;
  cursor: pointer;
  :hover {
    background: ${({ theme }) => theme.colors.blue6};
  }
  span {
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.gray6};
    font-size: ${({ theme }) => theme.fonts.size.sm};
    font-weight: ${({ theme }) => theme.fonts.weight.medium};
  }
`;

const Svg = styled.svg`
  width: 15px;
  height: 15px;
`;

const Body = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 50px;
`;

const IdeaInformation = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  ${({ theme }) => theme.media.mobile} {
    display: flex;
    flex-direction: column;
  }
`;

const Mine = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  ${({ theme }) => theme.media.mobile} {
    flex-direction: row;
    margin: 10px 40px;
    align-items: center;
    justify-content: space-between;
  }
`;

const Title = styled.span`
  color: ${({ theme }) => theme.colors.gray6};
  font-size: ${({ theme }) => theme.fonts.size.xl};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  margin: 10px 0;
  ${({ theme }) => theme.media.mobile} {
    margin: 0;
  }
`;

const MyShare = styled.span`
  color: ${({ theme }) => theme.colors.gray6};
  font-size: ${({ theme }) => theme.fonts.size.md};
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
  margin: 20px 0;
  span {
    color: ${({ theme }) => theme.colors.error};
  }
  ${({ theme }) => theme.media.mobile} {
    margin: 0;
  }
`;

const ShareTable = styled.div`
  width: 100%;
  margin-top: 50px;
  box-shadow: 0px 5px 7px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  ${({ theme }) => theme.media.mobile} {
    width: 80%;
  }
`;

const BoardHead = styled.div`
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
`;

const Row = styled.div<{ idx: number; mine?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 4fr;
  padding: 15px 0;
  height: 30px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray1};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  color: ${(props) =>
    props.mine
      ? ({ theme }) => theme.colors.error
      : ({ theme }) => theme.colors.gray5};
  background: ${(props) =>
    props.idx % 2 === 0
      ? ({ theme }) => theme.colors.gray0
      : ({ theme }) => theme.colors.white};
  div {
    display: flex;
    place-items: center;
    padding-right: 10px;
    flex-wrap: wrap;
    word-break: break-all;

    &:first-child {
      padding-left: 20px;
      ${({ theme }) => theme.media.mobile} {
        padding-left: 10px;
      }
    }
  }
  &:first-child {
    height: 15px;
    border-radius: 5px 5px 0 0;
    font-size: ${({ theme }) => theme.fonts.size.base};
    font-weight: ${({ theme }) => theme.fonts.weight.regular};
    color: ${({ theme }) => theme.colors.gray4};
    background: ${({ theme }) => theme.colors.gray7};
    border-bottom: none;
    padding: 15px 0;
  }
`;

interface SharedProps {
  nickname: string;
  walletAddress: string;
  properties: number;
}

function Share(): JSX.Element {
  const [searchParams] = useSearchParams();
  const [postId, setPostId] = useState<number>(0);
  const navigate = useNavigate();
  const [cookies] = useCookies(["TOKEN", "USER-INFORMATION"]);
  const [adoptedIdeaProps, setAdoptedIdeaProps] = useState<AdoptedIdeaProps>({
    tokenNumber: 0,
    originalToken: 0,
    owner: "",
    mintDate: "",
    contributors: 0,
    txHash: "",
  });
  const [sharedInfo, setSharedInfo] = useState<SharedProps[]>([]);
  const [mine, setMine] = useState<number>(0);

  const getCurrentPost = useCallback(
    async (postId: number) => {
      try {
        const { data } = await axios({
          method: "get",
          url: `/idea/token/${postId}`,
        });
        const debate = await axios({
          method: "get",
          url: `/debate/${postId}`,
        });

        let count = 0;
        const proposals = debate.data.data.proposals;
        for (let i = 0; i < proposals.length; i++) {
          if (proposals[i].isSelected) {
            count += 1;
          }
        }

        let date = data.data.debate.createdAt;
        const dateStr =
          date.substring(0, 4) +
          "년 " +
          date.substring(5, 7) +
          "월 " +
          date.substring(8, 10) +
          "일";

        const props = {
          tokenNumber: data.data.debate.finalTokenId,
          originalToken: postId,
          owner: data.data.author.nickname,
          mintDate: dateStr,
          contributors: count,
          txHash: data.data.debate.finalTokenTxHash,
        };
        setAdoptedIdeaProps(props);
        getShareInfo(data.data.debate.finalTokenId);
      } catch (e) {
        navigate(-1);
      }
    },
    [navigate]
  );

  const getShareInfo = useCallback(
    async (tokenId: number) => {
      try {
        const { data } = await axios({
          method: "post",
          url: `/debate/getMetadata/${tokenId}`,
        });
        data.data.forEach((value: SharedProps) => {
          value.nickname === cookies["USER-INFORMATION"].nickname &&
            setMine(value.properties);
        });
        setSharedInfo(data.data);
      } catch (e) {
        navigate(-1);
      }
    },
    [navigate]
  );

  useEffect(() => {
    const postId = Number(searchParams.get("id"));
    setPostId(postId);
    getCurrentPost(postId);
  }, [searchParams, getCurrentPost]);

  return (
    <Wrapper>
      <Container>
        <Header>
          <BackButton onClick={() => navigate(-1)}>
            <Svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.828 7.00066H16V9.00066H3.828L9.192 14.3647L7.778 15.7787L0 8.00066L7.778 0.222656L9.192 1.63666L3.828 7.00066Z"
                fill="#141414"
              />
            </Svg>
            <span>본문으로 돌아가기</span>
          </BackButton>
        </Header>
        <Body>
          <IdeaInformation>
            <Mobile>
              <Mine>
                <Title>Token #{adoptedIdeaProps.tokenNumber}의 지분</Title>
                <MyShare>
                  My Share : <span>{mine}%</span>
                </MyShare>
              </Mine>
            </Mobile>
            <AdoptedIdea props={adoptedIdeaProps} />
            <Desktop>
              <Mine>
                <Title>Token #{adoptedIdeaProps.tokenNumber}의 지분</Title>
                <MyShare>
                  My Share : <span>{mine}%</span>
                </MyShare>
              </Mine>
            </Desktop>
            <Tablet>
              <Mine>
                <Title>Token #{adoptedIdeaProps.tokenNumber}의 지분</Title>
                <MyShare>
                  My Share : <span>{mine}%</span>
                </MyShare>
              </Mine>
            </Tablet>
          </IdeaInformation>
          <ShareTable>
            <BoardHead>
              <Row idx={-1}>
                <div>Share</div>
                <div>Nickname</div>
                <div>WalletAddress</div>
              </Row>
            </BoardHead>
            {sharedInfo.map((item, index) => (
              <Row
                key={index}
                idx={index}
                mine={item.nickname === cookies["USER-INFORMATION"].nickname}
              >
                <div>{item.properties}%</div>
                <div>{item.nickname}</div>
                <div>{item.walletAddress}</div>
              </Row>
            ))}
          </ShareTable>
        </Body>
      </Container>
    </Wrapper>
  );
}

export default Share;
