import styled from 'styled-components';

import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { ExpertModalState } from 'atoms/ModalState';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  ${({ theme }) => theme.media.mobile} {
    width: 240px;
  }
  padding: 30px 40px 40px 40px;
  width: 650px;
  display: flex;
  margin: 100px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 15px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 3px 5px 0px ${({ theme }) => theme.colors.gray2};
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  margin: 40px;
`;

const Title = styled.span`
  font-size: ${({ theme }) => theme.fonts.size.md};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  color: ${({ theme }) => theme.colors.gray6};
  margin: 10px;
`;

const Detail = styled.span`
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  color: ${({ theme }) => theme.colors.gray5};
  margin-bottom: 5px;
`;

const ToHome = styled.button`
  height: 40px;
  margin: 50px 0 30px 0;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.main};
  width: 140px;
  margin-right: 10px;
  ${({ theme }) => theme.media.mobile} {
    width: 130px;
  }
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.main};
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.sm};
`;

function Completed(props: { user: string; userName: string }): JSX.Element {
  const navigate = useNavigate();
  // const [agreeModal, setAgreeModal] = useRecoilState(ExpertModalState);

  const navigateToHome = () => {
    navigate('/');
    // setAgreeModal(false);
  };

  if (props.user === 'expert') {
    return (
      <Wrapper>
        <Container>
          <Logo>
            <img
              src={require('assets/general-member.png')}
              alt="member"
              height={100}
            />
          </Logo>
          <Title>회원가입이 완료되었습니다.</Title>
          <Detail>{props.userName}님 회원가입을 축하합니다.</Detail>
          <Detail>전문가인증은 관리자 승인 후 처리될 예정입니다.</Detail>
          <ToHome onClick={navigateToHome}>메인으로</ToHome>
        </Container>
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        <Container>
          <Logo>
            <img
              src={require('assets/general-member.png')}
              alt="member"
              height={100}
            />
          </Logo>
          <Title>회원가입이 완료되었습니다.</Title>
          <Detail>{props.userName}님 회원가입을 축하합니다.</Detail>
          <ToHome onClick={navigateToHome}>메인으로</ToHome>
        </Container>
      </Wrapper>
    );
  }
}

export default Completed;
