import styled from 'styled-components';
import { useState, useEffect, useRef, KeyboardEvent } from 'react';
import { Avatar, Select } from 'antd';
import Calendar from 'react-calendar';
import './Calendar.css';
import axios from 'axios';
import qs from 'qs';
import EmailSelect from './EmailSelect';
import { useRecoilState } from 'recoil';
import { emailAddressState, emailIdState } from 'atoms/SignIn';
import { useRecoilValue } from 'recoil';

import NewCalendar from './NewCalendar';

import { birthState } from 'atoms/SignIn';

const idRegex = /^[a-z0-9]*$/;
const passwordRegex =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/;

const emailRegex =
  /^(([^<>()\].,;:\s@"]+(\.[^<>()\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

function Information(props: any): JSX.Element {
  const [emailAddress, setEmailAddress] = useRecoilState(emailAddressState);

  const [birthSate, setBirthState] = useRecoilState<string>(birthState);

  console.log('birthSate', birthSate);
  const [userPhoneNumber, setUserPhoneNumber] = useState(0 || '');

  const [emailValue, setEmailValue] = useState(0 || '');
  const calendarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setInformationForm({
      ...informationForm,
      birth: birthSate,
      email_address: emailAddress === '직접 입력' ? emailValue : emailAddress,
      phoneNumber: userPhoneNumber,
    });
  }, [birthSate, emailAddress, userPhoneNumber]);

  const [informationForm, setInformationForm] = useState({
    // profileImage: '' || null,
    userId: '',
    password: '',
    nickname: '',
    name: '',
    gender: 'M',
    birth: birthSate,
    email_id: '',
    email_address: emailAddress === '직접 입력' ? emailValue : emailAddress,
    phoneNumber: userPhoneNumber,
    interest: [],
  });

  console.log('info', informationForm);
  const [userIdState, setUserIdState] = useState({
    success: false,
    message: '',
  });

  const [nicknameState, setNicknameState] = useState({
    success: false,
    message: '',
  });

  const [profileImage, setProfileImage] = useState<string>(
    require('@assets/user-default.png')
  );

  const [passwordState, setPasswordState] = useState({
    checkPassword: '',
    errMsg: '',
    isAvailable: false,
    verified: false,
  });

  const [visibleCalendar, setVisibleCalender] = useState<boolean>(false);
  const fileInput = useRef<HTMLInputElement>(null);

  const genderList = ['M', 'F'];

  const handleIdficationInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setInformationForm((prev: any) => ({ ...prev, userId: value }));
    if (idRegex.test(value)) {
      setUserIdState({
        success: true,
        message: '',
      });
    } else {
      setUserIdState({
        success: false,
        message: '영문(소문자)과 숫자로만 조합해주세요.',
      });
    }
  };
  const handlePasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setInformationForm((prev: any) => ({ ...prev, password: value }));
    setPasswordState((prev) => ({
      ...prev,
      isAvailable: passwordRegex.test(value) ? true : false,
    }));
  };
  const handleCheckPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setPasswordState((prev) => ({ ...prev, checkPassword: value }));

    if (value === informationForm.password && passwordRegex.test(value)) {
      setPasswordState((prev) => ({
        ...prev,
        errMsg: '비밀번호가 일치합니다.',
        verified: true,
      }));
    } else {
      setPasswordState((prev) => ({
        ...prev,
        errMsg: '비밀번호가 일치하지 않습니다.',
        verified: false,
      }));
    }
  };
  const handleNicknameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setInformationForm((prev: any) => ({ ...prev, nickname: value }));
  };
  const handleNameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setInformationForm((prev: any) => ({ ...prev, name: value }));
  };
  const handleGenderInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    let gender = 'F';
    if (value === 'M') {
      gender = 'M';
    }
    setInformationForm((prev: any) => ({ ...prev, gender: gender }));
  };
  const handleBirthInput = (value: Date) => {
    setInformationForm((prev: any) => ({ ...prev, birth: value }));
  };

  const handleEmailInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setInformationForm((prev: any) => ({
      ...prev,
      email_id: value,
    }));
  };

  const handleEmailAddressInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInformationForm((prev: any) => ({
      ...prev,
      email_address: e.target.value,
    }));
    setEmailValue(e.target.value);
  };

  // const handleEmailInput = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { value } = e.currentTarget;
  //   setInformationForm((prev: any) => ({ ...prev, email: value }));
  //   setEmailState(emailRegex.test(value));
  // };

  const handlePhoneNumberInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setInformationForm((prev: any) => ({ ...prev, phoneNumber: value }));
  };

  const handleProfileImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = (e.currentTarget.files as FileList)[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setInformationForm((prev: any) => ({
        ...prev,
        profileImage: file,
      }));
      setProfileImage(reader.result as string);
    };
  };

  const checkId = async () => {
    if (userIdState.success) {
      const { data } = await axios({
        method: 'post',
        url: `/user/check`,
        data: qs.stringify({
          userId: informationForm.userId,
        }),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      if (data.data) {
        setUserIdState({ success: false, message: '중복된 아이디입니다.' });
      } else {
        setUserIdState({
          success: true,
          message: '사용 가능한 아이디입니다.',
        });
      }
    } else {
      alert('아이디를 확인해주세요.');
    }
  };

  const checkNickname = async () => {
    const { data } = await axios({
      method: 'post',
      url: `/user/check`,
      data: qs.stringify({
        nickname: informationForm.nickname,
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    if (data.data) {
      setNicknameState({ success: false, message: '중복된 닉네임입니다.' });
    } else {
      setNicknameState({
        success: true,
        message: '사용 가능한 닉네임입니다.',
      });
    }
  };

  const handleIdCheckPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      checkId();
    }
  };

  const handleNicknameCheckPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      checkNickname();
    }
  };

  const validate = (): boolean => {
    // if (informationForm.profileImage === "") {
    //   alert("프로필 사진을 첨부해주세요.");
    //   return false;
    // }
    if (informationForm.userId === '') {
      alert('아이디를 입력해주세요.');
      return false;
    } else if (!userIdState.success) {
      alert('아이디를 확인해주세요.');
      return false;
    }
    if (informationForm.password === '') {
      alert('비밀번호를 입력해주세요.');
      return false;
    } else if (!passwordState.isAvailable || !passwordState.verified) {
      alert('비밀번호를 확인해주세요.');
      return false;
    }
    if (informationForm.nickname === '') {
      alert('닉네임을 입력해주세요.');
      return false;
    } else if (!nicknameState.success) {
      alert('닉네임을 확인해주세요.');
      return false;
    }
    if (informationForm.name === '') {
      alert('이름을 입력해주세요.');
      return false;
    }
    if (informationForm.email_id === '') {
      alert('이메일을 입력해주세요.');
      return false;
      // } else if (!emailState) {
      //   alert('이메일을 확인해주세요.');
      //   return false;
    }
    if (informationForm.phoneNumber === '') {
      alert('휴대폰 번호를 입력해주세요.');
      return false;
    }
    if (informationForm.phoneNumber.includes('-')) {
      alert(`휴대폰 번호를 - 를 제거 후 입력해주세요.`);
      return false;
    }

    return true;
  };

  const onNextHandler = () => {
    if (validate()) {
      props.onNextHandler();
      props.getInformation(informationForm);
    }
  };

  useEffect(() => {
    const clickOutside = (e: any) => {
      if (visibleCalendar && !calendarRef.current?.contains(e.target)) {
        setVisibleCalender(false);
      }
    };
    document.addEventListener('mousedown', clickOutside);
    return () => {
      document.removeEventListener('mousedown', clickOutside);
    };
  }, [visibleCalendar]);

  useEffect(() => {
    setVisibleCalender(false);
  }, [informationForm.birth]);

  return (
    <Wrapper>
      <Container>
        <Title>정보 입력</Title>
        <ImageContainer>
          <ImageBox>
            <ProfileImage src={profileImage} size={120} />
            <CameraButton
              src={require('@assets/camera-button.png')}
              onClick={() => {
                fileInput.current?.click();
              }}
            />
            <input
              type="file"
              style={{ display: 'none' }}
              accept="image/*"
              name="profile_img"
              onChange={handleProfileImage}
              ref={fileInput}
            />
          </ImageBox>
        </ImageContainer>
        <FormContainer>
          <FormTitle>아이디</FormTitle>
          <CheckContainer>
            <IdContainer>
              <InputContainer>
                <Input
                  title="영문과 숫자를 20자 이내로 조합"
                  placeholder="아이디를 입력해 주세요."
                  value={informationForm.userId}
                  onChange={handleIdficationInput}
                  onKeyPress={handleIdCheckPress}
                />
              </InputContainer>
              <Message success={userIdState.success}>
                {userIdState.message}
              </Message>
            </IdContainer>
            <ButtonContainer>
              <CheckButton onClick={checkId}>중복확인</CheckButton>
            </ButtonContainer>
          </CheckContainer>

          <FormTitle>비밀번호</FormTitle>
          <InputContainer>
            <Input
              type="password"
              placeholder="비밀번호를 입력해 주세요."
              value={informationForm.password}
              onChange={handlePasswordInput}
            />
          </InputContainer>
          <FormTitle>비밀번호 확인</FormTitle>
          <div>
            <InputContainer>
              <Input
                type="password"
                placeholder="비밀번호를 한 번 더 입력해 주세요."
                value={passwordState.checkPassword}
                onChange={handleCheckPassword}
              />
            </InputContainer>
            <PasswordDescription
              isAvailable={
                informationForm.password ? passwordState.isAvailable : true
              }
            >
              {passwordState.isAvailable ? (
                passwordState.checkPassword && (
                  <Message success={passwordState.verified}>
                    {passwordState.errMsg}
                  </Message>
                )
              ) : (
                <span>
                  8자 이상 ~ 20자 이내로 영문자와 숫자, 특수문자를 포함해주세요.
                </span>
              )}
            </PasswordDescription>
          </div>

          <FormTitle>닉네임</FormTitle>
          <CheckContainer>
            <IdContainer>
              <InputContainer>
                <Input
                  placeholder="닉네임을 입력해 주세요."
                  value={informationForm.nickname}
                  onChange={handleNicknameInput}
                  onKeyPress={handleNicknameCheckPress}
                />
              </InputContainer>
              <Message success={nicknameState.success}>
                {nicknameState.message}
              </Message>
            </IdContainer>

            <ButtonContainer>
              <CheckButton onClick={checkNickname}>중복확인</CheckButton>
            </ButtonContainer>
          </CheckContainer>

          <FormTitle>이름</FormTitle>
          <InputContainer>
            <Input
              placeholder="이름을 입력해 주세요."
              value={informationForm.name}
              onChange={handleNameInput}
            />
          </InputContainer>

          <FormTitle>성별</FormTitle>
          <Tags>
            {genderList?.map((item, idx) => {
              return (
                <TagLabel key={idx} check={informationForm.gender === item}>
                  {item === 'M' ? '남자' : '여자'}
                  <Tag
                    type="radio"
                    name="label"
                    value={item}
                    onChange={handleGenderInput}
                  />
                </TagLabel>
              );
            })}
          </Tags>
          <FormTitle>생년월일</FormTitle>

          <InputContainer>
            <NewCalendar />

            {/* <Svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => {
                setVisibleCalender(!visibleCalendar);
              }}
            >
              <g clipPath="url(#clip0_837_934)">
                <path
                  d="M17 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H7V1H9V3H15V1H17V3ZM20 11H4V19H20V11ZM15 5H9V7H7V5H4V9H20V5H17V7H15V5ZM6 13H8V15H6V13ZM11 13H13V15H11V13ZM16 13H18V15H16V13Z"
                  fill="#838383"
                />
              </g>
              <defs>
                <clipPath id="clip0_837_934">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </Svg>
            {visibleCalendar && (
              <div ref={calendarRef}>
                <CalendarPicker
                  value={calendarDate}
                  onChange={handleBirthInput}
                  formatDay={(locale, date) =>
                    date.toLocaleDateString('en', { day: 'numeric' })
                  }
                  calendarType="US"
                  locale="en"
                  showNeighboringMonth={false}
                />
              </div>
            )} */}
          </InputContainer>
          <FormTitle>이메일</FormTitle>
          <div>
            <InputContainer>
              <Input onChange={handleEmailInput} />
              <span>@</span>
              {emailAddress === '직접 입력' ? (
                <Input onChange={handleEmailAddressInput} />
              ) : (
                <DisabledInput type="text" value={`${emailAddress}`} disabled />
              )}
              <EmailSelect />
              {/* {email === '직접 입력' ? null : <EmailSelect />}
              {email === '직접 입력' ? (
                <Input
                  type="email"
                  placeholder="이메일을 입력해주세요."
                  value={informationForm.email}
                  onChange={handleEmailInput}
                />
              ) : null} */}
            </InputContainer>

            {/* {informationForm.email && !emailState && (
              <Message success={false}>
                이메일 형식이 일치하지 않습니다.
              </Message>
            )} */}
          </div>
          <FormTitle>휴대폰번호</FormTitle>
          <InputContainer>
            <Input
              type="tel"
              placeholder="휴대폰번호를 입력해주세요."
              value={informationForm.phoneNumber}
              onChange={(e: any) =>
                setInformationForm({
                  ...informationForm,
                  phoneNumber: e.target.value,
                })
              }
            />
          </InputContainer>
        </FormContainer>
        <Buttons>
          <CancelButton onClick={props.onPrevHandler}>이전</CancelButton>
          <NextButton onClick={onNextHandler}>다음으로</NextButton>
        </Buttons>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  ${({ theme }) => theme.media.mobile} {
    width: 240px;
  }
  padding-top: 30px;
  padding: 40px;
  width: 650px;
  display: flex;
  margin: 100px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 15px;
  flex-direction: column;
  box-shadow: 2px 3px 5px 0px ${({ theme }) => theme.colors.gray2};
`;

const Title = styled.div`
  margin: 0 0 30px 0;
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
  font-size: ${({ theme }) => theme.fonts.size.md};
`;

const FormContainer = styled.div`
  display: grid;
  position: relative;
  margin: 20px;
  grid-template-columns: 170px 400px;
  grid-template-rows: 65px 55px 65px 65px 55px 55px 55px 65px 55px;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.media.mobile} {
    margin: 0px;
    grid-template-columns: 0px 100%;
  }
`;

const FormTitle = styled.span`
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  ${({ theme }) => theme.media.mobile} {
    font-size: 0;
  }
`;

const InputContainer = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  height: 40px;

  align-items: center;
  justify-content: center;
  span {
    margin: 0 5px;
  }
`;

const Input = styled.input.attrs({
  required: true,
})`
  all: unset;
  height: 40px;
  width: 100%;
  padding: 0 10px;
  transition: width 0.2s ease-in-out;
  color: ${({ theme }) => theme.colors.gray6};
  font-size: ${({ theme }) => theme.fonts.size.xs};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.gray1};
`;

const DisabledInput = styled.input.attrs({
  required: true,
})`
  all: unset;
  height: 40px;
  width: 100%;
  padding: 0 10px;
  transition: width 0.2s ease-in-out;
  background-color: ${({ theme }) => theme.colors.gray1};
  color: ${({ theme }) => theme.colors.gray6};
  font-size: ${({ theme }) => theme.fonts.size.xs};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.gray1};
  margin-right: 5px;
`;

const CheckContainer = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CheckButton = styled.button`
  width: 70px;
  height: 34px;
  margin-left: 10px;
  background-color: ${({ theme }) => theme.colors.gray1};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  color: ${({ theme }) => theme.colors.gray6};
  font-size: ${({ theme }) => theme.fonts.size.xs};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.colors.gray2};
  }
`;

const PasswordDescription = styled.div<{ isAvailable: boolean }>`
  font-size: ${({ theme }) => theme.fonts.size.xxs};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  color: ${({ isAvailable, theme }) =>
    isAvailable ? theme.colors.gray3 : theme.colors.error};
  margin-top: 5px;
  span {
    margin-left: 10px;
  }

  ${({ theme }) => theme.media.mobile} {
    margin-left: 0px;
    font-size: 0.6rem;
  }
`;

const Tags = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TagLabel = styled.label<{ check: boolean }>`
  display: flex;
  padding: 10px;
  height: 10px;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.fonts.size.xs};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.colors.blue6};
  }
  background-color: ${({ check, theme }) =>
    check ? theme.colors.blue4 : theme.colors.black};
  color: ${({ check, theme }) => check && theme.colors.black};
  border: 1px solid
    ${({ check, theme }) => (check ? theme.colors.blue2 : theme.colors.gray1)};
`;

const Tag = styled.input`
  display: flex;
  width: 0;
  margin-right: -5px;
  appearance: none;
`;

const Buttons = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

const NextButton = styled.button`
  height: 40px;
  margin: 0 5px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.main};
  width: 140px;
  margin-right: 10px;
  ${({ theme }) => theme.media.mobile} {
    width: 130px;
  }
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.main};
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.sm};
`;

const CancelButton = styled.button`
  width: 70px;
  height: 40px;
  margin: 0 5px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.gray3};
  ${({ theme }) => theme.media.mobile} {
    width: 80px;
  }
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.sm};
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageBox = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
`;

const ProfileImage = styled(Avatar)`
  display: flex;
  margin: 20px;
  width: 130px;
  height: 130px;
  border: 2px solid ${({ theme }) => theme.colors.gray2};
`;

const CameraButton = styled.img`
  position: absolute;
  width: 30px;
  height: 30px;
  top: 105px;
  left: 105px;
  cursor: pointer;
`;

const CalendarPicker = styled(Calendar)`
  position: absolute;
  top: 390px;
  left: 310px;
  ${({ theme }) => theme.media.mobile} {
    top: 400px;
    left: 0px;
  }
`;

const Svg = styled.svg`
  margin-right: 10px;
  width: 21px;
  height: 21px;
  cursor: pointer;
`;

const IdContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const Message = styled.span<{ success: boolean }>`
  margin-left: 10px;
  font-size: ${({ theme }) => theme.fonts.size.xxs};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  color: ${({ success, theme }) =>
    success ? theme.colors.blue2 : theme.colors.error};
`;

export default Information;
