import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import axios from 'axios';
import { PostProps } from 'components/post/IdeaProps';
import { useCookies } from 'react-cookie';
import NoItem from 'components/community/idea/NoItem';
import Cards from 'components/post/Cards';
import PageController from 'components/global/PageController';

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 20px;
`;

const CategoryTitle = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.lg};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  color: ${({ theme }) => theme.colors.gray6};
  margin-bottom: 10px;
`;

const CategoryExplanation = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.base};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  color: ${({ theme }) => theme.colors.gray4};
  margin-bottom: 40px;
`;

const Page = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

function Vote(): JSX.Element {
  const [searchParams] = useSearchParams();
  const [posts, setPosts] = useState<PostProps[]>();
  const [page, setPage] = useState<number>(1);
  const [boardsCount, setBoardsCount] = useState<number>(0);
  const [cookies] = useCookies(['TOKEN', 'USER-INFORMATION']);
  const location = useLocation();
  const navigate = useNavigate();

  const getPosts = useCallback(
    async (page: number) => {
      setBoardsCount(0);
      const params = { page: page };

      try {
        const { data } = await axios({
          method: 'get',
          url: `/user/mypage/vote`,
          params: params,
          headers: {
            Authorization: `Bearer ${cookies['TOKEN']}`,
          },
        });
        setPosts(data.data.data);
        setBoardsCount(data.data.count);
      } catch (e) {
        console.error(e);
      }
    },
    [cookies]
  );

  useEffect(() => {
    let page = Number(searchParams.get('page'));
    setPage(page);
    getPosts(page);
  }, [searchParams, getPosts]);

  useEffect(() => {
    navigate(`/mypage/vote?id=${cookies['USER-INFORMATION'].id}&page=1`);
  }, [navigate]);

  return (
    <Wrapper>
      <VoteDiv>
        <CategoryTitle>My 투표 정보</CategoryTitle>
        <CategoryExplanation>
          내가 투표한 아이디어의 정보를 볼 수 있습니다.
        </CategoryExplanation>
        {boardsCount > 0 ? (
          <>
            <Container>
              {posts?.map((post, id) => (
                <Link key={post.id} to={`/community/idea?id=${post.id}`}>
                  <Cards
                    key={id}
                    id={post.id}
                    post={post}
                    debateId={post.debate?.tokenId}
                  />
                </Link>
              ))}
            </Container>
            <Page>
              <PageController
                postCount={boardsCount}
                currentPage={page}
                uri={location.pathname + location.search.split('&')[0]}
                pagePostCount={6}
              />
            </Page>
          </>
        ) : (
          <NoItem description={'존재하는 아이디어가 없습니다.'} />
        )}
      </VoteDiv>
    </Wrapper>
  );
}

const VoteDiv = styled.div`
  width: 884px;
  margin-top: 80px;
`;

export default Vote;
