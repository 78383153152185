import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
// import useIntersectionObsever from 'hooks/UseIntersectionObserver';
import { HomeProps } from './HomeProps';
import { useRecoilState } from 'recoil';
import { useMediaQuery } from 'react-responsive';
import { LoginState } from 'atoms/LoginState';

// components
import Contents from './Contents';
import IdeaCount from './IdeaCount';
import Button from './Button';
import Guide from './Guide';

// function easeOutExpo(t: number): number {
//   return t === 1 ? 1 : 1 - Math.pow(2, -10 * t);
// }

function DesktopHome({ countList }: { countList: HomeProps }): JSX.Element {
  const [loginState] = useRecoilState(LoginState);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const isMobile = useMediaQuery({ maxWidth: 780 });

  const navigate = useNavigate();

  const [modalMessage, setModalMessage] =
    useState<string>('로그인 후 이용해주세요.');

  return (
    <StyledBox>
      <div className="container">
        <Section>
          <MainDiv>
            <MainContainer>
              <MainTitle>{`탈 중앙 집단지성 기반 아이디어 서비스`}</MainTitle>
              <HeaderExplanation>
                의견을 다른 사람과 함께 완성하고 블록체인에 기록하세요
              </HeaderExplanation>
              <ButtonDiv>
                <Button
                  onClick={() => navigate('/community?page=1')}
                  bg="black"
                  color="white"
                  width="191px"
                  height="56px"
                  size="16px"
                  radius="28px"
                  border="none"
                  cursor
                  display="flex"
                  margin="0px 40px 0px 0px"
                >
                  <Svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.172 6.99992L6.808 1.63592L8.222 0.221924L16 7.99992L8.222 15.7779L6.808 14.3639L12.172 8.99992H0V6.99992H12.172Z"
                      fill="white"
                    />
                  </Svg>
                  <p>아이디어 구경하기</p>
                </Button>

                <Link to="/community/editor">
                  <Button
                    onClick={() => {
                      if (!loginState || isMobile) {
                        setIsOpenModal(true);
                        setModalMessage(
                          isMobile
                            ? '아이디어 작성은 PC / 태블릿에서만 가능합니다.'
                            : '로그인 후 이용해주세요.'
                        );
                      }
                    }}
                    bg="white"
                    color="black"
                    width="191px"
                    height="56px"
                    size="16px"
                    radius="28px"
                    border="3px solid black"
                    cursor
                    display="flex"
                  >
                    <Svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.172 6.99992L6.808 1.63592L8.222 0.221924L16 7.99992L8.222 15.7779L6.808 14.3639L12.172 8.99992H0V6.99992H12.172Z"
                        fill="black"
                      />
                    </Svg>
                    <p>아이디어 등록하기</p>
                  </Button>
                </Link>
              </ButtonDiv>
            </MainContainer>
            <MainImg src={require('assets/dan_main.png')} />
            {/* <LogoContainer>
              <MainLogo src={require('assets/main-logo1.png')} />
              <SubLogo src={require('assets/main-logo2.png')} />
            </LogoContainer> */}
          </MainDiv>
        </Section>
        <Contents />
        <IdeaCount countList={countList} />
      </div>
    </StyledBox>
  );
}

const MainImg = styled.img`
  width: 30%;
  margin-top: 50px;
`;

// const GuideDiv = styled.div`
//   width: 60%;
//   display: flex;
//   justify-content: end;
// `;

const StyledBox = styled.div`
  width: 100%;
  .container {
    scroll-behavior: smooth;
    height: 100vh;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    width: 100%;
  }
  .container::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  .container > div {
    scroll-snap-align: start;
  }
`;

const Section = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: linear-gradient(#3939ff 35%, #6d94ff);
  position: relative;
  /* background-image: url(${require('assets/home.png')}); */
  /* background-position: center; */
  /* background-size: cover; */
  /* background-repeat: no-repeat; */
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const MainTitle = styled.div`
  display: flex;
  flex-direction: column;
  white-space: pre-line;
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  line-height: 100px;
  font-size: 37.9px;
  color: #fff;
`;

const HeaderExplanation = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.white};
  font-size: 21.33px;
`;

const MoreButton = styled.button`
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 56px;
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
  font-size: ${({ theme }) => theme.fonts.size.base};
  color: white;
  background-color: black;
  border-radius: 28px;
  border: none;
  margin-top: 40px;
  :hover {
    background: ${({ theme }) => theme.colors.blue5};
  }
  cursor: pointer;
`;

const Svg = styled.svg`
  margin-right: 10px;
`;

const ButtonDiv = styled.div`
  display: flex;
  margin-top: 30px;
`;

export default DesktopHome;
