import React, { useState } from 'react';
import styled from 'styled-components';

interface ContentProps {
  desc: string;
  title: string;
  img: string;
}

const MobileContent = ({ title, desc, img }: ContentProps) => {
  return (
    <ContentContainer>
      <ContnetDiv>
        <ContentImg src={require(`assets/${img}`)} />
        <Title>{title}</Title>
        <Desc>{desc}</Desc>
      </ContnetDiv>
    </ContentContainer>
  );
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 480px;
`;

const ContnetDiv = styled.div`
  width: 280px;
  height: 422px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0px 10px 13px 0px rgba(17, 38, 146, 0.05);
`;
const Title = styled.div`
  color: #141414;
  font-size: 21.328px;
  margin-top: 40px;
`;
const ContentImg = styled.img`
  width: 225px;
  height: 200px;
`;
const Desc = styled.div`
  color: #141414;
  font-size: 16px;
  margin-top: 8px;
  white-space: pre-wrap;
  text-align: center;
  line-height: 160%;
`;

export default MobileContent;
