import React from 'react';

import { DatePicker } from 'antd';
import type { DatePickerProps } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import useState from 'react';
import { useRecoilState } from 'recoil';
import { birthState } from '../../atoms/SignIn';

dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;

const dateFormat = 'YYYY/MM/DD';
const weekFormat = 'MM/DD';
const monthFormat = 'YYYY/MM';

const currentDate = new Date();

const currentDates =
  currentDate.getMonth() + 1 <= 9
    ? `${currentDate.getFullYear()} 0${
        currentDate.getMonth() + 1
      }${currentDate.getDate()}`.replace(/ /g, '')
    : `${currentDate.getFullYear()} ${
        currentDate.getMonth() + 1
      }${currentDate.getDate()}`.replace(/ /g, '');

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];

const NewCalendar = () => {
  const [targetBirth, setTargetBirth] = useRecoilState(birthState);

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    setTargetBirth(dateString.replace(/\//g, '').slice(2));
  };

  const onChangeErr = () => {
    const targetDatestr = targetBirth.replace(/\//g, '');
  };

  return (
    <div>
      <DatePicker
        style={{
          width: '400px',
          height: '42.5px',
          border: '1px solid #ebebeb',
          borderRadius: '4px',
        }}
        defaultValue={dayjs(`${currentDate.getFullYear()}/01/01`, dateFormat)}
        format={dateFormat}
        onChange={onChange}
      />
    </div>
  );
};

export default NewCalendar;
