import styled from 'styled-components';
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import PageController from 'components/global/PageController';
import NoItem from '@components/community/idea/NoItem';

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;

  justify-content: center;
  /* align-items: center; */
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  /* margin: 20px auto; */
  width: 100%;
`;

const CategoryTitle = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.lg};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  color: ${({ theme }) => theme.colors.gray6};
  margin-bottom: 10px;
`;

const CategoryExplanation = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.base};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  color: ${({ theme }) => theme.colors.gray4};
  margin-bottom: 40px;
`;

const BoardContainer = styled.div`
  width: 100%;
  box-shadow: 0px 3px 3px 3px rgba(0, 0, 0, 0.05);
`;

const BoardHead = styled.div`
  width: 100%;
  border-collapse: collapse;
`;

const Row = styled.div<{ idx: number }>`
  width: 100%;
  display: grid;
  grid-template-columns: 0.6fr 0.3fr 1.5fr 2.8fr 0.3fr;
  ${({ theme }) => theme.media.mobile} {
    grid-template-columns: 1.2fr 0.8fr 1fr 1.2fr 0.5fr;
  }
  padding: 15px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray1};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  color: ${({ theme }) => theme.colors.gray5};
  background: ${(props) =>
    props.idx % 2 === 0
      ? ({ theme }) => theme.colors.white
      : ({ theme }) => theme.colors.gray0};
  div {
    display: flex;
    place-items: center;
    padding-right: 10px;
    &:last-child {
      border-right: none;
      justify-content: center;
    }
    flex-wrap: wrap;
    word-break: break-all;
    &:first-child {
      padding-left: 20px;
      ${({ theme }) => theme.media.mobile} {
        padding-left: 10px;
      }
    }
  }
  &:first-child {
    font-size: ${({ theme }) => theme.fonts.size.base};
    font-weight: ${({ theme }) => theme.fonts.weight.medium};
    color: ${({ theme }) => theme.colors.gray4};
    background: ${({ theme }) => theme.colors.gray7};
    border-bottom: none;
    padding: 15px 0;
  }
`;

const IdeaImage = styled.img`
  ${({ theme }) => theme.media.mobile} {
    width: 30px;
    height: 30px;
  }
  width: 39px;
  height: 39px;
  border-radius: 8px;
  cursor: pointer;
`;

const Svg = styled.svg`
  margin-left: 10px;
`;

const Page = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

const Div = styled.div`
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.colors.main};
  }
`;

const NFTDiv = styled.div`
  width: 884px;
  margin-top: 80px;
`;

interface NFTInfoProps {
  debate_tokenId: number;
  debate_txHash: string;
  idea_id: string;
  idea_title: string;
  idea_thumbnail: string;
  author_id: string;
  i_id: string;
  finalTokenId: number;
}

function NFT(): JSX.Element {
  const [cookies] = useCookies(['TOKEN', 'USER-INFORMATION']);
  const [boardsCount, setBoardsCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [NFTInfo, setNFTInfo] = useState<NFTInfoProps[]>([]);
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const location = useLocation();

  const getList = useCallback(
    async (page: Number) => {
      const { data } = await axios({
        method: 'get',
        url: `/user/mypage/nft?page=${page}`,
        headers: {
          Authorization: `Bearer ${cookies['TOKEN']}`,
        },
      });

      if (data.success) {
        setNFTInfo(data.data.data);
        setBoardsCount(data.data.count);
      }
    },
    [cookies]
  );

  useEffect(() => {
    let page = Number(searchParams.get('page'));
    setPage(Number(searchParams.get('page')));
    getList(page);
  }, [searchParams, getList]);

  useEffect(() => {
    navigate(`/mypage/nft?id=${cookies['USER-INFORMATION']?.id}&page=1`);
  }, [navigate]);

  return (
    <Wrapper>
      <NFTDiv>
        <CategoryTitle>My NFT 정보</CategoryTitle>
        <CategoryExplanation>
          내가 소유하고 있는 NFT의 정보를 확인할 수 있습니다.
        </CategoryExplanation>
        <Container>
          {boardsCount > 0 ? (
            <BoardContainer>
              <BoardHead>
                <Row idx={-1}>
                  <div>Token ID</div>
                  <div>Idea</div>
                  <div></div>
                  <div>Txn Hash</div>
                  <div>
                    Final
                    <br />
                    Minting
                  </div>
                </Row>
              </BoardHead>
              {NFTInfo.map((item, idx) => (
                <Row key={idx} idx={idx}>
                  <div>#{item.debate_tokenId}</div>
                  <div>
                    <IdeaImage
                      src={item.idea_thumbnail}
                      onClick={() =>
                        navigate(`/forum/idea?id=${item.debate_tokenId}`)
                      }
                    />
                  </div>
                  <Div
                    onClick={() =>
                      navigate(`/forum/idea?id=${item.debate_tokenId}`)
                    }
                  >
                    {item.idea_title}
                  </Div>
                  <div>
                    <span>{item.debate_txHash}</span>
                    <span>
                      <Svg
                        width="18"
                        height="18"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() =>
                          window.open(
                            `https://goerli.etherscan.io/tx/${item.debate_txHash}`,
                            '_blank'
                          )
                        }
                      >
                        <g clipPath="url(#clip0_1169_816)">
                          <path
                            d="M8.33333 2.5V4.16667H4.16667V15.8333H15.8333V11.6667H17.5V16.6667C17.5 16.8877 17.4122 17.0996 17.2559 17.2559C17.0996 17.4122 16.8877 17.5 16.6667 17.5H3.33333C3.11232 17.5 2.90036 17.4122 2.74408 17.2559C2.5878 17.0996 2.5 16.8877 2.5 16.6667V3.33333C2.5 3.11232 2.5878 2.90036 2.74408 2.74408C2.90036 2.5878 3.11232 2.5 3.33333 2.5H8.33333ZM14.655 4.16667H10.8333V2.5H17.5V9.16667H15.8333V5.345L10 11.1783L8.82167 10L14.655 4.16667Z"
                            fill="black"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1169_816">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </Svg>
                    </span>
                  </div>
                  <div>{item.finalTokenId ? 'O' : 'X'}</div>
                </Row>
              ))}
            </BoardContainer>
          ) : (
            <NoItem description={'존재하는 NFT가 없습니다.'} />
          )}
          <Page>
            <PageController
              postCount={boardsCount}
              currentPage={page}
              uri={location.pathname + location.search.split('&')[0]}
              pagePostCount={9}
            />
          </Page>
        </Container>
      </NFTDiv>
    </Wrapper>
  );
}

export default NFT;
