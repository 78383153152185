import styled from 'styled-components';
// import { Card } from 'antd';
import { IdeaListProps } from 'components/post/IdeaProps';
import { LabelItems } from 'components/sign-up/CategoryItems';

const Idea = styled.div`
  width: 241px;
  height: 398px;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  box-shadow: 5px 3px 11px rgba(17, 38, 146, 0.08);
  border-radius: 10px;
`;
const CardHeader = styled.div`
  display: flex;
  height: 38px;
`;

const Category = styled.div`
  color: ${({ theme }) => theme.colors.gray6};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  font-size: 16px;
`;

const Writer = styled.div`
  color: ${({ theme }) => theme.colors.gray3};
  font-size: ${({ theme }) => theme.fonts.size.xs};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
`;

const Thumbnail = styled.img`
  width: 225px;
  height: 244px;
  object-fit: cover;
  border-radius: 15px;
`;

const Profile = styled.img`
  width: 22px;
  height: 22px;
  margin-right: 4px;
  object-fit: cover;
  border-radius: 30px;
  border: 0.5px solid ${({ theme }) => theme.colors.gray2};
`;

const Info = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 4px;
`;

const SummaryContainer = styled.div`
  justify-content: space-between;
  display: flex;
  width: 225px;
`;

const Summary = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${({ theme }) => theme.colors.gray5};
  /* font-size: ${({ theme }) => theme.fonts.size.xxs}; */
  font-size: 12px;
  font-weight: ${({ theme }) => theme.fonts.weight.regular};

  span {
    margin: 2px 20px 0 0;
    color: ${({ theme }) => theme.colors.gray3};
    font-size: ${({ theme }) => theme.fonts.size.xs};
  }
`;

const Svg = styled.svg`
  margin-right: 5px;
  width: 16px;
  height: 16px;
`;

const Title = styled.div`
  margin-top: 12px;
  height: 48px;
  color: ${({ theme }) => theme.colors.gray6};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
`;

const CardDiv = styled.div``;

function Cards({
  id,
  post,
}: {
  id: number | undefined;
  post: IdeaListProps;
}): JSX.Element {
  return (
    <Idea key={id}>
      <CardDiv>
        <CardHeader>
          <Profile
            src={
              post?.author_profile?.length > 0
                ? process.env.REACT_APP_S3_BUCKET_URL + post.author_profile
                : require('@assets/clicked-general-member.png')
            }
          />
          <Info>
            <Category>
              {LabelItems.slice(1)[post.interest_id - 1].title}
            </Category>
            <Writer>{post.author_nickname}</Writer>
          </Info>
        </CardHeader>
        <Thumbnail src={post?.thumbnail || 'error'} />
        <Title>
          {post?.title.length > 14
            ? `${post?.title.slice(0, 23)}...`
            : post.title}
        </Title>
        <SummaryContainer>
          <Summary>
            <Svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_744_1401)">
                <path
                  d="M10.8333 3.25H15.1666C17.4652 3.25 19.6696 4.16309 21.2949 5.78841C22.9202 7.41372 23.8333 9.61812 23.8333 11.9167C23.8333 14.2152 22.9202 16.4196 21.2949 18.0449C19.6696 19.6702 17.4652 20.5833 15.1666 20.5833V24.375C9.74996 22.2083 2.16663 18.9583 2.16663 11.9167C2.16663 9.61812 3.07972 7.41372 4.70503 5.78841C6.33035 4.16309 8.53475 3.25 10.8333 3.25ZM13 18.4167H15.1666C16.0202 18.4167 16.8655 18.2485 17.6541 17.9219C18.4427 17.5952 19.1592 17.1164 19.7628 16.5129C20.3664 15.9093 20.8452 15.1927 21.1718 14.4041C21.4985 13.6155 21.6666 12.7703 21.6666 11.9167C21.6666 11.0631 21.4985 10.2178 21.1718 9.42922C20.8452 8.64061 20.3664 7.92405 19.7628 7.32047C19.1592 6.71689 18.4427 6.23811 17.6541 5.91145C16.8655 5.58479 16.0202 5.41667 15.1666 5.41667H10.8333C9.10939 5.41667 7.45608 6.10149 6.2371 7.32047C5.01811 8.53946 4.33329 10.1928 4.33329 11.9167C4.33329 15.8275 7.00046 18.3798 13 21.1033V18.4167Z"
                  fill="#838383"
                />
              </g>
              <defs>
                <clipPath id="clip0_744_1401">
                  <rect width="26" height="26" fill="white" />
                </clipPath>
              </defs>
            </Svg>
            <span>{post.proposal_count}</span>
          </Summary>
          <Summary>개설일: {post.createdAt.substring(0, 10)}</Summary>
        </SummaryContainer>
      </CardDiv>
    </Idea>
  );
}

export default Cards;
