import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 1000px;
  height: 100vh;
  flex-direction: column;
`;

const Logo = styled.div`
  align-item: center;
  justify-content: center;
  display: flex;
`;

const Description = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.md};
  margin: 10px;
`;

function NoItem({ description }: { description: string }): JSX.Element {
  return (
    <Container>
      <Logo>
        <img
          src={require("assets/modal-logo.png")}
          alt="modal logo"
          height={150}
        />
      </Logo>
      <Description>{description}</Description>
    </Container>
  );
}

export default NoItem;
