import React from "react";
import styled from "styled-components";
import axios from "axios";

import { useState, useEffect, useCallback } from "react";
import { useCookies } from "react-cookie";
import Contents from "../home/Contents";

const OperatePage = () => {
  const [EditCount, setEditCount] = useState();
  const [EditIsRateType, setEditIsRateType] = useState("");
  console.log("editType", EditIsRateType);

  useEffect(() => {
    setEditAlgorithm({
      ...editAlgorithm,
      count: EditCount,
      isRateType: EditIsRateType,
    });
  }, [EditCount, EditIsRateType]);

  const [editAlgorithm, setEditAlgorithm] = useState({
    count: EditCount,
    isRateType: EditIsRateType,
  });

  //토큰, 개인정보
  const [cookies, setCookie] = useCookies(["TOKEN", "USER-INFORMATION"]);
  const userId = cookies["USER-INFORMATION"].id;
  const [algorithm, setAlgorithm] = useState({ count: 0, isRateType: true });

  useEffect(() => {
    axios
      .get(`/admin/algorithm`)
      .then((res) => {
        setAlgorithm(res.data.data);
        setEditIsRateType(res.data.data.isRateType ? "RATE" : "INTEGER");
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [userPassword, setUserPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  const [passwordMessage, setPasswordMessage] = useState<string>("");

  //유효성 검사
  const [isPassword, setIsPassword] = useState<boolean>(false);
  const [isPasswordConfirm, setIsPasswordConfirm] = useState<boolean>(false);
  const [passwordConfirmMessage, setPasswordConfirmMessage] =
    useState<string>("");

  //비밀번호
  const onChangePassword = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const passwordRegex =
        /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
      const passwordCurrent = e.target.value;
      setUserPassword(passwordCurrent);

      if (!passwordRegex.test(passwordCurrent)) {
        setPasswordMessage(
          "숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요!"
        );
        setIsPassword(false);
      } else {
        setPasswordMessage("");
        setIsPassword(true);
      }
    },
    []
  );

  // 비밀번호 확인
  const onChangePasswordConfirm = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const passwordConfirmCurrent = e.target.value;
      setPasswordConfirm(passwordConfirmCurrent);

      if (userPassword === passwordConfirmCurrent) {
        setPasswordConfirmMessage("비밀번호가 일치합니다.");
        setIsPasswordConfirm(true);
      } else {
        setPasswordConfirmMessage("비밀번호가 일치하지 않습니다.");
        setIsPasswordConfirm(false);
      }
    },
    [userPassword]
  );

  const passwordChange = () => {
    if (userPassword === "" || passwordConfirm === "") {
      alert("비밀번호를 입력해주세요.");
      return false;
    }
    if (userPassword) {
      axios
        .patch(
          `/user/${userId}`,
          {
            password: userPassword,
          },
          {
            headers: {
              Authorization: `Bearer ${cookies["TOKEN"]}`,
            },
          }
        )
        .then(function (res) {
          console.log(res);
          alert("비밀번호를 변경하였습니다.");
        })
        .catch((err) => console.log("에러"));
    }
  };

  const algorithmChange = () => {
    axios
      .post(
        `/admin/algorithm`,
        {
          count: EditCount,
          type: EditIsRateType,
        },
        {
          headers: {
            Authorization: `Bearer ${cookies["TOKEN"]}`,
          },
        }
      )
      .then(function (res) {
        console.log(res);
        alert("변경 완료 되었습니다.");
      })
      .catch((err) => console.log("에러"));
  };

  const algorithmIsRateType = () => {
    if (algorithm.isRateType === false) {
      return "고정 추천 수";
    }
    if (algorithm.isRateType === true) {
      return "전체 이용자 수 대비";
    }
  };

  return (
    <Wrapper>
      <Container>
        <CategoryTitle>관리자</CategoryTitle>
        <CategoryDetailTitle>사이트 운영/관리</CategoryDetailTitle>
        <Content>
          <Header>
            <Title>웹사이트 운영 관리 항목</Title>
          </Header>

          <Management>
            <SubTitle>웹 사이트 운영 관리 항목</SubTitle>
            <Select onChange={(e: any) => setEditIsRateType(e.target.value)}>
              {/* <option
                selected
                hidden
                style={{ fontSize: "5px", marginLeft: "10px" }}
              >
                {algorithmIsRateType()}
              </option> */}
              {algorithm.isRateType ? (
                <>
                  <option value="RATE" selected>
                    전체 이용자 수 대비
                  </option>
                  <option value="INTEGER">고정 추천 수</option>
                </>
              ) : (
                <>
                  <option value="RATE">전체 이용자 수 대비</option>
                  <option value="INTEGER" selected>
                    고정 추천 수
                  </option>
                </>
              )}
              {/* <option value="RATE">전체 이용자 수 대비</option>
              <option value="INTEGER">고정 추천 수</option> */}
            </Select>
            <InputDiv>
              <Input
                type="number"
                placeholder={`${algorithm.count}`}
                onChange={(e: any) => setEditCount(e.target.value)}
              />
              <span>
                전체 이용자 수 대비 X% 입력 (예:70, 1-100 사이의 정수 입력 가능)
              </span>
            </InputDiv>
            <Button onClick={algorithmChange}>변경</Button>
          </Management>
          <Password>
            <SubTitle>관리자 비밀번호 변경</SubTitle>
            <PasswordContent>
              <PasswordInput>
                <Input
                  placeholder="비밀번호를 입력해주세요."
                  onChange={onChangePassword}
                  type="password"
                />
                <span className={`message ${isPassword ? "success" : "error"}`}>
                  {passwordMessage}
                </span>
                <Input
                  placeholder="비밀번호를 한번 더 입력해주세요."
                  onChange={onChangePasswordConfirm}
                  type="password"
                />
                {passwordConfirm.length > 0 && (
                  <span
                    className={`message ${
                      isPasswordConfirm ? "success" : "error"
                    }`}
                  >
                    {passwordConfirmMessage}
                  </span>
                )}
              </PasswordInput>
              <Button onClick={passwordChange}>변경</Button>
            </PasswordContent>
          </Password>
        </Content>
      </Container>
    </Wrapper>
  );
};

const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-size: 12px;
    color: #838383;
    margin-left: 16px;
    margin-top: 7px;
  }
`;

const Message = styled.span<{ success: boolean }>`
  margin-left: 10px;
  font-size: ${({ theme }) => theme.fonts.size.xxs};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  color: ${({ success, theme }) =>
    success ? theme.colors.blue2 : theme.colors.error};
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-itmes: center;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px;
  max-width: 1290px;
  width: 100%;
`;

const CategoryTitle = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.md};
  color: ${({ theme }) => theme.colors.gray4};
  margin-bottom: 10px;
`;

const CategoryDetailTitle = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.xl};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  color: ${({ theme }) => theme.colors.gray6};
  margin-bottom: 40px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 904px;
  height: 556px;
  background-color: white;
  border-radius: 18px;
  padding: 0px 16px;
`;

const Management = styled.div`
  display: flex;
  height: 50px;
  border-bottom: 1px solid #e1edff;
  padding: 8px 16px;
  align-items: center;
  gap: 20px;
`;

const Title = styled.div`
  height: 44px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
`;

const SubTitle = styled.div`
  width: 200px;
`;

const Password = styled.div`
  height: 130px;
  border-bottom: 1px solid #e1edff;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  display: flex;
  /* gap: 15px; */
  justify-content: space-between;
`;

const Select = styled.select`
  all: unset;
  width: 181px;
  height: 25px;
  padding: 0 10px;
  background-color: #fff;
  border: 1px solid #ccdcee;
  border-radius: 8px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  display: block;
  align-items: center;
  justify-content: flex-start;
  &:focus {
    border-color: ${({ theme }) => theme.colors.main};
  }
  z-index: 3;
  cursor: pointer;
`;

const Input = styled.input<any>`
  width: 331px;
  height: 19px;
  padding: 4px 16px;
  border: 1px solid #ccdcee;
  border-radius: 8px;
`;
const PasswordInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  span {
    font-size: 12px;
    color: #838383;
    margin-left: 16px;
  }
`;
const Header = styled.div`
  display: flex;
  border-bottom: 1px solid #e1edff;
  padding: 8px 16px;
  height: 44px;
  align-items: center;
`;

const Button = styled.div`
  width: 61px;
  height: 28px;
  background-color: #1f56f1;
  color: #fff;
  font-size: 12px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const PasswordContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 660px;
`;

export default OperatePage;
