import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 1000px;
  height: 100vh;
  flex-direction: column;
`;

const Logo = styled.div`
  align-item: center;
  justify-content: center;
  display: flex;
`;

const Description = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.md};
  margin: 10px;
`;

const Button = styled.button`
  width: 100px;
  height: 45px;
  cursor: pointer;
  border-radius: 10px;
  margin: 20px 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  background-color: ${({ theme }) => theme.colors.main};
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
  font-size: ${({ theme }) => theme.fonts.size.sm};
`;

function NoAuth({
  isAccessible,
  setIsAccess,
}: {
  isAccessible: boolean;
  setIsAccess: (value: boolean) => void;
}): JSX.Element {
  const [searchParams] = useSearchParams();
  const [cookies] = useCookies(["TOKEN", "USER-INFORMATION"]);

  const applyParticipation = async () => {
    try {
      const { data } = await axios({
        method: "get",
        url: `/debate-access/addByTokenId`,
        params: {
          userId: cookies["USER-INFORMATION"].id,
          tokenId: searchParams.get("id"),
        },
      });
      if (data.success) {
        setIsAccess(true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Container>
      <Logo>
        <img
          src={require("assets/modal-logo.png")}
          alt="modal logo"
          height={150}
        />
      </Logo>
      <Description>접근 권한이 없습니다.</Description>
      {isAccessible && <Button onClick={applyParticipation}>참여 신청</Button>}
    </Container>
  );
}

export default NoAuth;
