import styled from "styled-components";

export const Container = styled.div<{ width: string }>`
  display: flex;
  justify-content: space-between;
  position: relative;

  :before {
    content: "";
    position: absolute;
    background: ${({ theme }) => theme.colors.blue5};
    height: 4px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  :after {
    content: "";
    position: absolute;
    background: ${({ theme }) => theme.colors.main};
    height: 4px;
    width: ${({ width }) => width};
    top: 50%;
    transition: 0.4s ease;
    transform: translateY(-50%);
    left: 0;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  z-index: 1;
`;

export const StepStyle = styled.div<{ step: string }>`
  width: 35px;
  height: 35px;
  ${({ theme }) => theme.media.mobile} {
    width: 30px;
    height: 30px;
  }
  border-radius: 100%;
  background-color: ${({ step }) =>
    step === "completed"
      ? ({ theme }) => theme.colors.main
      : ({ theme }) => theme.colors.blue5};
  transition: 0.4s ease;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StepCount = styled.span`
  font-size: ${({ theme }) => theme.fonts.size.md};
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.media.mobile} {
    font-size: ${({ theme }) => theme.fonts.size.base};
  }
`;

export const StepsLabelContainer = styled.div`
  position: absolute;
  top: 55px;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const StepLabel = styled.span<{ step: string }>`
  white-space: nowrap;
  font-size: ${({ theme }) => theme.fonts.size.base};
  color: ${({ step }) =>
    step === "completed"
      ? ({ theme }) => theme.colors.gray6
      : ({ theme }) => theme.colors.gray2};
  ${({ theme }) => theme.media.mobile} {
    font-size: ${({ theme }) => theme.fonts.size.sm};
  }
`;
