import React, { useState, useRef, useEffect } from 'react';
import { HomeProps } from './HomeProps';
import useIntersectionObsever from 'hooks/UseIntersectionObserver';
import styled from 'styled-components';

const Statistics = ({ countList }: { countList: HomeProps }): JSX.Element => {
  const [scroll, setScroll] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const isInViewport = useIntersectionObsever(ref);

  const start = 0;
  const duration = 2000;
  const [ideaCount, setIdeaCount] = useState<number>(start);
  const [nftCount, setNftCount] = useState<number>(start);
  const [debateCount, setDebateCount] = useState<number>(start);
  const frameRate = 1000 / 60;
  const totalFrame = Math.round(duration / frameRate);

  function easeOutExpo(t: number): number {
    return t === 1 ? 1 : 1 - Math.pow(2, -10 * t);
  }

  useEffect(() => {
    let currentNumber = start;
    const counter = setInterval(() => {
      const progress = easeOutExpo(++currentNumber / totalFrame);
      setIdeaCount(Math.round(countList.ideaCount * progress));
      setNftCount(Math.round(countList.nftCount * progress));
      setDebateCount(Math.round(countList.ongoingDebateCount * progress));

      if (progress === 1) {
        clearInterval(counter);
      }
    }, frameRate);

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll); //clean up
    };
  }, [isInViewport, scroll]);

  const handleScroll = () => {
    if (isInViewport) {
      setScroll(true);
      return;
    }
  };

  return (
    <Container>
      <StatisticsDiv ref={ref}>
        <Count>{ideaCount}+</Count>
        <Title>발급된 아이디어 NFT</Title>
      </StatisticsDiv>
      <StatisticsDiv>
        <Count>{nftCount}+</Count>
        <Title>아이디어 NFT의 Total Balance</Title>
      </StatisticsDiv>
      <StatisticsDiv>
        <Count>{debateCount}+</Count>
        <Title>현재 토론 진행 중인 아이디어</Title>
      </StatisticsDiv>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 448px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #3577fc;
  gap: 40px;
`;

const StatisticsDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const Count = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: #fff;
`;

const Title = styled.div`
  font-size: 16px;
  color: #fff;
`;

export default Statistics;
