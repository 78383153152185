export const unCheckedIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" fill="white" />
    <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#ADB5BD" />
  </svg>
);

export const checkedIcon = () => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_589_1611)">
      <rect x="4" y="2" width="16" height="16" rx="2" fill="white" />
      <g filter="url(#filter1_d_589_1611)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.51662 10.5979C5.82779 9.90067 5.82779 8.77019 6.51662 8.07293C7.20545 7.37567 8.32225 7.37567 9.01108 8.07293L11.2561 10.3454C11.3939 10.4849 11.6172 10.4849 11.755 10.3454L18.9889 3.02294C19.6777 2.32569 20.7946 2.32569 21.4834 3.02294C22.1722 3.7202 22.1722 4.85068 21.4834 5.54794L11.755 15.3954C11.6172 15.5349 11.3939 15.5349 11.2561 15.3954L6.51662 10.5979Z"
          fill="#3A57E8"
        />
      </g>
      <rect x="4.5" y="2.5" width="15" height="15" rx="1.5" stroke="#3A57E8" />
    </g>
    <defs>
      <filter
        id="filter0_d_589_1611"
        x="0"
        y="0"
        width="26"
        height="24"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.231373 0 0 0 0 0.541176 0 0 0 0 1 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_589_1611"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_589_1611"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_589_1611"
        x="0"
        y="0"
        width="26"
        height="24"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.215686 0 0 0 0 0.490196 0 0 0 0 1 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_589_1611"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_589_1611"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
