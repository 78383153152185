import styled from 'styled-components';
import { MenuItems } from './MenuItems';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Desktop, Mobile, Tablet } from 'hooks/MediaQuery';
import MobileBar from './MobileBar';

const MenuContainer = styled.div`
  display: flex;
  width: 84px;

  flex-direction: column;
  align-items: center;
  margin-top: 50px;
`;

const NavSection = styled.div`
  width: 38px;
  height: 224px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-top: 92px;
`;

const PathTitle = styled.div<{ isHover: number; isClicked: boolean }>`
  font-size: ${({ theme }) => theme.fonts.size.base};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  color: ${({ theme, isHover, isClicked }) =>
    isClicked
      ? theme.colors.white
      : isHover === 1
      ? theme.colors.gray4
      : theme.colors.gray6};
  background: ${({ theme, isHover, isClicked }) =>
    isClicked ? theme.colors.main : isHover === 1 ? '' : theme.colors.blue5};
  height: 50px;
  width: 200px;
  display: flex;
  align-items: center;
  box-shadow: 5px 5px 10px -3px rgba(0, 0, 0, 0.1);
  border-radius: 27px;
`;

const MainPath = styled(Link)`
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  box-shadow: 5px 3px 11px 0px rgba(17, 38, 146, 0.08);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.img<{ isClicked: boolean }>`
  width: 24px;
  height: 24px;
`;

const UserInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px;
`;

const Profile = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 40px;
  border: 10px solid ${({ theme }) => theme.colors.main};
  background: ${({ theme }) => theme.colors.white};
`;

const Nickname = styled.div`
  margin: 10px;
  font-size: 12px;
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  color: ${({ theme }) => theme.colors.gray6};
`;

const UserType = styled.div`
  font-size: 9px;
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  color: ${({ theme }) => theme.colors.gray4};
`;

function MyPageBar2(): JSX.Element {
  const [isChecked, setIsChecked] = useState<number>(0);

  const activeButton = (idx: number) => {
    if (idx === isChecked) {
      return '#1F56F1';
    } else {
      return '#fff';
    }
  };

  const activeButtonHandler = (idx: number) => {
    setIsChecked(idx);
    // setIsDisabled(true);
  };

  const [isClicked, setIsClicked] = useState([
    {
      id: 0,
      state: 1,
    },
    {
      id: 1,
      state: 1,
    },
    {
      id: 2,
      state: 1,
    },
    {
      id: 3,
      state: 1,
    },
  ]);
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState<string>(
    location.pathname.split('/')[2]
  );
  const [cookies] = useCookies(['USER-INFORMATION']);

  const icons = [
    {
      unclicked: require('@assets/unclicked-idea.png'),
      clicked: require('@assets/clicked-idea.png'),
      hover: require('@assets/hover-idea.png'),
    },
    {
      unclicked: require('@assets/unclicked-vote.png'),
      clicked: require('@assets/clicked-vote.png'),
      hover: require('@assets/hover-vote.png'),
    },
    {
      unclicked: require('@assets/unclicked-nft.png'),
      clicked: require('@assets/clicked-nft.png'),
      hover: require('@assets/hover-nft.png'),
    },
    {
      unclicked: require('@assets/unclicked-user.png'),
      clicked: require('@assets/clicked-user.png'),
      hover: require('@assets/hover-user.png'),
    },
  ];

  const handleClick = (id: number, state: number) => {
    let copiedItems = [...isClicked];
    copiedItems[id].state = state;
    setIsClicked(copiedItems);
  };

  useEffect(() => {
    setCurrentPage(location.pathname.split('/')[2]);
  }, [location]);

  function ImgFile() {
    if (cookies['USER-INFORMATION'] === 'true') {
      if (cookies['USER-INFORMATION']?.profile != null) {
        return (
          process.env.REACT_APP_S3_BUCKET_URL +
          cookies['USER-INFORMATION']?.profile
        );
      }
      if (cookies['USER-INFORMATION']?.profile === null) {
        return require('@assets/clicked-general-member.png');
      }
    }
    if (cookies['USER-INFORMATION'] !== 'true') {
      return require('@assets/clicked-general-member.png');
    }
  }

  return (
    <>
      <Desktop>
        <MenuContainer>
          <UserInformation>
            <Profile src={ImgFile()} />
            <Nickname>{cookies['USER-INFORMATION'].nickname}</Nickname>
            <UserType>
              {cookies['USER-INFORMATION'].isExpert
                ? '전문가 회원'
                : '일반 회원'}
            </UserType>
          </UserInformation>

          <NavSection>
            {MenuItems.map((item, index) => (
              <MainPath
                onClick={() => setIsChecked(index)}
                key={index}
                color={activeButton(index)}
                to={item.path + `?id=${cookies['USER-INFORMATION'].id}&page=1`}
              >
                <Icon
                  src={
                    currentPage === item.title.toLowerCase()
                      ? icons[index].clicked
                      : isClicked[index].state === 1
                      ? icons[index].unclicked
                      : icons[index].hover
                  }
                  isClicked={currentPage === item.title.toLowerCase()}
                />
              </MainPath>
            ))}
          </NavSection>
        </MenuContainer>
      </Desktop>
      <Tablet>
        <MenuContainer>
          <UserInformation>
            <Profile
              src={
                cookies['USER-INFORMATION']
                  ? process.env.REACT_APP_S3_BUCKET_URL +
                    cookies['USER-INFORMATION'].profile
                  : require('@assets/clicked-general-member.png')
              }
            />
            <Nickname>{cookies['USER-INFORMATION'].nickname}</Nickname>
            <UserType>
              {cookies['USER-INFORMATION'].isExpert
                ? '전문가 회원'
                : '일반 회원'}
            </UserType>
          </UserInformation>

          <NavSection>
            {MenuItems.map((item, index) => (
              <MainPath
                key={index}
                to={item.path + `?id=${cookies['USER-INFORMATION'].id}&page=1`}
                onMouseOver={() => handleClick(index, 3)}
                onMouseOut={() => handleClick(index, 1)}
              >
                <PathTitle
                  isHover={isClicked[index].state}
                  isClicked={currentPage === item.title.toLowerCase()}
                  onClick={() => {
                    setCurrentPage(item.title.toLowerCase());
                  }}
                >
                  <Icon
                    src={
                      currentPage === item.title.toLowerCase()
                        ? icons[index].clicked
                        : isClicked[index].state === 1
                        ? icons[index].unclicked
                        : icons[index].hover
                    }
                    isClicked={currentPage === item.title.toLowerCase()}
                  />
                  <div>{item.title}</div>
                </PathTitle>
              </MainPath>
            ))}
          </NavSection>
        </MenuContainer>
      </Tablet>
      <Mobile>
        <MobileBar />
      </Mobile>
    </>
  );
}

export default MyPageBar2;
