import Navigation from '@components/global/header/Header';
import { Outlet } from 'react-router-dom';

function UserLayout(): JSX.Element {
  return (
    <>
      <Navigation page={'user'} />
      <Outlet />
    </>
  );
}

export default UserLayout;
