import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import { useCookies } from 'react-cookie';

import { DebateProps, PostProps } from '../../post/IdeaProps';
import Voting from './Voting';
import { Viewer } from '@toast-ui/react-editor';
import FileBox from 'components/forum/idea/FileBox';
import { LabelItems } from 'components/sign-up/CategoryItems';
import AdoptedIdea from './AdoptedIdea';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  ${({ theme }) => theme.media.mobile} {
    width: 90%;
  }
  margin-top: 90px;
  padding-top: 30px;
  padding: 20px;
  width: 650px;
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 10px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray1};
`;

const Category = styled.div`
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.gray4};
  font-size: ${({ theme }) => theme.fonts.size.base};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
`;

const Title = styled.div`
  margin: 10px 0;
  color: ${({ theme }) => theme.colors.gray6};
  font-size: ${({ theme }) => theme.fonts.size.xl};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
`;

const Writer = styled.div`
  margin: 10px 0;
  color: ${({ theme }) => theme.colors.main};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
`;

const SummaryContainer = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
  flex-direction: row;
`;

const Svg = styled.svg`
  margin-right: 5px;
  width: 16px;
  height: 16px;
`;

const Summary = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin: 2px 20px 0 0;
    color: ${({ theme }) => theme.colors.gray3};
    font-size: ${({ theme }) => theme.fonts.size.xs};
    font-weight: ${({ theme }) => theme.fonts.weight.medium};
  }
  &:last-child {
    margin-left: auto;
  }
  position: relative;
`;

const Ul = styled.div`
  position: absolute;
  top: 25px;
  right: 15px;
  width: 60px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  -webkit-animation: swing-in-top-fwd 0.5s
    cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  @-webkit-keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
      transform: rotateX(-100deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 1;
    }
  }
  @keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
      transform: rotateX(-100deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 1;
    }
  }
  div {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`;

const Li = styled.li`
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s ease-in-out;
  :hover {
    background-color: ${({ theme }) => theme.colors.gray1};
  }
  cursor: pointer;
`;

const Menu = styled.div`
  color: ${({ theme }) => theme.colors.gray6};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const VotingContainer = styled.div`
  display: flex;
  margin: 20px;
  align-items: center;
  justify-content: center;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  padding: 20px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray1};
  justify-content: space-between;
`;

const FootElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin: 2px 20px 0 0;
    color: ${({ theme }) => theme.colors.gray6};
    font-size: ${({ theme }) => theme.fonts.size.sm};
    font-weight: ${({ theme }) => theme.fonts.weight.medium};
  }
`;

const FileContainer = styled.div`
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 20px auto;
`;

const BackButton = styled.div`
  width: 80px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.gray1};
  color: ${({ theme }) => theme.colors.gray5};
  font-size: ${({ theme }) => theme.fonts.size.xs};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  cursor: pointer;
`;

function Post(): JSX.Element {
  const [searchParams] = useSearchParams();
  const [postId, setPostId] = useState<string>('');
  const [post, setPost] = useState<PostProps>();
  const [guage, setGuage] = useState<number>(0);
  const [dueDay, setDueDay] = useState<number>(0);
  const [cookies] = useCookies(['TOKEN', 'USER-INFORMATION']);
  const [completed, setCompleted] = useState<boolean>(false);
  const [debate, setDebate] = useState<DebateProps>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [more, setMore] = useState<boolean>(false);
  const [users, setUsers] = useState<number>(0);
  const navigate = useNavigate();
  const moreList = ['삭제'];
  const moreRef = useRef<HTMLDivElement>(null);

  const getCurrentPost = useCallback(
    async (postId: string) => {
      try {
        const { data } = await axios({
          method: 'get',
          url: `/idea/id/${postId}`,
        });
        setPost(data.data);
        setGuage(data.data.votes.length);
        if (data.data.state === 1) {
          setDueDay(data.data.dueDate[0] + 1);
        }
        checkVoting(postId);
        setDebate(data.data.debate);
        if (data.data.author.id === cookies['USER-INFORMATION'].id) {
          setIsEdit(true);
        }
      } catch {
        navigate(-1);
      }
    },
    [cookies, navigate]
  );

  const checkVoting = async (postId: string) => {
    try {
      const { data } = await axios({
        method: 'get',
        url: `/vote/${postId}`,
      });
      const res = data.data;
      setUsers(res.userCount);

      if (res.voters.includes(cookies['USER-INFORMATION'].id)) {
        setCompleted(true);
      }
    } catch {
      navigate(-1);
    }
  };

  const handleView = useCallback(
    async (postId: string) => {
      await axios({
        method: 'patch',
        url: `/idea/viewcount/${postId}`,
        headers: {
          Authorization: `Bearer ${cookies['TOKEN']}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
    },
    [cookies]
  );

  const handleMenu = async (menu: string) => {
    setMore(!more);
    if (menu === '삭제') {
      if (window.confirm('삭제하시겠습니까?')) {
        try {
          const { data } = await axios({
            method: 'delete',
            url: `/idea/${postId}`,
            headers: {
              Authorization: `Bearer ${cookies['TOKEN']}`,
            },
          });
          if (data.success) {
            navigate('/community?page=1');
          }
        } catch {
          navigate(-1);
        }
      }
    }
  };

  useEffect(() => {
    const clickOutside = (e: any) => {
      if (more && !moreRef.current?.contains(e.target)) {
        setMore(false);
      }
    };
    document.addEventListener('mousedown', clickOutside);
    return () => {
      document.removeEventListener('mousedown', clickOutside);
    };
  }, [more]);

  useEffect(() => {
    const postId = String(searchParams.get('id'));
    setPostId(postId);
    getCurrentPost(postId);
    handleView(postId);
  }, [searchParams, handleView, getCurrentPost]);

  useEffect(() => {
    if (postId) {
      getCurrentPost(postId);
    }
  }, [completed]);

  return (
    <Wrapper>
      <Container>
        <Header>
          <Category>
            {post?.interestField.id !== undefined
              ? LabelItems.slice(1)[post?.interestField.id - 1].title
              : ''}
          </Category>
          <Title>{post?.title}</Title>
          <Writer>{post?.author.nickname}</Writer>
          <SummaryContainer>
            <Summary>
              <Svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_867_392)">
                  <path
                    d="M7.99998 14.6663C4.31798 14.6663 1.33331 11.6817 1.33331 7.99967C1.33331 4.31767 4.31798 1.33301 7.99998 1.33301C11.682 1.33301 14.6666 4.31767 14.6666 7.99967C14.6666 11.6817 11.682 14.6663 7.99998 14.6663ZM7.99998 13.333C9.41447 13.333 10.771 12.7711 11.7712 11.7709C12.7714 10.7707 13.3333 9.41416 13.3333 7.99967C13.3333 6.58519 12.7714 5.22863 11.7712 4.22844C10.771 3.22824 9.41447 2.66634 7.99998 2.66634C6.58549 2.66634 5.22894 3.22824 4.22874 4.22844C3.22855 5.22863 2.66665 6.58519 2.66665 7.99967C2.66665 9.41416 3.22855 10.7707 4.22874 11.7709C5.22894 12.7711 6.58549 13.333 7.99998 13.333ZM8.66665 7.99967H11.3333V9.33301H7.33331V4.66634H8.66665V7.99967Z"
                    fill="#838383"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_867_392">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </Svg>
              <span>{post?.createdAt.substring(0, 10)}</span>
            </Summary>
            <Summary>
              <Svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_867_389)">
                  <path
                    d="M8.00002 2C11.5947 2 14.5854 4.58667 15.2127 8C14.586 11.4133 11.5947 14 8.00002 14C4.40535 14 1.41469 11.4133 0.787354 8C1.41402 4.58667 4.40535 2 8.00002 2ZM8.00002 12.6667C9.35967 12.6664 10.6789 12.2045 11.7419 11.3568C12.8049 10.509 13.5486 9.32552 13.8514 8C13.5475 6.67554 12.8033 5.49334 11.7404 4.64668C10.6776 3.80003 9.35889 3.33902 8.00002 3.33902C6.64115 3.33902 5.32248 3.80003 4.2596 4.64668C3.19673 5.49334 2.45253 6.67554 2.14869 8C2.45142 9.32552 3.19514 10.509 4.25812 11.3568C5.3211 12.2045 6.64037 12.6664 8.00002 12.6667ZM8.00002 11C7.20437 11 6.44131 10.6839 5.8787 10.1213C5.31609 9.55871 5.00002 8.79565 5.00002 8C5.00002 7.20435 5.31609 6.44129 5.8787 5.87868C6.44131 5.31607 7.20437 5 8.00002 5C8.79567 5 9.55873 5.31607 10.1213 5.87868C10.6839 6.44129 11 7.20435 11 8C11 8.79565 10.6839 9.55871 10.1213 10.1213C9.55873 10.6839 8.79567 11 8.00002 11ZM8.00002 9.66667C8.44205 9.66667 8.86597 9.49107 9.17853 9.17851C9.49109 8.86595 9.66669 8.44203 9.66669 8C9.66669 7.55797 9.49109 7.13405 9.17853 6.82149C8.86597 6.50893 8.44205 6.33333 8.00002 6.33333C7.55799 6.33333 7.13407 6.50893 6.82151 6.82149C6.50895 7.13405 6.33335 7.55797 6.33335 8C6.33335 8.44203 6.50895 8.86595 6.82151 9.17851C7.13407 9.49107 7.55799 9.66667 8.00002 9.66667Z"
                    fill="#838383"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_867_389">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </Svg>
              <span>{post?.view}</span>
            </Summary>
            <Summary />
            {post?.author.id === cookies['USER-INFORMATION'].id && (
              <Summary>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => {
                    setMore(!more);
                  }}
                  cursor="pointer"
                >
                  <path
                    d="M12 20C11.45 20 10.9793 19.8043 10.588 19.413C10.196 19.021 10 18.55 10 18C10 17.45 10.196 16.979 10.588 16.587C10.9793 16.1957 11.45 16 12 16C12.55 16 13.021 16.1957 13.413 16.587C13.8043 16.979 14 17.45 14 18C14 18.55 13.8043 19.021 13.413 19.413C13.021 19.8043 12.55 20 12 20ZM12 14C11.45 14 10.9793 13.804 10.588 13.412C10.196 13.0207 10 12.55 10 12C10 11.45 10.196 10.979 10.588 10.587C10.9793 10.1957 11.45 10 12 10C12.55 10 13.021 10.1957 13.413 10.587C13.8043 10.979 14 11.45 14 12C14 12.55 13.8043 13.0207 13.413 13.412C13.021 13.804 12.55 14 12 14ZM12 8C11.45 8 10.9793 7.804 10.588 7.412C10.196 7.02067 10 6.55 10 6C10 5.45 10.196 4.97933 10.588 4.588C10.9793 4.196 11.45 4 12 4C12.55 4 13.021 4.196 13.413 4.588C13.8043 4.97933 14 5.45 14 6C14 6.55 13.8043 7.02067 13.413 7.412C13.021 7.804 12.55 8 12 8Z"
                    fill="#838383"
                  />
                </svg>
                {more && (
                  <Ul ref={moreRef}>
                    {moreList.map((item, index) => (
                      <Li key={index}>
                        <Menu onClick={() => handleMenu(item)}>{item}</Menu>
                      </Li>
                    ))}
                  </Ul>
                )}
              </Summary>
            )}
          </SummaryContainer>
        </Header>
        {debate && (
          <AdoptedIdea
            debate={debate}
            ownerProps={String(post?.author.nickname)}
          />
        )}
        {post?.content && <Viewer initialValue={post.content} />}
        <FileContainer>
          {post?.attachments.map((item, id) => {
            return <FileBox key={id} detail={item} />;
          })}
        </FileContainer>
        <VotingContainer>
          <Voting
            count={post ? post.votes.length : 0}
            users={users}
            period={dueDay}
            guage={guage}
            ideaId={String(searchParams.get('id'))}
            completed={completed}
            setCompleted={setCompleted}
          />
        </VotingContainer>
        <Footer>
          <FootElement>
            <Svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_867_406)">
                <path
                  d="M10.95 5.99981H15.75C16.1478 5.99981 16.5294 6.15784 16.8107 6.43915C17.092 6.72045 17.25 7.10198 17.25 7.49981V9.07781C17.2502 9.27383 17.212 9.46799 17.1375 9.64931L14.8162 15.2856C14.7596 15.423 14.6634 15.5405 14.5399 15.6231C14.4164 15.7058 14.2711 15.7499 14.1225 15.7498H1.5C1.30109 15.7498 1.11032 15.6708 0.96967 15.5301C0.829018 15.3895 0.75 15.1987 0.75 14.9998V7.49981C0.75 7.30089 0.829018 7.11013 0.96967 6.96948C1.11032 6.82882 1.30109 6.74981 1.5 6.74981H4.1115C4.23157 6.74984 4.3499 6.72104 4.45653 6.66583C4.56315 6.61062 4.65497 6.53062 4.72425 6.43256L8.814 0.637306C8.8657 0.56404 8.94194 0.511726 9.0289 0.489848C9.11586 0.467971 9.20778 0.477978 9.288 0.518056L10.6485 1.19831C11.0314 1.38968 11.3372 1.70629 11.5153 2.09554C11.6933 2.48479 11.7329 2.92325 11.6273 3.33806L10.95 5.99981ZM5.25 7.94081V14.2498H13.62L15.75 9.07781V7.49981H10.95C10.7215 7.49977 10.4961 7.44756 10.2909 7.34715C10.0857 7.24674 9.90617 7.10078 9.76596 6.92042C9.62575 6.74006 9.52859 6.53005 9.48189 6.30642C9.43519 6.0828 9.44019 5.85146 9.4965 5.63006L10.1737 2.96906C10.1949 2.88605 10.1871 2.79829 10.1515 2.72038C10.1159 2.64246 10.0546 2.57909 9.978 2.54081L9.48225 2.29331L5.94975 7.29731C5.76225 7.56281 5.52225 7.78031 5.25 7.94081ZM3.75 8.24981H2.25V14.2498H3.75V8.24981Z"
                  fill="#141414"
                />
              </g>
              <defs>
                <clipPath id="clip0_867_406">
                  <rect width="18" height="18" fill="white" />
                </clipPath>
              </defs>
            </Svg>
            <span>{post?.votes.length}</span>
          </FootElement>
          <FootElement>
            <BackButton onClick={() => navigate(-1)}>목록으로</BackButton>
          </FootElement>
        </Footer>
      </Container>
    </Wrapper>
  );
}

export default Post;
