import React, { useState, KeyboardEvent, useEffect } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { LoginState } from "atoms/LoginState";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useRecoilState } from "recoil";
import qs from "qs";
import jwt_decode from "jwt-decode";
import { UserInfoProps } from "@components/user/UserProps";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 100px;
  padding: 40px;
  width: 40%;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 15px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 3px 5px 0px ${({ theme }) => theme.colors.gray2};
  ${({ theme }) => theme.media.mobile} {
    margin: 0px;
    padding: 40px;
    width: 60%;
  }
`;

const Title = styled.span`
  color: ${({ theme }) => theme.colors.main};
  font-size: ${({ theme }) => theme.fonts.size.lg};
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
  margin: 30px;
  ${({ theme }) => theme.media.mobile} {
    margin: 0px;
  }
`;

const InnerContainer = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }
`;

const Lable = styled.span`
  color: ${({ theme }) => theme.colors.gray4};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
`;

const InputContainer = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100% - 10px;
  height: 40px;
  padding: 0 0 0 10px;
  margin: 5px 0;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.gray1};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Input = styled.input.attrs({
  required: true,
})`
  all: unset;
  height: 40px;
  transition: width 0.2s ease-in-out;
  color: ${({ theme }) => theme.colors.gray6};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
`;

const Svg = styled.svg`
  margin: 0 10px;
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

const ExtrasContainer = styled.div`
  display: flex;
  width: 70%;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }
`;

const SaveLogin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.gray3};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
`;

const SaveBoxContainer = styled.div`
  margin-right: 5px;
`;

const SearchPassword = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.main};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
`;

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0 0 0;
`;

const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.colors.error};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 40px;
`;

const LoginButton = styled.button`
  all: unset;
  width: 180px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.main};
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fonts.size.base};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  cursor: pointer;
`;

const SignUpButton = styled(Link)`
  width: 180px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  border: 1px solid ${({ theme }) => theme.colors.main};
  color: ${({ theme }) => theme.colors.main};
  font-size: ${({ theme }) => theme.fonts.size.base};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
`;

function Login(): JSX.Element {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [saveLogin, setSaveLogin] = useState<boolean>(false);
  const [loginForm, setLoginForm] = useState({
    userId: "",
    password: "",
  });
  const [loginErrorState, setLoginErrorState] = useState({
    error: false,
    message: "",
  });
  const [, setLoginState] = useRecoilState(LoginState);
  const [, setCookie] = useCookies(["TOKEN", "USER-INFORMATION", "ISADMIN"]);
  const navigate = useNavigate();

  const handleUserIdInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setLoginForm((prev) => ({ ...prev, userId: value.toLowerCase() }));
  };

  const handlePasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setLoginForm((prev) => ({ ...prev, password: value }));
  };

  const handleLogin = async () => {
    let message = "";
    if (loginForm.userId === "") message = "이메일을 입력해주세요.";
    else if (loginForm.password === "") message = "비밀번호를 입력해주세요.";

    const data = {
      userId: loginForm.userId,
      password: loginForm.password,
    };

    const config = {
      method: "post",
      url: "/user/login",
      data: qs.stringify(data),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (response) {
        const { token } = response.data.data;
        getUserInformation(token);

        if (loginForm.userId === "admin") {
          setCookie("ISADMIN", true, {
            expires: new Date(new Date().setDate(new Date().getDate() + 1)),
          });
        } else {
          setCookie("ISADMIN", false, {
            expires: new Date(new Date().setDate(new Date().getDate() + 1)),
          });
        }

        navigate("/");
      })
      .catch(function (error) {
        if (error.response.data.error.statusCode === 400) {
          message = "비밀번호는 8자 ~ 20자 이내로 입력해주세요.";
        } else {
          message = error.response.data.error.message;
        }
        setLoginErrorState({
          error: true,
          message,
        });
      });
  };

  const getUserInformation = async (token: string) => {
    let user_information: UserInfoProps = jwt_decode(token);
    setCookie("TOKEN", token, {
      expires: new Date(new Date().setDate(new Date().getDate() + 1)),
    });

    const { data } = await axios({
      method: "get",
      url: `user/${user_information.id}`,
    });
    setCookie(
      "USER-INFORMATION",
      Object.assign(user_information, { profile: data.data.profile }),
      {
        expires: new Date(new Date().setDate(new Date().getDate() + 1)),
      }
    );

    setLoginState(true);
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  useEffect(() => {
    const localSaveId = localStorage.getItem("saveLogin");
    if (localSaveId) {
      setLoginForm({
        userId: JSON.parse(localSaveId),
        password: "",
      });
      setSaveLogin(true);
    } else {
      setSaveLogin(false);
    }
  }, []);

  useEffect(() => {
    if (saveLogin) {
      localStorage.setItem("saveLogin", JSON.stringify(loginForm.userId));
    } else {
      localStorage.removeItem("saveLogin");
    }
  }, [saveLogin]);

  return (
    <Wrapper>
      <Container>
        <Title>Sign In</Title>
        <InnerContainer>
          <Lable>ID</Lable>
          <InputContainer>
            <Input
              placeholder="Enter your ID"
              value={loginForm.userId}
              onChange={handleUserIdInput}
              onKeyPress={handleKeyPress}
            />
          </InputContainer>
        </InnerContainer>
        <InnerContainer>
          <Lable>Password</Lable>
          <InputContainer>
            <Input
              type={showPassword ? "text" : "password"}
              placeholder="Enter password"
              value={loginForm.password}
              onChange={handlePasswordInput}
              onKeyPress={handleKeyPress}
            />
            {showPassword ? (
              <Svg
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setShowPassword((prev) => !prev)}
              >
                <path
                  d="M0.180908 9C1.12091 3.88 5.60791 0 10.9999 0C16.3919 0 20.8779 3.88 21.8189 9C20.8789 14.12 16.3919 18 10.9999 18C5.60791 18 1.12191 14.12 0.180908 9ZM10.9999 14C12.326 14 13.5978 13.4732 14.5354 12.5355C15.4731 11.5979 15.9999 10.3261 15.9999 9C15.9999 7.67392 15.4731 6.40215 14.5354 5.46447C13.5978 4.52678 12.326 4 10.9999 4C9.67383 4 8.40206 4.52678 7.46437 5.46447C6.52669 6.40215 5.99991 7.67392 5.99991 9C5.99991 10.3261 6.52669 11.5979 7.46437 12.5355C8.40206 13.4732 9.67383 14 10.9999 14ZM10.9999 12C10.2043 12 9.4412 11.6839 8.87859 11.1213C8.31598 10.5587 7.99991 9.79565 7.99991 9C7.99991 8.20435 8.31598 7.44129 8.87859 6.87868C9.4412 6.31607 10.2043 6 10.9999 6C11.7956 6 12.5586 6.31607 13.1212 6.87868C13.6838 7.44129 13.9999 8.20435 13.9999 9C13.9999 9.79565 13.6838 10.5587 13.1212 11.1213C12.5586 11.6839 11.7956 12 10.9999 12Z"
                  fill="#1F56F1"
                />
              </Svg>
            ) : (
              <Svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setShowPassword((prev) => !prev)}
              >
                <g clipPath="url(#clip0_474_227)">
                  <path
                    d="M4.51991 5.93394L1.39291 2.80794L2.80791 1.39294L22.6069 21.1929L21.1919 22.6069L17.8819 19.2969C16.1231 20.4125 14.0826 21.0033 11.9999 20.9999C6.60791 20.9999 2.12191 17.1199 1.18091 11.9999C1.61094 9.67066 2.78154 7.54284 4.51991 5.93394ZM14.7569 16.1719L13.2929 14.7079C12.733 14.9759 12.1037 15.0635 11.4918 14.9587C10.88 14.854 10.3157 14.562 9.87678 14.1231C9.43784 13.6841 9.14588 13.1199 9.04112 12.508C8.93637 11.8962 9.02399 11.2669 9.29191 10.7069L7.82791 9.24294C7.19162 10.2044 6.90718 11.3563 7.02274 12.5034C7.1383 13.6506 7.64676 14.7226 8.46203 15.5378C9.27729 16.3531 10.3493 16.8616 11.4964 16.9771C12.6436 17.0927 13.7954 16.8082 14.7569 16.1719ZM7.97391 3.75994C9.22091 3.26994 10.5799 2.99994 11.9999 2.99994C17.3919 2.99994 21.8779 6.87994 22.8189 11.9999C22.5125 13.6656 21.8238 15.2375 20.8069 16.5919L16.9469 12.7319C17.0616 11.9596 16.9937 11.1711 16.7488 10.4296C16.5039 9.68815 16.0888 9.01436 15.5366 8.46221C14.9845 7.91007 14.3107 7.49492 13.5693 7.25002C12.8278 7.00512 12.0393 6.93728 11.2669 7.05194L7.97391 3.75994Z"
                    fill="#D6D6D6"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_474_227">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </Svg>
            )}
          </InputContainer>
        </InnerContainer>
        <ExtrasContainer>
          <SaveLogin>
            <SaveBoxContainer>
              {saveLogin ? (
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  cursor="pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => setSaveLogin((prev) => !prev)}
                >
                  <g filter="url(#filter0_d_474_233)">
                    <rect
                      x="8"
                      y="4"
                      width="16"
                      height="16"
                      rx="2"
                      fill="white"
                    />
                    <path
                      d="M11.5 11.5L15 15L21 8.5"
                      stroke="#1F56F1"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                    <rect
                      x="8.5"
                      y="4.5"
                      width="15"
                      height="15"
                      rx="1.5"
                      stroke="#1F56F1"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_474_233"
                      x="0"
                      y="0"
                      width="32"
                      height="32"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0.0627451 0 0 0 0 0.160784 0 0 0 0.1 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_474_233"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_474_233"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              ) : (
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  cursor="pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => {
                    setSaveLogin((prev) => !prev);
                  }}
                >
                  <g filter="url(#filter0_d_471_438)">
                    <rect
                      x="8"
                      y="4"
                      width="16"
                      height="16"
                      rx="2"
                      fill="white"
                    />
                    <rect
                      x="8.5"
                      y="4.5"
                      width="15"
                      height="15"
                      rx="1.5"
                      stroke="#ADB5BD"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_471_438"
                      x="0"
                      y="0"
                      width="32"
                      height="32"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0.0627451 0 0 0 0 0.160784 0 0 0 0.1 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_471_438"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_471_438"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              )}
            </SaveBoxContainer>
            Remember me?
          </SaveLogin>
          {/* <SearchPassword to={"/search-password"}>
            Forgot Password
          </SearchPassword> */}
        </ExtrasContainer>
        {loginErrorState.message !== "" && (
          <ErrorContainer>
            <ErrorMessage>{loginErrorState.message}</ErrorMessage>
          </ErrorContainer>
        )}
        <ButtonContainer>
          <LoginButton onClick={handleLogin}>Sign In</LoginButton>
          <SignUpButton to={"/sign-up"}>Sign up</SignUpButton>
        </ButtonContainer>
      </Container>
    </Wrapper>
  );
}

export default Login;
