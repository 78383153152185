import { useCookies } from "react-cookie";

export const useLogout = () => {
  const [, , removeCookie] = useCookies([
    "TOKEN",
    "USER-INFORMATION",
    "ISADMIN",
  ]);
  const logout = () => {
    removeCookie("TOKEN", { path: "/" });
    removeCookie("USER-INFORMATION", { path: "/" });
    removeCookie("ISADMIN", { path: "/" });
    window.location.href = "/login";
  };
  return { logout };
};
