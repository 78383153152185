import React from 'react';
// import { Select } from 'antd';
import styled from 'styled-components';
import { useState } from 'react';
import { emailAddressState } from 'atoms/SignIn';
import { useRecoilState } from 'recoil';

const EmailSelectArr = [
  { label: 'naver.com', value: 'naver.com' },
  { label: 'hanmail.net', value: 'hanmail.net' },
  { label: 'yahoo.co.kr', value: 'yahoo.co.kr' },
  { label: 'empal.com', value: 'empal.com' },
  { label: 'nate.com', value: 'nate.com' },
  { label: 'lycos.co.kr', value: 'lycos.co.kr' },
  { label: '직접 입력', value: '직접 입력' },
];

const EmailSelectDiv = styled.select`
  all: unset;
  width: 400px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.gray1};
  background-color: ${({ theme }) => theme.colors.white};
  -webkit-appearance: none;
  -moz-appearance: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: ${({ theme }) => theme.fonts.size.xs};
  padding: 0 10px;
  color: #838383;
  option {
    font-size: 10px;
    margin-left: 10px;
  }
`;

const EmailSelect: React.FC = () => {
  const [emailAddress, setEmailAddress] = useRecoilState(emailAddressState);

  const handledEmailInput = (e: any) => {
    setEmailAddress(e.target.value);
  };

  return (
    <EmailSelectDiv name="sort" onChange={handledEmailInput}>
      <option selected hidden style={{ fontSize: '5px', marginLeft: '10px' }}>
        이메일을 입력해주세요
      </option>
      {EmailSelectArr.map((item, idx) => {
        return (
          <option key={idx} value={item.label}>
            {item.label}
          </option>
        );
      })}
    </EmailSelectDiv>
  );
};

// <Select
//   showSearch
//   style={{ width: 200, height: 300 }}
//   placeholder="Search to Select"
//   optionFilterProp="children"
//   // filterOption={(input, option) => (option?.label ?? '').includes(input)}
//   options={[
//     {
//       value: '1',
//       label: 'naver.com',
//     },
//     {
//       value: '2',
//       label: 'hanmail.net',
//     },
//     {
//       value: '3',
//       label: 'yahoo.co.kr',
//     },
//     {
//       value: '4',
//       label: 'empal.com',
//     },
//     {
//       value: '5',
//       label: 'hotmail.com',
//     },
//     {
//       value: '6',
//       label: 'nate.com',
//     },
//     {
//       value: '7',
//       label: 'lycos.co.kr',
//     },
//     {
//       value: '8',
//       label: 'paran.com',
//     },
//     {
//       value: '9',
//       label: 'korea.com',
//     },
//     {
//       value: '10',
//       label: 'gmail.com',
//     },
//   ]}
// />

export default EmailSelect;
