import styled from "styled-components";
import Modal from "components/community/Modal";

const ModalLogo = styled.div`
  align-item: center;
  justify-content: center;
  display: flex;
  margin: 30px;
`;

const ModalDescription = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.md};
  margin: 10px 40px;
  display: flex;
  flex-direction: column;
  align-item: center;
  justify-content: center;
  span {
    margin: auto;
  }
`;

const ModalButton = styled.button`
  width: 100px;
  height: 45px;
  cursor: pointer;
  border-radius: 10px;
  margin: 20px 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  background-color: ${({ theme }) => theme.colors.main};
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
  font-size: ${({ theme }) => theme.fonts.size.sm};
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-item: center;
  justify-content: center;
`;

function AuthorityModal({isOpenModal, setIsOpenModal}:{isOpenModal:boolean, setIsOpenModal: Function}) {

  return (
    <>
      <Modal>
        <>
          <ModalLogo>
            <img
              src={require("assets/modal-logo.png")}
              alt="modal logo"
              height={150}
            />
          </ModalLogo>
          <ModalDescription>
              <span>접근 권한이 없습니다.</span>
          </ModalDescription>
          <Buttons>
            <ModalButton onClick={() => setIsOpenModal(!isOpenModal)}>
              닫기
            </ModalButton>
          </Buttons>
        </>
      </Modal>
    </>
  );
}

export default AuthorityModal;
