import styled from 'styled-components';

import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { LoginState } from 'atoms/LoginState';
import { useCookies } from 'react-cookie';

function MenuBar(): JSX.Element {
  //user Login
  const [loginState, setLoginState] = useRecoilState(LoginState);
  const [cookies] = useCookies(['USER-INFORMATION', 'ISADMIN']);

  const [isChecked, setIsChecked] = useState<number>(0);
  const activeButton = (idx: number) => {
    if (idx === isChecked) {
      return '#1F56F1';
    } else {
      return '#fff';
    }
  };

  const menuList = [
    { title: '유저관리', url: '/admin/user-management?page=1' },
    { title: '전문가회원 승인', url: '/admin/expert-approval?page=1' },
    { title: '사이트운영관리', url: '/admin/operate?page=1' }
  ];

  const location = useLocation();
  const [currentPage, setCurrentPage] = useState<string>(
    location.pathname.split('/')[2]
  );
  const [subpathOpened, setSubpathOpened] = useState<boolean[]>([
    ['user-management', 'expert-approval'].includes(currentPage),
    ['idea-one', 'idea-two'].includes(currentPage)
  ]);

  const icons = [
    {
      clicked: require('@assets/clicked-user.png'),
      unclicked: require('@assets/unclicked-user.png')
    },
    {
      clicked: require('@assets/clicked-user2.png'),
      unclicked: require('@assets/unclicked-user2.png')
    },
    {
      clicked: require('@assets/clicked-user3.png'),
      unclicked: require('@assets/unclicked-user3.png')
    }
  ];

  useEffect(() => {
    setCurrentPage(location.pathname.split('/')[2]);
  }, [location]);

  return (
    <MenuContainer>
      <UserInformation>
        <Profile src={require('@assets/clicked-general-member.png')} />
        <Nickname>{cookies['ISADMIN'] === 'true' ? 'Admin' : ''}</Nickname>
        <UserType></UserType>
      </UserInformation>

      <NavSection>
        {menuList.map((item, idx) => (
          <MainPath
            onClick={() => setIsChecked(idx)}
            key={idx}
            color={activeButton(idx)}
          >
            <NaveLink to={item.url}>
              <Icon
                src={
                  idx === isChecked ? icons[idx].clicked : icons[idx].unclicked
                }
              />
            </NaveLink>
          </MainPath>
        ))}
      </NavSection>
    </MenuContainer>
  );
}

const MenuContainer = styled.div`
  ${({ theme }) => theme.media.mobile} {
    width: 150px;
  }
  display: flex;
  width: 84px;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;

  /* background-color: black; */
`;

const NavBlock = styled.li`
  width: 100%;
`;

const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PathTitle = styled.div<{
  mainPath?: boolean;
  opened?: boolean;
  isClicked?: boolean;
}>`
  ${({ theme }) => theme.media.mobile} {
    height: 35px;
    font-size: ${({ theme }) => theme.fonts.size.sm};
    font-weight: ${({ theme }) => theme.fonts.weight.medium};
  }

  font-size: ${({ theme }) => theme.fonts.size.md};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  color: ${({ theme, mainPath, opened, isClicked }) =>
    mainPath
      ? opened
        ? theme.colors.white
        : theme.colors.gray4
      : isClicked
      ? theme.colors.gray6
      : theme.colors.gray4};
  background-color: ${({ theme, mainPath, opened }) =>
    mainPath
      ? opened
        ? theme.colors.main
        : theme.colors.white
      : theme.colors.blue6};
  height: 38px;
  width: 38px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: ${({ mainPath }) => (mainPath ? 'flex-start' : 'center')};
  box-shadow: ${({ mainPath }) =>
    mainPath ? '0px 0px 10px 0px rgba(0, 0, 0, 0.1)' : ''};
  border-radius: 50%;
`;

const MainPath = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  box-shadow: 5px 3px 11px 0px rgba(17, 38, 146, 0.08);
  cursor: pointer;
`;

const SubPath = styled.div<{ pathCount: number; opened: boolean }>`
  ${({ theme }) => theme.media.mobile} {
    height: ${({ opened, pathCount }) => (opened ? 35 * pathCount : 0)}px;
    margin-left: 20px;
  }
  width: 100%;
  height: 160px;
  position: relative;
  overflow: hidden;
  height: ${({ opened, pathCount }) => (opened ? 55 * pathCount : 0)}px;
  transition: height 0.2s ease-in-out;
  :last-child {
    border-radius: 27px;
  }
`;

const Expandable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const UserInformation = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px;
`;

const Profile = styled.img`
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 40px;
  border: 10px solid ${({ theme }) => theme.colors.main};
  background: ${({ theme }) => theme.colors.white};
`;

const Nickname = styled.div`
  margin: 15px;
  font-size: ${({ theme }) => theme.fonts.size.lg};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  color: ${({ theme }) => theme.colors.gray6};
`;

const UserType = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.base};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  color: ${({ theme }) => theme.colors.gray4};
`;

const NavSection = styled.div`
  width: 100%;
  heiht: 100px;
  gap: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

const NaveLink = styled(Link)``;

export default MenuBar;
