import { NavigationProps } from './NavigationProps';

export const NavItems: NavigationProps[] = [
  {
    title: 'Home',
    path: '/',
    id: 1,
  },
  {
    title: '아이디어 제안',
    path: '/community?page=1',
    id: 2,
  },
  {
    title: '아이디어 토론',
    path: '/forum?page=1',
    id: 3,
  },
];
