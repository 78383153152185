import React from 'react';
import styled from 'styled-components';

interface ButtonProps {
  children?: React.ReactNode;
  onClick?: (e: any) => void;
  onSubmit?: () => void;
  margin?: string;
  width?: string;
  height?: string;
  padding?: number;
  bg?: string;
  color?: string;
  radius?: string;
  size?: string;
  cursor?: any;
  border?: string;
  display?: string;

  alignItems?: boolean;
  flexDirection?: boolean;
}

const Button = (props: ButtonProps) => {
  const {
    children,
    onClick,
    margin,
    width,
    height,
    padding,
    bg,
    color,
    radius,
    size,
    cursor,
    border,
    display,
    alignItems,
    flexDirection,
  } = props;

  const styles = {
    margin,
    width,
    height,
    padding,
    bg,
    color,
    radius,
    size,
    cursor,
    border,
    display,
    alignItems,
    flexDirection,
  };

  return (
    <ButtonDiv {...styles} onClick={onClick}>
      {children}
    </ButtonDiv>
  );
};

const ButtonDiv = styled.div`
  width: ${(props: ButtonProps) => props.width};
  height: ${(props: ButtonProps) => props.height};
  ${(props: ButtonProps) => (props.display ? `display: ${props.display};` : '')}
  ${(props: ButtonProps) =>
    props.flexDirection ? `flex-direction: ${props.flexDirection};` : ''}
  ${(props: ButtonProps) => (props.size ? `font-size: ${props.size}` : '')};
  ${(props: ButtonProps) => (props.bg ? `background-color: ${props.bg};` : '')};
  ${(props: ButtonProps) => (props.color ? `color: ${props.color};` : '')};
  ${(props: ButtonProps) =>
    props.radius ? `border-radius: ${props.radius};` : ''};
  ${(props: ButtonProps) =>
    props.padding ? `padding: ${props.padding};` : ''};
  ${(props: ButtonProps) => (props.margin ? `margin: ${props.margin};` : '')};
  ${(props: ButtonProps) => (props.cursor ? `cursor: pointer;` : '')};
  ${(props: ButtonProps) => (props.border ? `border: ${props.border}` : '')};
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #ffba00;
    color: black;
  }
`;

export default Button;
