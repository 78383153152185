import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { agreeModalState } from 'atoms/ModalState';
import { useRecoilState } from 'recoil';
import axios from 'axios';

const AgreeModal = (props: any) => {
  const { onClick } = props;
  const [isCheck, setIsCheck] = useState(false);
  const [isOpen, setIsOpen] = useRecoilState(agreeModalState);

  const [message, setMessage] = useState([
    '작성된 게시글은 업로드 후 수정이 불가하며, 삭제만 가능합니다.',
    '민팅된 아이디어는 삭제할 수 없습니다.',
    '아이디어 채택 시 생성되는 토론방 내의 토론 내용에 대한 비밀 유지 서약서에 동의해야 업로드가 가능합니다.  ',
  ]);

  const ContractDownload = async () => {
    axios({
      url: `/download/agreement`, // 파일 다운로드 요청 URL
      method: 'GET',
      responseType: 'blob',
    }).then((response: any) => {
      const blob = new Blob([response.data]);

      const fileObjectUrl = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = fileObjectUrl;
      link.style.display = 'none';

      const extractDownloadFilename = (response: any) => {
        const disposition = response.headers['content-disposition'];
        const fileName = decodeURI(
          disposition
            ?.split('/')[1]
            ?.substring(14)
            ?.slice(0, -Number(disposition.length) - 1)
        );

        return fileName;
      };

      link.download = extractDownloadFilename(response);

      link.download = '비밀유지계약서.hwp';

      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(fileObjectUrl);
    });
  };

  return (
    <ModalDiv>
      <ModalLogo>
        <img
          src={require('assets/modal-logo.png')}
          alt="modal logo"
          height={150}
        />
      </ModalLogo>
      <ModalDescription>
        {message.map((item) => (
          <span>* {item}</span>
        ))}
      </ModalDescription>
      <Contract
        onClick={async () => {
          ContractDownload();
        }}
      >
        <span>비밀 유지 계약서</span>
        <img src={require('assets/down.png')} alt="" />
      </Contract>
      <CheckBox>
        <input type="checkbox" onClick={() => setIsCheck(true)} />
        아이디어 채택 후 토론방 생성 시 진행되는 토론 내용에 대한 비밀 유지
        서약서에 동의합니다.
      </CheckBox>
      <ButtonDiv>
        <ModalButton onClick={() => setIsOpen(false)}>취소</ModalButton>
        <ModalButton
          onClick={() => {
            if (!isCheck) {
              alert('체크 버튼을 눌러주세요.');
            } else {
              onClick();
              setIsOpen(false);
            }
          }}
        >
          확인
        </ModalButton>
      </ButtonDiv>
    </ModalDiv>
  );
};

const ModalDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 639px;
  height: 600px;
  background-color: white;
`;

const CheckBox = styled.div`
  margin-bottom: 40px;
  font-size: 14px;
`;

const ButtonDiv = styled.div`
  display: flex;
  width: 420px;
  height: 40px;
  gap: 20px;
`;

const Contract = styled.div`
  display: flex;
  width: 525px;
  height: 44px;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  margin-bottom: 40px;
  margin-top: 40px;
  cursor: pointer;

  align-items: center;
  justify-content: center;

  img {
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-left: 10px;
  }
`;

const ModalLogo = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 30px;
`;

const ModalDescription = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: 14px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  span {
    margin-bottom: 5px;
  }
`;

const ModalButton = styled.button`
  width: 200px;
  height: 40px;
  cursor: pointer;
  border-radius: 18.5px;
  background-color: #ffffff;
  color: #1f56f1;
  border: 1.5px solid #1f56f1;
  border-radius: 25px;
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  &:hover {
    background-color: #1f56f1;
    color: white;
  }
`;

export default AgreeModal;
