import styled from "styled-components";
import { useState, useRef, useEffect } from "react";

const Wrapper = styled.div`
  margin-top: 20px;
`;

const FieldContainer = styled.div`
  display: flex;
  margin: 5px 0;
  width: 300px;
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
  align-items: center;
  justify-content: center;
`;

const FileContainer = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
  flex-direction: row;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
`;

const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
  flex-direction: column;
`;

const InputBox = styled.input`
  display: none;
`;

const Input = styled.input`
  all: unset;
  width: 100% - 10px;
  height: 30px;
  padding: 0 10px;
  background-color: transparent;

  font-size: ${({ theme }) => theme.fonts.size.sm};
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InputButton = styled.button`
  width: 80px;
  height: 30px;
  margin-left: 10px;
  background-color: ${({ theme }) => theme.colors.gray1};
  color: ${({ theme }) => theme.colors.gray6};
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 10px;
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.colors.gray2};
  }
  ${({ theme }) => theme.media.tablet} {
    width: 100px;
  }
`;

const Svg = styled.svg`
  margin: 10px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

interface FileProps {
  id: number;
  file: File;
  name: string;
}

function UploadBox(props: any): JSX.Element {
  const [files, setFiles] = useState<FileProps[]>([]);
  const fileInput = useRef<HTMLInputElement>(null);
  const [currentInput, setCurrentInput] = useState<HTMLInputElement>();

  const handleClick = () => {
    if (fileInput.current !== null) {
      setCurrentInput(fileInput.current);
      fileInput.current?.click();
    } else {
      currentInput?.click();
    }
  };

  const handleRemove = (idx: number) => {
    props.onRemove(idx);
    let copiedItems = files.filter((files) => files.id !== idx);

    copiedItems.forEach((value, index) => {
      copiedItems[index].id = index;
    });

    setFiles(copiedItems);
  };

  const onFileHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    const target = e.currentTarget;
    const file = (target.files as FileList)[0];

    if (file === undefined) {
      return;
    }

    setFiles((prev) => [
      ...prev,
      {
        id: idx,
        file: file,
        name: file.name,
      },
    ]);
  };

  useEffect(() => {
    props.getFiles(files);
  }, [files]);

  return (
    <Wrapper>
      {props.countList.map((idx: number) => (
        <FieldContainer key={idx}>
          <InputContainer>
            <FileContainer>
              <InputWrapper>
                <InputBox
                  ref={fileInput}
                  type="file"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onFileHandler(e, idx)
                  }
                />
                <Input
                  placeholder="선택된 파일 없음"
                  value={files[idx]?.name || "선택된 파일 없음"}
                  readOnly
                />
              </InputWrapper>
              {files[idx] && (
                <Svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => handleRemove(idx)}
                >
                  <g clipPath="url(#clip0_592_2064)">
                    <path
                      d="M12 10.5862L16.95 5.63623L18.364 7.05023L13.414 12.0002L18.364 16.9502L16.95 18.3642L12 13.4142L7.04999 18.3642L5.63599 16.9502L10.586 12.0002L5.63599 7.05023L7.04999 5.63623L12 10.5862Z"
                      fill="#141414"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_592_2064">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </Svg>
              )}
            </FileContainer>
            {!files[idx] && (
              <Label>
                <InputButton onClick={handleClick}>파일 업로드</InputButton>
              </Label>
            )}
          </InputContainer>
        </FieldContainer>
      ))}
    </Wrapper>
  );
}

export default UploadBox;
