import styled from 'styled-components';

const URL = process.env.REACT_APP_S3_BUCKET_URL;

const Download = styled.div`
  ${({ theme }) => theme.media.mobile} {
    width: 95px;
  }
  width: 100%;
  display: grid;
  grid-template-columns: 5fr 1fr;
  border: 1px solid ${({ theme }) => theme.colors.blue5};
  border-radius: 5px;
  align-items: center;
  text-align: center;
  div {
    width: 100%;
  }
  margin-bottom: 5px;
  padding: 3px;
  background: ${({ theme }) => theme.colors.white};
`;

const Data = styled.div`
  width: 100%;
`;

const Name = styled.div`
  color: ${({ theme }) => theme.colors.gray6};
  font-size: ${({ theme }) => theme.fonts.size.xs};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  flex-wrap: wrap;
  word-break: break-all;
`;

const DownloadIcon = styled.div`
  cursor: pointer;
`;

function FileBox({ detail }: { detail: string }): JSX.Element {
  const temp = detail.split('.');
  let extension = temp[temp.length - 1];
  let fileName = detail
    .split('/')[1]
    .substring(14)
    .slice(0, -Number(extension.length) - 1);

  if (fileName.length >= 30) {
    fileName = fileName.substring(0, 31) + '...';
  }
  fileName += '.' + extension;

  return (
    <Download>
      <Data>
        <Name>{fileName}</Name>
      </Data>
      <DownloadIcon>
        <a target="_blank" rel="noopener noreferrer" href={URL + detail}>
          <svg
            width="15"
            height="15"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_837_1390)">
              <path
                d="M3.75 15V25H26.25V15"
                stroke="#838383"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16 6.66602C16 6.11373 15.5523 5.66602 15 5.66602C14.4477 5.66602 14 6.11373 14 6.66602L16 6.66602ZM14.2929 20.7065C14.6834 21.097 15.3166 21.097 15.7071 20.7065L22.0711 14.3425C22.4616 13.952 22.4616 13.3188 22.0711 12.9283C21.6805 12.5378 21.0474 12.5378 20.6569 12.9283L15 18.5851L9.34315 12.9283C8.95262 12.5378 8.31946 12.5378 7.92893 12.9283C7.53841 13.3188 7.53841 13.952 7.92893 14.3425L14.2929 20.7065ZM14 6.66602L14 19.9993L16 19.9993L16 6.66602L14 6.66602Z"
                fill="#838383"
              />
            </g>
            <defs>
              <clipPath id="clip0_837_1390">
                <rect width="30" height="30" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </a>
      </DownloadIcon>
    </Download>
  );
}

export default FileBox;
