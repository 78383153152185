import styled from 'styled-components';
import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { agreeModalState } from 'atoms/ModalState';
import { useRecoilState } from 'recoil';

import Modal from './Modal';
import AgreeModal from './AgreeModal';

import { Editor as ToastEditor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import { HookCallback } from '@toast-ui/editor/types/editor';
import { LabelItems } from '../sign-up/CategoryItems';
import UploadBox from './UploadBox';
import ImagePicker from './ImagePicker';

import imageCompression from 'browser-image-compression';

const Wrapper = styled.div`
  display: flex;
  min-height: 800px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin-top: 100px;
  /* background-color: red; */
  flex-direction: column;
  min-width: 1024px;
  /* border-left: 1px solid ${({ theme }) => theme.colors.gray1};
  border-right: 1px solid ${({ theme }) => theme.colors.gray1}; */
  padding: 40px;
`;

const InputContainer = styled.div`
  display: flex;
  width: 300px;
  height: 40px;
  align-items: center;
  justify-content: center;
`;

const Select = styled.select`
  all: unset;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  background-color: transparent;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  color: ${({ theme }) => theme.colors.gray5};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  display: block;
  align-items: center;
  justify-content: flex-start;
  &:focus {
    border-color: ${({ theme }) => theme.colors.main};
  }
  z-index: 3;
`;

const Svg = styled.svg`
  margin-left: -24px;
  width: 24px;
  height: 24px;
`;

const TitleInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray1};
  margin: 20px 0 30px 0;
`;

const TitleInput = styled.input.attrs({
  required: true,
})`
  all: unset;
  height: 40px;
  width: 100%;
  padding: 0 10px;
  transition: width 0.2s ease-in-out;
  color: ${({ theme }) => theme.colors.gray6};
  font-size: ${({ theme }) => theme.fonts.size.xl};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
`;

const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: 10px 0 10px 0;
`;

const Button = styled.button`
  width: 80px;
  height: 35px;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  background-color: ${({ theme }) => theme.colors.main};
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.media.mobile} {
    width: 80px;
  }
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.sm};
`;

const AddButton = styled.div`
  display: flex;
  width: 270px;
  color: ${({ theme }) => theme.colors.gray4};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  cursor: pointer;
  margin-top: 5px;
`;

const PickerTitle = styled.span`
  font-size: ${({ theme }) => theme.fonts.size.xl};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  margin-bottom: 20px;
`;

const ImagePickerButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  align-items: center;
  justify-content: center;
`;

const ImagePickerButton = styled.button`
  width: 20%;
  height: 45px;
  cursor: pointer;
  border-radius: 10px;
  margin: 15px;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  background: ${(props) =>
    props.value === 'active'
      ? ({ theme }) => theme.colors.main
      : ({ theme }) => theme.colors.gray2};
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
  font-size: ${({ theme }) => theme.fonts.size.base};
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: scroll;
  width: 90%;
`;

const EachImageContainer = styled.div<{ value: string }>`
  display: flex;
  align-items: center;
  margin: 10px;
  cursor: pointer;
  :hover {
    border: 4px solid
      ${(props) =>
        props.value === 'active'
          ? ({ theme }) => theme.colors.main
          : ({ theme }) => theme.colors.blue2};
  }

  border: 4px solid
    ${(props) =>
      props.value === 'active'
        ? ({ theme }) => theme.colors.main
        : ({ theme }) => theme.colors.white};
`;

const ModalLogo = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 30px;
`;

const ModalDescription = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.md};
  margin: 10px;
  padding: 0 20px;
`;

const ModalButton = styled.button`
  width: 80%;
  height: 45px;
  cursor: pointer;
  border-radius: 10px;
  margin: 20px;
  padding: 0 20px;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  background-color: ${({ theme }) => theme.colors.main};
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
  font-size: ${({ theme }) => theme.fonts.size.sm};
`;

const AlertButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 40%;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.media.mobile} {
    width: 80%;
  }
`;

interface ImageProps {
  image: string;
  state: boolean;
}

interface FileProps {
  id: number;
  file: File;
  name: string;
}

function Editor(): JSX.Element {
  const [field, setField] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [attachments, setAttachments] = useState<FileProps[]>([]);
  const [thumbnail, setThumbnail] = useState<string>('');

  const [isThumbnail, setIsThumbnail] = useState<boolean>(false);
  const [countList, setCountList] = useState<number[]>([0]);
  const [imageList, setImageList] = useState<ImageProps[]>([]);

  const [isOpenImagePicker, setIsOpenImagePicker] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenAlert, setIsOpenAlert] =
    useRecoilState<boolean>(agreeModalState);
  const [cookies] = useCookies(['TOKEN']);

  const editorRef = useRef<any>();
  const navigate = useNavigate();

  const onChange = () => {
    const data = editorRef.current.getInstance().getHTML();
    setContent(data);
  };

  const uploadImage = async (f: Blob | File) => {
    const form = new FormData();
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1024,
    };
    const blobToFile = new File([f], 'image.jpeg', {
      type: f.type,
    });
    const compressedFile = await imageCompression(blobToFile, options);

    form.append('image', compressedFile);
    const url = await axios.post(`/idea/image`, form);

    return url.data.data;
  };

  const onUploadImage = async (blob: Blob | File, callback: HookCallback) => {
    const url = await uploadImage(blob);
    setImageList((prev) => [
      ...prev,
      {
        image: process.env.REACT_APP_S3_BUCKET_URL + url,
        state: true,
      },
    ]);

    callback(process.env.REACT_APP_S3_BUCKET_URL + url);
    return false;
  };

  const onClickImage = (src: string, e: any) => {
    setThumbnail(src);
    setIsThumbnail(true);
  };

  const handleTitleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setTitle(value);
  };

  const onAddHandler = () => {
    if (attachments.length !== countList.length) {
      alert('파일을 첨부한 후 클릭하세요.');
      return;
    }
    let countArr = [...countList];
    let counter = countArr.slice(-1)[0];
    counter += 1;
    countArr.push(counter);
    setCountList(countArr);
  };

  const onRemove = (idx: number) => {
    let countArr = countList.filter((countList) => countList !== idx);
    countArr.forEach((value, index) => {
      countArr[index] = index;
    });
    setCountList(countArr);
  };

  const checkAttachments = (attachments: FileProps[]) => {
    setAttachments(attachments);
  };

  const onClickImagePicker = () => {
    setThumbnail('');
    setIsThumbnail(false);
    let imageCount = imageList.length;

    imageList.forEach((value, id) => {
      if (!content.includes(value.image)) {
        let copiedItems = [...imageList];
        copiedItems[id].state = false;
        imageCount -= 1;
        setImageList(copiedItems);
      }
    });

    if (title === '' || field === '' || imageCount === 0) {
      setIsOpenModal(!isOpenModal);
    } else {
      setIsOpenImagePicker(!isOpenImagePicker);
    }
  };

  const getFieldId = (field: string) => {
    let fieldId: number = 0;
    LabelItems.slice(1).forEach((value, index) => {
      if (value.title === field) {
        fieldId = value.id;
      }
    });

    return fieldId;
  };

  const setImagePath = () => {
    let newImages: string[] = [];
    imageList.forEach((value, id) => {
      if (value.state) {
        newImages = [...newImages, `"${value.image}"`];
      }
    });

    return newImages;
  };

  const onClickSubmit = async () => {
    const imagePaths: string[] = setImagePath();
    const fieldId: number = getFieldId(field);

    const form = new FormData();

    form.append('title', title);
    form.append('content', content);
    form.append('imagePaths', '[' + imagePaths.toString() + ']');
    attachments.forEach((value) => form.append('attachments', value.file));
    form.append('thumbnail', thumbnail);
    form.append('interestField', fieldId.toString());

    try {
      const { data } = await axios({
        method: 'post',
        url: `/idea`,
        data: form,
        headers: {
          Authorization: `Bearer ${cookies['TOKEN']}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      if (data.success) {
        navigate('/community?page=1');
      } else {
        alert("something's wrong!");
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {isOpenModal && (
        <Modal>
          <>
            <ModalLogo>
              <img
                src={require('assets/modal-logo.png')}
                alt="modal logo"
                height={150}
              />
            </ModalLogo>
            <ModalDescription>
              {field === '' ? (
                <span>분야를 선택해야 합니다.</span>
              ) : title === '' ? (
                <span>제목을 입력해야 합니다.</span>
              ) : (
                <span>글에 이미지가 하나 이상 들어가야 합니다.</span>
              )}
            </ModalDescription>
            <ModalButton onClick={() => setIsOpenModal(!isOpenModal)}>
              확인
            </ModalButton>
          </>
        </Modal>
      )}
      {isOpenImagePicker && (
        <ImagePicker>
          <>
            <PickerTitle>대표 이미지 설정</PickerTitle>
            <ImageContainer>
              {imageList &&
                imageList.map(
                  (item: ImageProps, idx: number) =>
                    item.state && (
                      <EachImageContainer
                        key={idx}
                        value={thumbnail === item.image ? 'active' : 'inactive'}
                        onClick={(e) => {
                          onClickImage(item.image, e);
                        }}
                      >
                        <img src={item.image} alt="main" height={200} />
                      </EachImageContainer>
                    )
                )}
            </ImageContainer>
            <ImagePickerButtonContainer>
              <ImagePickerButton
                value={'active'}
                onClick={() => setIsOpenImagePicker(!isOpenImagePicker)}
              >
                취소
              </ImagePickerButton>
              <ImagePickerButton
                disabled={!isThumbnail}
                value={isThumbnail ? 'active' : 'inactive'}
                onClick={() => setIsOpenAlert(!isOpenAlert)}
              >
                확인
              </ImagePickerButton>
            </ImagePickerButtonContainer>
          </>
          )
        </ImagePicker>
      )}

      {isOpenAlert ? (
        <Modal>
          <AgreeModal onClick={onClickSubmit} />
        </Modal>
      ) : null}

      <Wrapper>
        <Container>
          <InputContainer>
            <Select
              name="field"
              value={field}
              onChange={({ currentTarget }) => setField(currentTarget.value)}
            >
              <option disabled value="" defaultValue="">
                {`분야를 선택해주세요.`}
              </option>
              {LabelItems.slice(1)?.map((item, idx) => {
                return (
                  <option key={idx} value={item.title}>
                    {item.title}
                  </option>
                );
              })}
            </Select>
            <Svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_592_2077)">
                <path
                  d="M12 13.1722L16.95 8.22217L18.364 9.63617L12 16.0002L5.63599 9.63617L7.04999 8.22217L12 13.1722Z"
                  fill="#8E8E8E"
                />
              </g>
              <defs>
                <clipPath id="clip0_592_2077">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </Svg>
          </InputContainer>
          <TitleInputContainer>
            <TitleInput
              placeholder="제목"
              value={title}
              onChange={handleTitleInput}
            />
          </TitleInputContainer>
          <ToastEditor
            initialValue="아이디어를 설명할 내용을 입력해주세요."
            previewStyle="vertical"
            height="600px"
            initialEditType="wysiwyg"
            useCommandShortcut={false}
            hideModeSwitch={true}
            plugins={[colorSyntax]}
            ref={editorRef}
            onChange={onChange}
            hooks={{
              addImageBlobHook: onUploadImage,
            }}
          />
          <UploadBox
            countList={countList}
            onRemove={onRemove}
            getFiles={checkAttachments}
          />
          <AddButton onClick={onAddHandler}>
            + 더 많은 파일을 첨부하려면 여기를 클릭하세요.
          </AddButton>
          <ContainerHeader>
            <ButtonContainer>
              <Button onClick={onClickImagePicker}>등록</Button>
            </ButtonContainer>
          </ContainerHeader>
        </Container>
      </Wrapper>
    </>
  );
}

export default Editor;
