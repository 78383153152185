import { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { AgreementItems } from './AgreementItems';
import { OnClickProps } from './OnClickProps';
import { checkedIcon, unCheckedIcon } from 'assets/icons';

interface AgreeType {
  first: boolean;
  second: boolean;
  all: boolean;
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  ${({ theme }) => theme.media.mobile} {
    width: 240px;
  }
  padding-top: 30px;
  padding: 40px;
  width: 650px;
  display: flex;
  margin: 100px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 15px;
  flex-direction: column;
  box-shadow: 2px 3px 5px 0px ${({ theme }) => theme.colors.gray2};
`;

const Title = styled.span`
  font-size: ${({ theme }) => theme.fonts.size.md};
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
  margin-bottom: 20px;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`;

const IconContainer = styled.div`
  display: flex;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  cursor: pointer;
`;

const AgreementTitle = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.sm};
  color: ${({ theme }) => theme.colors.gray6};
`;

const Detail = styled.textarea`
  width: 630px;
  height: 140px;
  resize: none;
  padding: 10px;
  :focus {
    outline: none;
  }
  display: block;
  margin: 10px 0;
  white-space: pre-line;
  border: 1px solid ${({ theme }) => theme.colors.gray1};
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.gray6};
  font-size: ${({ theme }) => theme.fonts.size.xs};
  ${({ theme }) => theme.media.mobile} {
    width: 220px;
    height: 290px;
  }
`;

const Buttons = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

const NextButton = styled.button`
  height: 40px;
  margin: 0 5px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.main};
  width: 140px;
  margin-right: 10px;
  ${({ theme }) => theme.media.mobile} {
    width: 130px;
  }
  color: ${(props) =>
    props.value === 'active'
      ? ({ theme }) => theme.colors.white
      : ({ theme }) => theme.colors.main};
  background-color: ${(props) =>
    props.value === 'active'
      ? ({ theme }) => theme.colors.main
      : ({ theme }) => theme.colors.white};
  cursor: ${(props) => (props.value === 'active' ? 'pointer' : 'default')};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.sm};
`;

const CancelButton = styled.button`
  width: 70px;
  height: 40px;
  margin: 0 5px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.gray3};
  ${({ theme }) => theme.media.mobile} {
    width: 80px;
  }
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.sm};
`;

function Agreement({ onNextHandler }: OnClickProps): JSX.Element {
  const [{ first, second }, setAgree] = useState<AgreeType>({
    first: false,
    second: false,
    all: false,
  });

  const checkedAll = first && second;

  const navigate = useNavigate();

  const navigateToPrev = () => {
    navigate('/sign-up');
  };

  const handleAllCheck = () => {
    setAgree((prevState) => ({
      ...prevState,
      first: !prevState['all'],
      second: !prevState['all'],
      all: !prevState['all'],
    }));
  };

  const handleFirstCheck = () => {
    setAgree((prevState) => ({
      ...prevState,
      first: !prevState['first'],
    }));
  };

  const handleSecondCheck = () => {
    setAgree((prevState) => ({
      ...prevState,
      second: !prevState['second'],
    }));
  };

  return (
    <Wrapper>
      <Container>
        <Title>약관동의</Title>
        <InnerContainer>
          <Detail defaultValue={AgreementItems[0].content} />
          <Label>
            <IconContainer onClick={handleFirstCheck}>
              {first ? <>{checkedIcon()}</> : <>{unCheckedIcon()}</>}
            </IconContainer>
            <AgreementTitle>이용약관 동의 (필수)</AgreementTitle>
          </Label>

          <Detail defaultValue={AgreementItems[1].content} />
          <Label>
            <IconContainer onClick={handleSecondCheck}>
              {second ? <>{checkedIcon()}</> : <>{unCheckedIcon()}</>}
            </IconContainer>
            <AgreementTitle>개인정보 수집 및 이용 동의 (필수)</AgreementTitle>
          </Label>
          <Label>
            <IconContainer onClick={handleAllCheck}>
              {checkedAll ? <>{checkedIcon()}</> : <>{unCheckedIcon()}</>}
            </IconContainer>
            <AgreementTitle>
              이용약관, 개인정보 수집 및 이용에 모두 동의합니다.
            </AgreementTitle>
          </Label>
        </InnerContainer>
        <Buttons>
          <CancelButton type="button" onClick={navigateToPrev}>
            취소
          </CancelButton>
          <NextButton
            type="button"
            disabled={!checkedAll}
            value={checkedAll ? 'active' : 'inactive'}
            onClick={onNextHandler}
          >
            다음으로
          </NextButton>
        </Buttons>
      </Container>
    </Wrapper>
  );
}

export default Agreement;
