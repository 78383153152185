import { AgreementItems } from "@components/sign-up/AgreementItems";
import Block from "components/global/Block";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InnerContainer = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.base};
  line-height: ${({ theme }) => theme.fonts.size.xl};
`;

function Terms(): JSX.Element {
  return (
    <Container>
      <Block
        title={AgreementItems[0].title}
        contents={<InnerContainer>{AgreementItems[0].content}</InnerContainer>}
      />
    </Container>
  );
}

export default Terms;
