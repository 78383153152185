import { AgreementProps } from "./AgreementProps";

export const AgreementItems: AgreementProps[] = [
  {
    id: 1,
    title: "이용약관",
    content: `
    제1조(목적) 이 약관은 씨에듀블록 주식회사(이하 ‘회사’라고 함)가 운영하는 토트 플랫폼(이하 ‘플랫폼’이라 함)의 이용조건과 절차, 권리 의무, 책임 및 기타 이를 위하여 필요한 제반 사항을 규정함을 목적으로 합니다.
    
    제2조(정의) ① 본 약관에서 사용하는 용어의 정의는 다음과 같다. 
    
    1. “토트 플랫폼 서비스”란 아이디어의 제안과 개발 및 아이디어의 상품화를 위한 인터넷 기반의 서비스를 말합니다.
    
    2. “이용자”란 이 “플랫폼”에 접속하여 이 약관에 따라 “플랫폼”이 제공하는 서비스를 받는 회원과 비회원을 말합니다.
    
    3. ‘회원’이라 함은 “플랫폼”에 회원등록을 한 자로서, 계속적으로 이 “플랫폼”이 제공하는 서비스를 이용할 수 있는 자를 말합니다.
    
    4. ‘비회원’이라 함은 회원에 가입하지 않고 이 “플랫폼”이 제공하는 서비스를 이용하는 자를 말합니다.
    
    5. ‘전문가 회원’이라 함은 회원이 제안하는 아이디어를 개발하고 구체화를 도와줄 수 있는 역량을 보유한 자로서 플랫폼으로부터 전문성을 인증받은 회원을 말합니다.
      
    6. ‘아이디어 방’이라 함은 회원이 아이디어 과제를 제안하고, 제안된 아이디어 개발과 구체화 및 완성을 위하여 전문가들이 참여하여 의견을 개진할 수 있는 공간을 말합니다.
    
    7. ‘아이디어 마켓’이라 함은 이 플랫폼에서 개발된 아이디어를 경매나 입찰 등을 통하여 거래할 수 있는 공간을 말합니다.
    
    ② 본 약관에서 사용하는 용어 중 위 제1항에서 정하지 아니한 것은 관련 법령과 일반 관례에 따릅니다.
    
    제3조(약관 등의 명시와 설명 및 개정) ① 회사는 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호, 모사전송번호, 전자우편주소, 사업자등록번호, 통신판매업 신고번호, 개인정보관리책임자 등을 이용자가 쉽게 알 수 있도록 이 플랫폼의 초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
    
    ② “플랫폼”은 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회, 배송책임, 환불 조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다.
    
    ③ “회사”는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
    
    ④ “회사”는 이 약관을 개정할 때는 적용 일자 및 개정 사유를 명시하여 현행약관과 함께 플랫폼의 초기화면에 그 적용 일자 7일 이전부터 적용 일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관 내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 "플랫폼“은 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다. 
    
    ⑤ 회원이 변경 약관에 동의하지 않으면 언제든지 회원 탈퇴를 신청하여 이용 계약을 해지할 수 있으며, 변경 약관의 효력발생일 이후에도 회원 탈퇴를 하지 않으면 변경 약관을 승인한 것으로 간주됩니다.
    
    ⑥ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 「전자상거래 등에서의 소비자보호에 관한 법률」,「약관의 규제에 관한 법률」,「게임산업진흥에 관한 법률」,「정보통신망이용촉진 및 정보보호 등에 관한 법률」,「콘텐츠산업진흥법」 등 관련 법령 또는 상 관례에 따릅니다.
    
    ⑤ 회원이 본 약관에 대한 동의는 본 약관에 따라 신청서에 기재된 회원 정보를 “플랫폼”이 수집, 이용하는 것에 동의하는 것으로 봅니다.
    
    제4조(서비스의 제공 및 변경) ① “플랫폼”은 다음과 같은 업무를 수행합니다.
    
    1. 아이디어의 제안과 개발 및 상품화를 위한 컨설팅 서비스 
    
    2. 완성된 아이디어에 대한 지적 재산권 등록, NFT 발행(minting) 및 아이디어 거래를 위한 아이디어 마켓 운영
    3. 완성된 아이디어의 상품화를 위한 펀딩 및 제조사 선정 등을 위한 제반 컨설팅 업무
    
    4. 기타 이 “플랫폼”의 목적을 달성을 위하여 필요하다고 인정한 업무
        
    ② 회사는 “플랫폼”의 서비스 모델 및 기술적 사양 등의 변경 필요성이 있는 경우에는 서비스 내용을 변경할 수 있으며, 이 경우 플랫폼은 그 사유를 지체없이 홈페이지에 공고합니다. 
    
     
    제5조(서비스 중단) ① 컴퓨터 등 정보통신설비의 보수점검․ 교체 및 고장 등 기술상, 운영상 필요한 경우 및 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적, 종국적으로 중단할 수 있습니다. 이 경우 30일 전에 홈페이지에 공고하되, 부득이한 사정이 있는 경우는 사후에 공지 또는 통지를 할 수 있습니다. 
    
    ② “플랫폼”의 서비스를 영구히 중단하는 경우에는 공지 기간은 최소 30일 이상으로 합니다. 
    
    ③  제1항 및 제2항의 경우에 “플랫폼”이 제공하는 서비스의 이용과 관련하여, “플랫폼”은 “회원”에게 발생한 어떠한 손해에 대해서도 책임을 지지 않습니다. 
    
    제6조(회원 가입) ① 이용자는 “플랫폼”이 정한 가입 양식에 따라 회원 정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로써 회원 가입을 신청합니다.
    
    ② 가입신청 과정에 플랫폼상의 기술적 장애가 있거나 플랫폼 운영 정책상 가입신청을 받을 수 없는 사정이 있는 경우에는 가입신청이 지연될 수 있습니다. 이 경우 가입 신청자에게 이를 알려야 합니다. 다만 플랫폼의 귀책 사유 없이 가입 신청자에게 통지할 수 없는 경우에는 그러하지 아니합니다.
    
    ③ “플랫폼”은 제1항과 같이 회원 가입을 신청한 이용자 중, 다음 각호에 해당하지 않는 한 회원으로 등록합니다.
    
    1. 가입 신청자가 이 약관 제7조 제3항에 의하여 이전에 회원 자격을 상실한 적이 있는 경우, 다만 제8조 제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 “플랫폼”의 회원 재가입 승낙을 얻은 경우에는 예외로 한다.
    
    2. 등록 내용에 허위, 기재 누락, 오기가 있는 경우 
    
    3. 기타 회원으로 등록하는 것이 “플랫폼”의 기술상 현저히 지장이 있다고 판단되는 경우
    
    ③ 회원 가입 계약의 성립 시기는 “플랫폼”의 승낙이 회원에게 도달한 시점으로 합니다.
    
    ④ 회원은 회원 가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 “플랫폼”에 대하여 회원 정보 수정 등의 방법으로 그 변경사항을 알려야 합니다.
    
    제7조 (회원 ID 부여, 변경 및 휴면계정) ① 회원은 회원가입시에 발급받은 ID로 계정이 만들어지며, 당해 계정으로 “플랫폼”에서 제공하는 서비스를 이용할 수 있습니다.
    
    ② 회원 ID는 원칙적으로 변경이 불가합니다. 다만 다음 각호의 경우에는 정보 주체 또는 “플랫폼”의 요청으로 ID를 변경할 수 있습니다.
    
    1. 회원 ID가 전화번호, 생년월일 등으로 만들어져 사생활 침해가 있는 경우
    
    2. 기타 ID를 변경하여야 하는 합리적인 사유가 있는 경우
    
    ③ “플랫폼”은 1년 이상 장기간 로그인을 하지 않은 회원 계정을 휴면계정으로 변경하여 별도로 관리합니다.
    
    제8조(회원탈퇴 및 자격 상실 등) ① 회원은 “플랫폼”에 언제든지 탈퇴를 요청할 수 있으며 “플랫폼”은 즉시 회원탈퇴를 처리합니다.
    
    ② 회원이 다음 각호의 사유에 해당하는 경우, “플랫폼”은 회원자격을 제한 또는 정지시킬 수 있습니다.
    
    1. 가입신청 시에 허위 내용을 등록한 경우
    
    2. 다른 사람의 명의를 사용하는 등 본인의 실명으로 가입신청하지 않은 경우
    
    3. 기타 “플랫폼”이 정한 가입신청 요건이 미비된 경우
    
    4. 다른 사람의 “플랫폼” 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우
    
    5. “플랫폼”을 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우
    
    6. 정보통신설비의 오작동이나 정보 등의 파괴를 유발시키는 컴퓨터 바이러스 프로그램 등을 유포하는 경우
    
    7. 본 약관을 위반하거나 이에 반하는 행위를 하는 경우
    
    8. 기타 관련 법령이나 플랫폼이 정한 이용조건에 위배되는 행위를 한 경우
    
    ③ “플랫폼”은 회원자격을 제한, 정지시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 회원자격을 상실시킬 수 있습니다.
    
    ④ “플랫폼”은 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.
    
    ⑤ 회원자격 상실과 관련하여 발생한 손해는 자격을 상실당한 회원이 부담하여야 하고, 그에 대하여 플랫폼은 일체의 책임을 부담하지 않습니다.
    
    제8조(회원에 대한 통지) ① 회원에 대한 통지는 회원이 “플랫폼”과 미리 약정하여 지정한 전자우편, 문자(SMS 및 카카오톡 등), 푸시 등으로 할 수 있습니다.
    
    ② 불특정다수 회원에 대한 통지의 경우 1주일 이상 “플랫폼” 게시판에 게시함으로써 개별통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.
    
    제9조 (회원 정보의 변경) ① 회원은 개인정보 관리를 통해 언제든지 본인의 정보를 열람하고 수정할 수 있습니다. ID 변경은 제7조 제2항에 따릅니다.
    
    ② 회원은 가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 해야 하며, 회원정보를 변경하지 아니하여 발생하는 문제의 책임은 그 회원에게 있습니다.
    
    제10조(“플랫폼“의 의무) ① “플랫폼”은 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며, 다음의 경우를 제외하고는 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화, 용역을 제공하는 데 최선을 다하여야 합니다.
    
    1. 서비스용 설비의 보수, 정기점검 또는 공사로 인하여 부득이한 경우
    
    2. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지한 경우
    
    3. 전시, 사변, 천재지변 또는 이에 준하는 국가비상사태가 발생하거나 발생할 우려가 있는 경우
    
    4. 설비 장애 또는 이용 폭주 등으로 인하여 서비스 이용에 지장이 있는 경우
    
    ② “플랫폼”은 이용자가 안전하게 인터넷서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함) 보호를 위한 보안 시스템을 갖추어야 합니다.
    
    ③ “플랫폼”이 상품이나 용역에 대하여 「표시․광고의 공정화에 관한 법률」 제3조 소정의 부당한 표시․광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.
    
    ④ “플랫폼”은 이용자가 원하지 않는 영리 목적의 광고성 전자우편을 발송하지 않습니다.
    
    제19조(회원의 ID 및 비밀번호에 대한 의무) ① 제17조의 경우를 제외한 ID와 비밀번호에 관한 관리 책임은 회원에게 있습니다.
    
    ② 회원은 자신의 ID 및 비밀번호를 제3자에게 알려주거나 이용하게 해서는 안 됩니다.
    
    ③ 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 “플랫폼”에 통보하고 “플랫폼”의 안내가 있는 경우에는 그에 따라야 합니다.
    
    제11조(이용자의 의무) 이용자는 “플랫폼 서비스”와 관련하여 다음 각 호에 해당하는 행위를 하여서는 안 됩니다.
    
    1. 신청 또는 변경 시 허위 내용의 등록
    
    2. 플랫폼상의 타인의 아이디어나 게시물 및 정보를 도용, 표절, 탈취하는 행위 및 이를 복제, 출판, 방송 등 기타의 방법으로 사용하거나 타인에게 제공하는 행위
    
    3. “플랫폼”에 게시된 정보를 무단으로 변경하는 행위
    
    4. “플랫폼”이 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등을 송신하거나 또는 게시하는 행위
    
    5. “플랫폼” 및 기타 제3자의 저작권 등 지적 재산권을 침해하는 행위
    
    6. “플랫폼” 기타 제3자의 명예를 손상하거나 업무를 방해하는 행위
    
    7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 플랫폼에 공개, 게시 또는 링크를 하는 일체의 행위
    
    8. 선량한 풍속 기타 사회질서를 해하는 행위
    
    9. 기타 서비스의 안정적인 운영을 방해하거나 방해할 우려가 있는 일체의 행         위  
    
    제12조(개인정보의 보호) ① “플랫폼”은 이용자의 개인정보 수집시 서비스 제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다. 
    
    ② “플랫폼”은 회원가입시 구매계약이행에 필요한 정보를 미리 수집하지 않습니다. 다만, 관련 법령상 의무이행을 위하여 구매계약 이전에 본인확인이 필요한 경우로서 최소한의 특정 개인정보를 수집하는 경우에는 그러하지 아니합니다.
    ③ “플랫폼”은 이용자의 개인정보를 수집·이용하는 때에는 당해 이용자에게 그 목적을 고지하고 동의를 받습니다.
    
    ④ “플랫폼”은 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며, 새로운 이용 목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용·제공단계에서 당해 이용자에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.
    
    ⑤ “플랫폼”이 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용 목적, 제3자에 대한 정보 제공 관련 사항(제공받은자, 제공목적 및 제공할 정보의 내용) 등 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제22조 제2항이 규정한 사항을 미리 명시하거나 고지해야 하며. 이용자는 언제든지 이 동의를 철회할 수 있습니다.
    
    ⑥ 이용자는 언제든지 “플랫폼”이 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 “플랫폼”은 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 “플랫폼”은 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.
    
    ⑦ “플랫폼”은 개인정보 보호를 위하여 이용자의 개인정보를 취급하는 자를 최소한으로 제한하여야 하며, 신용카드, 은행 계좌 등을 포함한 이용자의 개인정보 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.
    
    ⑧ “플랫폼” 또는 그로부터 개인정보를 받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 바로 파기합니다.
    
    ⑨ “플랫폼”은 개인정보의 수집·이용·제공에 관한 동의란을 미리 선택한 것으로 설정해두지 않습니다. 또한, 개인정보의 수집·이용·제공에 관한 이용자의 동의 거절 시 제한되는 서비스를 구체적으로 명시하고, 필수수집항목이 아닌 개인정보의 수집·이용·제공에 관한 이용자의 동의 거절을 이유로 회원가입 등 서비스 제공을 제한하거나 거절하지 않습니다.
    
    제13조 (저작권의 귀속 및 이용제한) ① “플랫폼“이 작성한 저작물에 대한 저작권 기타 지적재산권은 ”플랫폼에 귀속합니다.
    
    ② 이용자는 “플랫폼”을 이용함으로써 얻은 정보 중 “플랫폼”에게 지적 재산권이 귀속된 정보를 “플랫폼”의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리 목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.
    
    ③ 회원이 제안한 아이디어나 게시물이 타인의 저작권 등 지식재산권을 침해함으로써 발생하는 민·형사상의 책임은 전적으로 당해 회원이 부담합니다.
    
    제14 (아이디어의 게시와 아이디어 방의 운영)
    ① 회원은 플랫폼의 게시판에 자신의 아이디어를 게시할 수 있으며, 2주간 일반회원을 대상으로 의견을 받고, 의견을 제시한 회원 중 일정 비율 이상의 긍정 의견을 받은 경우, 당해 회원은 그 아이디어를 아이디어 방에 올려 전문가의 의견을 받아 볼 수 있습니다. 
    
    ② 아이디어 방에서의 아이디어의 개발과 구체화를 위한 진행은 아이디어를 올리 회원(아이디어 제안자)의 책임으로 진행합니다.
    
    ⓷ 아이디어가 완성되어 그 아이디어가 상품화될 경우 상품화로 인한 수익 분배 방식에 대하여는 그 수익분배 방식은 아이디어의 완성에 대해 회원과 전문가의 기여율에 따라 결정되고, 기여율은 별도의 동료평가 방식을 통하여 정해집니다. 
     
    제15조 (플랫폼의 이용대가) “플랫폼”상의 서비스 시험 운용 기간에는 서비스가 무료로 제공되나, 정식 서비스가 제공되는 경우에는 유료로 전환될 수 있습니다. 유료 서비스, 유료 정보 및 환불 등 기타 사항에 대해서는 별도로 정한 바에 따릅니다.
    
    제16조 (아이디어 거래 및 계약체결) ① 회원은 플랫폼상에서 제공되는 표준거래계약서에 기반하여 플랫폼상의 아이디어 마켓에서 아이디어를 거래할 수 있습니다. 
    
    ② 아이디어 마켓 개장 시기, 운영방식, 수수료 및 거래방법 등에 대하여는 별도로 정한 바에 따릅니다.
    
    ③ 민법상 19세 미만의 미성년자인 회원의 경우 그 법정대리인이 미성년 회원의 “플랫폼”상의 서비스 이용 행위에 대하여 동의하고, 모든 책임이 본인에게 있다는 동의서를 “플랫폼”에 제출하거나 “플랫폼”에서 제공하는 절차에 따라 그 책임 부담에 동의해야만 이 플랫폼상의 모든 서비스를 이용할 수 있습니다.
    
     제17조 (회원의 게시물) ① 회원이 게시한 아이디어와 의견 및 기타의 게시물에 대한 모든 권리 및 책임은 이를 게시한 회원에게 있으며, 회사는 회원이 “플랫폼” 상에 게시한 게시물이 다음 각호에 해당한다고 판단되는 경우에 사전 통지 없이 삭제할 수 있고, 이에 대하여 회사는 어떠한 법적 책임도 지지 않습니다.
    
    1. 게시물이 다른 회원 또는 제3자를 모욕하거나 명예훼손 하는 내용을 담고 있는 경우
    
    2. 게시물이 음란물 또는 이를 위한 링크이거나 공서양속에 반하는 경우
    
    3. 게시물이 범죄적 행위에 결부된다고 인정되는 경우
    
    4. “플랫폼”의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우
    
    5. “플랫폼”이 규정한 게시물 원칙에 어긋나거나, 게시판 성격에 부합하지 않는 경우
    
    6. 불법복제 또는 해킹을 조장하는 내용인 경우
    
    7. 게시물이 영리를 목적으로 하는 광고인 경우
    
    10. 서비스를 이용하여 얻은 정보를 “플랫폼”의 사전 승낙 없이 복사, 복제, 변경, 번역, 출판, 방송. 기타의 방법으로 사용하거나 이를 타인에게 제공하는 경우
    
    11. 기타 관련 법령에 위배된다고 판단되는 경우
    
    ② “플랫폼”은 회원이 등록한 게시물을 검색 노출, 판촉, 홍보 및 기타의 자료로 무상 사용할 수 있습니다. 다만, “플랫폼”은 그중에서 아이디어를 사용할 경우 회원의 사전허락을 받아야 하며, 해당 게시물을 등록한 회원이 게시물의 삭제와 함께 사용중지를 요청한 경우에는 즉시 사용을 중지합니다.
    
    ③ 회원이 “플랫폼”에 게재한 아이디어나 의견 기타 게시물의 내용이 사생활 침해, 명예훼손 등 타인의 권리를 침해하는 경우 침해받은 자가 “플랫폼”에 침해사실을 소명하여 침해정보의 삭제를 요청하거나 반박내용의 게재를 요청하는 경우, “플랫폼”은 지체 없이 침해정보의 삭제, 30일 범위에서 임시적 접근차단조치 등 필요한 조치를 취해야 합니다. “플랫폼”이 스스로 침해정보로 판단한 경우에도 동일합니다.
    
    ⑤“플랫폼”이 침해정보에 대하여 필요한 조치를 취한 경우 “플랫폼”은 즉시 요청자 및 침해정보 게재자에게 통지합니다. 다만, 게재 또는 등록한 정보를 삭제당한 회원이 분쟁당사자 간의 합의문, 확정력 있는 판결, 조서 또는 기타 삭제 또는 임시조치된 정보가 침해정보가 아니라는 충분한 증빙 등을 제시하여 “플랫폼”에 삭제 정보의 복구, 임시조치의 중단 등을 요구하는 경우 “플랫폼”은 그 요구에 응할 수 있습니다.
    
    ⑥ 회원의 게시물 정보(제안한 아이디어, 댓글 등 회원이 플랫폼 이용시 생성되는 모든 기록 및 정보를 포함)는 회원탈퇴를 한 경우에도 5년간 보관ㆍ관리됩니다. 단, 회원 본인이 삭제한 게시물 정보는 예외로 하나, “플랫폼”에서 거래되거나 수상된 게시물 정보는 회원 본인이 삭제하더라도 삭제한 시점 이후부터 5년간 보관ㆍ관리됩니다.
    
    제18조(손해배상 및 면책)
    ① 회원이 본 약관의 규정을 위반함으로 인하여 “플랫폼”에 손해가 발생하는 경우 이 약관을 위반한 회원은 “플랫폼”에 발생하는 모든 손해에 대해 민ㆍ형사상 책임을 집니다.
    
    ② 회사는 “플랫폼”상에 회원 또는 기타 관계 기관이 게시 또는 제공하는 게시물의 내용상 정확성, 무결성 및 완전성을 보장하지 않으며, 회원이 이를 이용함으로 인하여 입게 된 어떤 종류의 손해에 대하여서도 손해배상책임을 책임을 부담하지 아니합니다.
    
    ③ 회사는 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로 제공하지 아니하여 플랫폼의 서비스가 이루어지지 아니하여 손해가 발생한 경우 책임이 면제됩니다.
    
    ④ 회사는 “플랫폼” 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 인하여 발생한 손해에 대한 책임이 면제됩니다.
    
    ⑤ “플랫폼”상의 서비스와 관련하여 회원이 손해를 입더라도 회사는 이에 대하여 어떠한 책임도 지지 않으며, 회원은 이의신청 등 일체의 민원을 제기할 수 없습니다. 다만 “플랫폼”의 고의 또는 중대한 과실에 의한 경우에는 그러하지 않습니다.
    
    제19조 (분쟁해결) ① “플랫폼”은 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치․운영합니다.
    
    ② “플랫폼”은 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.
    
    ③ “플랫폼”과 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
    
    제20조 (재판권 및 준거법) ① “플랫폼”과 이용자 간에 발생한 전자상거래 분쟁에 대한 소송은 제소 당시 이용자의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.
    
    ② “플랫폼”과 이용자 간에 제기된 전자상거래 소송에는 한국법을 적용합니다.
    `,
  },
  {
    id: 2,
    title: "토트 플랫폼 개인정보 처리방침",
    content: `
      토트 플랫폼은 「개인정보 보호법」 제30조에 따라 정보 주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.
      
      제1조(개인정보의 처리 목적) 토트 플랫폼은 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
      
      1. 홈페이지 회원가입 및 관리
      
      회원 가입 의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 만14세 미만 아동의 개인정보 처리 시 법정대리인의 동의여부 확인, 각종 고지·통지, 고충처리 목적으로 개인정보를 처리합니다.
      
      
      2. 재화 또는 서비스 제공
      
      물품배송, 서비스 제공, 계약서·청구서 발송, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 연령인증, 요금결제·정산을 목적으로 개인정보를 처리합니다.
      
      
      3. 마케팅 및 광고에의 활용
      
      신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 등을 목적으로 개인정보를 처리합니다.
      
      제2조(개인정보의 처리 및 보유 기간) ① 토트 플랫폼은 법령에 따른 개인정보 보유·이용 기간 또는 정보 주체로부터 개인정보의 수집 시에 동의받은 개인정보 보유·이용 기간 내에서 개인정보를 처리·보유합니다. 
      
      ② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다. 
      1. 홈페이지 회원 가입 및 관리 : 사업자/단체 홈페이지 탈퇴 시까지 
           다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지 
           1) 관계 법령 위반에 따른 수사․조사 등이 진행 중인 경우에는 해당 수사·조사 종료 시까지 
           2) 홈페이지 이용에 따른 채권·채무관계 잔존 시에는 해당 채권·채무 관계 정산 시까지 
           
       2. 재화 또는 서비스 제공 : 재화·서비스 공급 완료 및 요금결제·정산 완료 시까지, 다만, 다음의 사유에 해당하는 경우에는 해당 기간 종료 시까지 
           1) 「전자상거래 등에서의 소비자 보호에 관한 법률」에 따른 표시·광고, 계약 내용 및 이행 등 거래에 관한 기록 
            - 표시․광고에 관한 기록 : 6개월 
            - 계약 또는 청약 철회, 대금결제, 재화 등의 공급기록 : 5년 
            - 소비자 불만 또는 분쟁 처리에 관한 기록 : 3년 
          
            2) 「통신비밀보호법」에 따른 통신사실확인자료 보관
            - 가입자 전기통신일시, 개시, 종료시간, 상대방 가입자 번호, 사용도수, 발신기지국 위치추적자료 : 1년 
            - 컴퓨터 통신, 인터넷 로그 기록 자료, 접속지 추적자료 : 3개월
      
      제3조 (처리하는 개인정보의 항목) 토트 플랫폼은 다음의 개인정보 항목을 처리하고 있습니다.
      
      <필수항목> 이메일, 휴대전화번호, 자택 주소, 비밀번호 질문과 답, 비밀번호, 로그인 ID, 생년월일, 이름, 직업, 서비스 이용기록, 접속 로그, 쿠키, 접속 IP 정보, 법정대리인 이름, 법정대리인 휴대전화번호
      
      <선택항목> 자택 전화번호, 회사명, 직책, 부서, 회사전화번호, 성별, 학력, 법정대리인 자택 주소, 법정대리인 자택 전화번호,
      
      <수집방법> 토트 플랫폼 홈페이지, 서면 양식.
      
      제4조(개인정보의 제3자 제공에 관한 사항) 토트 플랫폼은 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
      
      제5조(개인정보의 파기절차 및 파기방법) ①토트 플랫폼은 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
      
      ② 정보 주체로부터 동의받은 개인정보 보유 기간이 경과하거나 처리목적이 달성되었음에도 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
      
      ③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
      
      1. 파기절차
      토트 플랫폼은 파기 사유가 발생한 개인정보를 선정하고, 토트 플랫폼은 개인정보 보호 책임자의 승인을 받아 개인정보를 파기합니다.
      
      2. 파기방법
      전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.
      종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다
      
      
      제6조(정보 주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항) ① 정보 주체는 토트 플랫폼에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.
      
      ② 제1항에 따른 권리 행사는 토트 플랫폼에 대해 「개인정보 보호법」 시행령 제41조 제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 토트 플랫폼은 이에 대해 지체 없이 조치하겠습니다.
      
      ③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
      
      ④ 개인정보 열람 및 처리정지 요구는 「개인정보보호법」 제35조 제4항, 제37조 제2항에 의하여 정보 주체의 권리가 제한될 수 있습니다.
      
      ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
      
      ⑥ 토트 플랫폼은 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
      
      
      제7조(개인정보의 안전성 확보조치에 관한 사항) 토트 플랫폼은 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
      
      1. 관리적 조치 : 정기적인 자체 감사 실시, 내부관리계획 수립․ 시행, 정기적 직원 교육 등
      2. 기술적 조치 : 개인정보처리시스템 등의 접근 권한 관리, 개인정보의 암호화, 보안프로그램 설치, 접속기록의 보관 및 위변조 방지
      3. 물리적 조치 : 개인정보에 대한 접근통제, 문서보안을 위한 잠금장치 사용
      
      제8조(개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항)
      
      ① 토트 플랫폼은 이용자에게 개별적인 맞춤 서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
      
      ② 쿠키는 웹 사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도 합니다.
      가. 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안 접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.
      
      나. 쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구> 인터넷 옵션> 개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부할 수 있습니다.
      
      다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.
      
      제9조 (개인정보 보호 책임자에 관한 사항) 토트 플랫폼 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보 주체의 불만 처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호 책임자를 지정하고 있습니다.
      
      ▶ 개인정보 보호 책임자
      성명 : 이규선
      연락처 :031-8005-0000/000000000@dankook.ac.kr,
      
      ▶ 개인정보 보호 담당부서
      부서명 : 총무팀
      담당자 : 이규선
      연락처 : 031-8005-0000/0000000@dankook.ac.kr,
      
      제10조(개인정보의 열람 청구를 접수·처리하는 부서) 정보 주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 토트 플랫폼은 정보 주체의 개인정보 열람 청구가 신속하게 처리되도록 노력하겠습니다.

      ▶ 개인정보 열람청구 접수·처리 부서
      부서명 : 총무팀
      담당자 : 이규선
      연락처 : 031-8005-0000/0000000@dankook.ac.kr,
      
      
      제11조(정보주체의 권익침해에 대한 구제방법) ① 정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
      
      1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)
      2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
      3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)
      4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)
      
      ② 「개인정보보호법」 제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.
      
      ※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.
      
      제12조(개인정보 처리방침 변경) 이 개인정보처리방침은 2023년 1월 1부터 적용됩니다.
      `,
  },
];
