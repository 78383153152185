import React from 'react';
import styled from 'styled-components';
import { Fade } from 'react-awesome-reveal';

const Contents = () => {
  return (
    <>
      <Section>
        <ContentContainer>
          <FadeImageDiv>
            <Fade direction={'up'} duration={500} triggerOnce>
              <ContentImage src={require('assets/main_a.png')} />
            </Fade>
          </FadeImageDiv>
          <ContentDiv>
            <ContentTitle>아이디어 제시</ContentTitle>
            <Fade cascade damping={0.1}>
              <ContentExplanation>
                아이디어를 cEdu에 기록하여, 전문가를 포함한 여러 사람들과 함께
                의견을 구체화해 보세요.
              </ContentExplanation>
            </Fade>
          </ContentDiv>
        </ContentContainer>
      </Section>
      <Section>
        <ContentContainer>
          <ContentDiv>
            <ContentTitle>채택</ContentTitle>
            <Fade cascade damping={0.1}>
              <ContentExplanation>
                아이디어에 투표하고, 토론방에 입장해서 의견을 나눠보세요!
                토론에서 의견이 채택되면 해당 NFT의 지분을 가질 수 있어요.
              </ContentExplanation>
            </Fade>
          </ContentDiv>
          <FadeImageDiv>
            <Fade direction={'up'} duration={500} triggerOnce>
              <ContentImage src={require('assets/main_b.png')} />
            </Fade>
          </FadeImageDiv>
        </ContentContainer>
      </Section>

      <Section>
        <ContentContainer>
          <FadeImageDiv>
            <Fade direction={'up'} duration={500} triggerOnce>
              <ContentImage src={require('assets/main_c.png')} />
            </Fade>
          </FadeImageDiv>
          <ContentDiv>
            <ContentTitle>NFT 발급</ContentTitle>
            <Fade cascade damping={0.1}>
              <ContentExplanation>
                최종으로 아이디어가 채택이 되면 NFT가 발급이 됩니다. 원제시자는
                50%의 지분을 가질 수 있어요!
              </ContentExplanation>
            </Fade>
          </ContentDiv>
        </ContentContainer>
      </Section>
    </>
  );
};

const Section = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 100px;
`;

const ContentContainer = styled.div`
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 30px 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80%;
  background-color: #fff;
  border-radius: 20px;
  margin-top: 60px;
  box-shadow: 0px 10px 13px rgba(17, 38, 146, 0.05);
`;

const FadeImageDiv = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ContentImage = styled.img``;

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
`;

const ContentTitle = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  font-size: ${({ theme }) => theme.fonts.size.xxl};
  color: ${({ theme }) => theme.colors.main};
`;

const ContentExplanation = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  font-size: ${({ theme }) => theme.fonts.size.lg};
  color: ${({ theme }) => theme.colors.gray6};
  margin-top: 30px;
  line-height: 30px;
`;

export default Contents;
