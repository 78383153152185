import { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useRecoilState } from 'recoil';

import { LoginState } from 'atoms/LoginState';
import Modal from 'components/community/Modal';
import NoItem from 'components/community/idea/NoItem';
import { IdeaListProps } from 'components/post/IdeaProps';
import PageController from 'components/global/PageController';
import Cards from 'components/forum/Cards';
import AgreeModal from '@components/forum/AgreeModal';
import { appliedState } from 'atoms/ModalState';

import { ForumAgreeModalState } from 'atoms/ModalState';

function Forum(): JSX.Element {
  const sortBy = [
    { label: '최신순', value: 'latest' },
    { label: '추천순', value: 'vote' },
    { label: '조회순', value: 'view' },
  ];
  const [posts, setPosts] = useState<IdeaListProps[]>();
  const [sort, setSort] = useState<string>('최신순');
  const [order, setOrder] = useState<string>('latest');
  const [boardsCount, setBoardsCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [searchParams] = useSearchParams();
  const [isOpenModal, setIsOpenModal] = useRecoilState<boolean>(appliedState);
  const [isAccessible, setIsAccessible] = useState<boolean>(false);

  const [applied, setApplied] = useState<boolean>(false);
  const [currentTokenId, setCurrentTokenId] = useState<number>();
  const [cookies] = useCookies(['TOKEN', 'USER-INFORMATION', 'ISADMIN']);
  const [loginState] = useRecoilState(LoginState);
  const navigate = useNavigate();
  const [confirmation, setConfirmation] = useRecoilState(ForumAgreeModalState);

  const [guideModal, setGuideModal] = useState(false);

  const getPosts = useCallback(
    async (page: number) => {
      const params = { page: page, order: order, viewcount: 12 };

      try {
        const { data } = await axios({
          method: 'get',
          url: `/debate`,
          params: params,
        });
        setPosts(data.data.data);
        setBoardsCount(data.data.count);
      } catch (e) {
        console.error(e);
      }
    },
    [order]
  );

  const getOrder = (title: string) => {
    sortBy.forEach((item) => {
      if (item.label === title) {
        setOrder(item.value);
      }
    });
  };

  const getAccessibleUser = async (ideaId: string, field: number) => {
    setIsAccessible(false);
    const { data } = await axios({
      method: 'get',
      url: `/vote/${ideaId}`,
    });

    data.data.voters.forEach((user: string) => {
      if (
        user === cookies['USER-INFORMATION'].id ||
        cookies['USER-INFORMATION'].expertField.id === field
      ) {
        setIsAccessible(true);
      }
    });
  };

  const checkAccessRight = async (
    postId: number,
    ideaId: string,
    field: number
  ) => {
    setCurrentTokenId(postId);
    getAccessibleUser(ideaId, field);

    const config = {
      method: 'get',
      url: `/debate-access/one/${postId}`,
      headers: {
        Authorization: `Bearer ${cookies['TOKEN']}`,
      },
    };

    await axios(config)
      .then(function (response) {
        if (response.data.data || cookies.ISADMIN === 'true') {
          navigate(`/forum/idea?id=${postId}`);
        } else {
          setIsOpenModal(!isOpenModal);
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const setAppliedHandler = () => {
    setApplied(true);
  };

  const applyParticipation = async () => {
    try {
      const { data } = await axios({
        method: 'get',
        url: `/debate-access/addByTokenId`,
        params: {
          userId: cookies['USER-INFORMATION'].id,
          tokenId: currentTokenId,
        },
      });
      if (data.success) {
        navigate('/forum?page=1');
        setConfirmation(true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    let page = Number(searchParams.get('page'));
    setPage(Number(searchParams.get('page')));
    getPosts(page);
  }, [searchParams, order, page, getPosts]);

  useEffect(() => {
    navigate('/forum?page=1');
  }, [navigate, sort]);

  return (
    <>
      {isOpenModal && (
        <Modal>
          <>
            <ModalLogo>
              <img
                src={require('assets/modal-logo.png')}
                alt="modal logo"
                height={150}
              />
            </ModalLogo>
            <ModalDescription>
              {applied ? (
                <>
                  <span>참여 신청이 완료되었습니다.</span>
                  <span>토론방을 이용해주세요.</span>
                </>
              ) : (
                <span>접근 권한이 없습니다.</span>
              )}
            </ModalDescription>
            <Buttons>
              {isAccessible && !applied && (
                <ModalButton onClick={setAppliedHandler}>참여 신청</ModalButton>
              )}
              <ModalButton
                onClick={() => {
                  setIsOpenModal(!isOpenModal);
                }}
              >
                닫기
              </ModalButton>
              {applied && (
                <ModalButton onClick={applyParticipation}>
                  토론방 이동
                </ModalButton>
              )}
            </Buttons>
          </>
        </Modal>
      )}

      {confirmation && (
        <Modal>
          <AgreeModal applied={applied} />
        </Modal>
      )}

      <Wrapper>
        <ForumDiv>
          {/* <Title>토론 목록</Title> */}
          <Header>
            <SortContainer>
              <Select
                name="sort"
                value={sort}
                onChange={({ currentTarget }) => {
                  setSort(currentTarget.value);
                  getOrder(currentTarget.value);
                }}
              >
                {sortBy?.map((item, idx) => {
                  return (
                    <option key={idx} value={item.label}>
                      {item.label}
                    </option>
                  );
                })}
              </Select>
              <Arrow
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_592_2077)">
                  <path
                    d="M12 13.1722L16.95 8.22217L18.364 9.63617L12 16.0002L5.63599 9.63617L7.04999 8.22217L12 13.1722Z"
                    fill="#8E8E8E"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_592_2077">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </Arrow>
            </SortContainer>
          </Header>
          {boardsCount > 0 ? (
            <>
              <Container>
                {posts?.map((post, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      if (loginState) {
                        checkAccessRight(
                          Number(post.debate_tokenId),
                          String(post.ideaId),
                          Number(post.interest_id)
                        );
                      } else {
                        setIsOpenModal(!isOpenModal);
                      }
                    }}
                  >
                    <Cards id={post.debate_tokenId} post={post} />
                  </div>
                ))}
              </Container>
              <Page>
                <PageController
                  postCount={boardsCount}
                  currentPage={page}
                  uri={'forum'}
                  pagePostCount={12}
                />
              </Page>
            </>
          ) : (
            <NoItem description={'존재하는 아이디어가 없습니다.'} />
          )}
        </ForumDiv>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ForumDiv = styled.div`
  top: 300px;
  width: 1024px;
  margin-top: 100px;
`;

const Title = styled.div`
  margin: 20px;
  color: ${({ theme }) => theme.colors.gray6};
  font-size: ${({ theme }) => theme.fonts.size.xl};
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
`;

const Header = styled.div`
  display: grid;
  width: 70%;
  grid-template-columns: 1fr 3fr 7fr;
  margin: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
`;

const SortContainer = styled.div`
  display: flex;
  margin-right: 20px;
  width: 70px;
  height: 30px;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
`;

const Select = styled.select`
  all: unset;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  background-color: transparent;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  display: block;
  align-items: center;
  justify-content: flex-start;
  &:focus {
    border-color: ${({ theme }) => theme.colors.main};
  }
  z-index: 3;
  cursor: pointer;
`;

const Arrow = styled.svg`
  margin-left: -20px;
  width: 24px;
  height: 24px;
`;

const Page = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 80px;
`;

const ModalLogo = styled.div`
  align-item: center;
  justify-content: center;
  display: flex;
  margin: 30px;
`;

const ModalDescription = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.md};
  margin: 10px 40px;
  display: flex;
  flex-direction: column;
  align-item: center;
  justify-content: center;
  span {
    margin: auto;
  }
`;

const ModalButton = styled.button`
  width: 100px;
  height: 45px;
  cursor: pointer;
  border-radius: 10px;
  margin: 20px 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  background-color: ${({ theme }) => theme.colors.main};
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
  font-size: ${({ theme }) => theme.fonts.size.sm};
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-item: center;
  justify-content: center;
`;

export default Forum;
