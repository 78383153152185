import { useEffect } from "react";
import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import { ThemeProvider } from "styled-components";
import { theme } from "styles/Theme";
import { RecoilRoot } from "recoil";
import Router from "./Router";

const GlobalStyles = createGlobalStyle`
    ${reset}
    body {
        background-color: #FBFBFD;
        position: relative;
    }
    :root {
      font-family: 'Pretendard', sans-serif;
      @media (max-width: 780px) {
          font-size: 12px
      };
      @media (min-width: 781px) and (max-width: 1023px) {
        font-size: 14px
    };
  }
    #root {
      --vh: 100%;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
    a {
      text-decoration: none;
    }
  }
`;

function App() {
  const setVh = () => {
    document.documentElement.style.setProperty(
      "--vh",
      `${window.innerHeight}px`
    );
  };

  useEffect(() => {
    window.addEventListener("resize", setVh);
    setVh();
    return () => {
      document.documentElement.style.removeProperty("--vh");
      window.removeEventListener("resize", setVh);
    };
  }, []);

  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <div className="App">
          <GlobalStyles />
          <Router />
        </div>
      </ThemeProvider>
    </RecoilRoot>
  );
}

export default App;
