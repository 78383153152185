import { atom } from 'recoil';

export const ApplyExpertModalState = atom<boolean>({
  key: 'applyExpertState',
  default: false,
});

export const ExpertModalState = atom({
  key: 'ExpertModalState',
  default: false,
});

export const ProcessExpertModalState = atom<boolean>({
  key: 'processExpertState',
  default: false,
});

export const appliedState = atom({
  key: 'appliedState',
  default: false,
});

export const agreeModalState = atom({
  key: 'agreeModalState',
  default: false,
});

export const ForumAgreeModalState = atom({
  key: 'ForumAgreeModalState',
  default: false,
});

export const InformationFormState = atom({
  key: 'informationFromState',
  default: {
    id: '',
    userId: '',
    name: '',
    nickname: '',
    gender: '',
    birth: '',
    phonenumber: '',
    profile: '',
    isExpert: null,
    interests: [
      {
        id: 0,
        fieldName: '',
      },
    ],
    expertField: {
      id: 0,
      fieldName: '',
    },
    email: '',
    password: '',
  },
});
