import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { CommentProps, PostProps } from 'components/post/IdeaProps';
import { LabelItems } from 'components/sign-up/CategoryItems';
import { Viewer } from '@toast-ui/react-editor';
import { checkedIcon, unCheckedIcon } from 'assets/icons';
import FileBox from './FileBox';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import qs from 'qs';
import AdoptedIdea from './AdoptedIdea';
import { AdoptedIdeaProps } from './AdoptedIdeaProps';
import Modal from '@components/community/Modal';
import Evaluation from '../Evaluation';
import NoAuth from '@pages/NoAuth';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
`;

const Container = styled.div`
  ${({ theme }) => theme.media.mobile} {
    width: 90%;
  }

  /* width: 650px; */
  margin-top: 100px;
  width: 628px;

  display: flex;
  /* background-color: ${({ theme }) => theme.colors.white}; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 10px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray1};
`;

const HeaderBottom = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Category = styled.div`
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.gray4};
  font-size: 15px;
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
`;

const Title = styled.div`
  margin: 10px 0;
  color: ${({ theme }) => theme.colors.gray6};
  font-size: 26px;
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
`;

const Writer = styled.div`
  margin: 10px 0;
  color: ${({ theme }) => theme.colors.main};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
`;

const SummaryContainer = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
  justify-content: space-between;
`;

const Svg = styled.svg`
  margin-right: 5px;
  width: 16px;
  height: 16px;
`;

const Summary = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: ${({ theme }) => theme.colors.gray3};
    font-size: ${({ theme }) => theme.fonts.size.xs};
    font-weight: ${({ theme }) => theme.fonts.weight.medium};
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray1};
`;

const Top = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 2fr;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray1};
`;

const Image = styled.img`
  margin: 10px 0;
  width: 100%;
`;

const ViewContainer = styled.div<{ isDrop: boolean }>`
  max-height: ${(props) => (props.isDrop ? '' : '400px')};
  margin: ${(props) => props.isDrop && '20px 0'};
  overflow: hidden;
`;

const MoreViewButtonWrapper = styled.div<{ isDrop: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  position: relative;
  & ::before {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 100%;
    height: ${(props) => (props.isDrop ? '' : '50px')}; //그라데이션 높이
    background: linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 90%
    );
    content: '';
  }
`;

const CommentSection = styled.section`
  max-width: 1280px;
  width: 588px;
`;

const CommentCount = styled.div`
  margin: 10px;
  color: ${({ theme }) => theme.colors.gray6};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
`;

const CommentForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
`;

const CommentTitleInput = styled.textarea`
  ::placeholder {
    text-align: center;
  }
  all: unset;
  width: 588px;
  ${({ theme }) => theme.media.mobile} {
    width: 90%;
  }
  height: 50px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 10px;

  color: ${({ theme }) => theme.colors.gray6};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
`;

const CommentInput = styled.textarea`
  ::placeholder {
    text-align: center;
  }
  all: unset;
  width: 588px;
  ${({ theme }) => theme.media.mobile} {
    width: 90%;
  }
  height: 80px;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 10px;
  margin: 15px 0;

  color: ${({ theme }) => theme.colors.gray6};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
`;

const CommentBottom = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const FileInput = styled.div`
  width: 100%;
`;

const SubmitButton = styled.button<{ isChecked: boolean }>`
  all: unset;
  background-color: ${({ theme, isChecked }) =>
    isChecked ? theme.colors.error : theme.colors.main};
  color: ${({ theme }) => theme.colors.white};
  width: 100px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const CommentLists = styled.ul`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.media.mobile} {
    width: 240px;
  }
  width: 588px;
`;

const CommentListHeader = styled.div`
  display: flex;
  width: 100%;
`;

const CommentSort = styled.div<{ checked: boolean }>`
  margin: 20px;
  color: ${(props) =>
    props.checked
      ? ({ theme }) => theme.colors.gray6
      : ({ theme }) => theme.colors.gray3};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  cursor: pointer;
`;

const Comment = styled.li<{ isSelected: boolean; isClosingProposal: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 20px 0 10px 0;
  border: 1px solid ${({ theme }) => theme.colors.gray1};
  border-top: ${(props) =>
    props.isSelected
      ? props.isClosingProposal
        ? '2px solid #E25241'
        : '2px solid #74ABFF'
      : ''};
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  margin: 10px 0;
`;

const SelectedLabel = styled.div<{ isClosingProposal: boolean }>`
  margin-top: -21px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 25px;
  border-radius: 0 0 10px 10px;
  background: ${(props) =>
    props.isClosingProposal
      ? ({ theme }) => theme.colors.error
      : ({ theme }) => theme.colors.blue2};
  color: ${(props) =>
    props.isClosingProposal
      ? ({ theme }) => theme.colors.white
      : ({ theme }) => theme.colors.gray6};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const CommentHeader = styled.div<{
  isExpert: boolean;
  isClosingProposal: boolean;
}>`
  display: grid;
  grid-template-columns: 8fr 1fr 1.6fr;
  align-items: center;
  justify-content: center;
  width: 100% - 20px;
  height: 60px;
  padding: 0 10px;
  margin: 10px 0;
  flex-direction: row;
  border-radius: 10px;
  background: ${(props) =>
    props.isClosingProposal
      ? '#F3BAB3'
      : props.isExpert
      ? ({ theme }) => theme.colors.blue7
      : ({ theme }) => theme.colors.white};
  color: ${(props) =>
    props.isExpert
      ? ({ theme }) => theme.colors.white
      : ({ theme }) => theme.colors.gray6};
  box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.1);
`;

const CommentTitle = styled.div``;

const AuthorInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ProfileImage = styled.img`
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 30px;
  margin-bottom: 3px;
  border: 1px solid ${({ theme }) => theme.colors.gray1};
  background: ${({ theme }) => theme.colors.white};
`;

const ExpertTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 12px;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.white};

  span {
    margin-top: 2px;
    color: ${({ theme }) => theme.colors.gray6};
    font-size: ${({ theme }) => theme.fonts.size.xxs};
    font-weight: ${({ theme }) => theme.fonts.weight.regular};
  }
`;

const CommentInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const Information = styled.div`
  margin: 3px 0;
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
`;

const CommentText = styled.div`
  padding: 20px;
  white-space: pre-line;
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
`;

const CommentFiles = styled.div`
  display: flex;
  width: 450px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 20px auto;
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
`;

const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  flex-direction: column;
`;

const InputBox = styled.input`
  display: none;
`;

const Input = styled.input`
  all: unset;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  background-color: transparent;
  font-size: ${({ theme }) => theme.fonts.size.sm};
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CheckBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  flex-direction: row;
  span {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: ${({ theme }) => theme.fonts.size.sm};
    color: ${({ theme }) => theme.colors.gray6};
  }
`;

const InputButton = styled.button`
  width: 80px;
  height: 30px;
  margin-left: 10px;
  background-color: ${({ theme }) => theme.colors.gray1};
  color: ${({ theme }) => theme.colors.gray6};
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 10px;
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.colors.gray2};
  }
`;

const IconContainer = styled.div`
  display: flex;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  cursor: pointer;
`;

const EvaluationButton = styled.button`
  all: unset;
  background-color: ${({ theme }) => theme.colors.main};
  color: ${({ theme }) => theme.colors.white};
  width: 100px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const ModalLogo = styled.div`
  align-item: center;
  justify-content: center;
  display: flex;
  margin: 30px;
`;

const ModalDescription = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.md};
  margin: 10px;
`;

const ModalButton = styled.button`
  width: 80%;
  height: 45px;
  cursor: pointer;
  border-radius: 10px;
  margin: 20px;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  background-color: ${({ theme }) => theme.colors.main};
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
  font-size: ${({ theme }) => theme.fonts.size.sm};
`;

const FileContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  margin-left: 10px;
`;

const BottomContainer = styled.div`
  ${({ theme }) => theme.media.mobile} {
    width: 380px;
  }
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 480px;
  margin: 10px 0;
`;

const EtherButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blue1};
  width: 100px;
  color: ${({ theme }) => theme.colors.main};
  background-color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.sm};
`;

const ViewShareContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100px;
  height: 20px;
  border-radius: 5px;
  padding-top: 3px;
  margin: 0 20px;
  background: ${({ theme }) => theme.colors.blue6};
  color: ${({ theme }) => theme.colors.gray6};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.sm};
`;

const InformationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 50px 0;
`;

interface FileProps {
  file: File;
  name: string;
}

interface RecommendProps {
  id: string;
  isRecommended: boolean;
}

function Post(): JSX.Element {
  const [searchParams] = useSearchParams();
  const [postId, setPostId] = useState<number>(0);
  const [post, setPost] = useState<PostProps>();
  const [commentList, setCommentList] = useState<CommentProps[]>([]);
  const [isDrop, setIsDrop] = useState<boolean>(false);
  const [commentTitle, setCommentTitle] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [attachment, setAttachment] = useState<FileProps[]>([]);
  const [commentSort, setCommentSort] = useState<string>('추천순');
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [isRecommended, setIsRecommended] = useState<RecommendProps[]>([]);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [adoptedIdeaProps, setAdoptedIdeaProps] = useState<AdoptedIdeaProps>({
    tokenNumber: 0,
    originalToken: 0,
    owner: '',
    mintDate: '',
    contributors: 0,
    txHash: '',
  });
  const [isClosed, setIsClosed] = useState<boolean>();
  const [isEvaluationOpened, setIsEvaluationOpened] = useState<boolean>(false);
  const [isExpert, setIsExpert] = useState<boolean>(false);
  const [isEvaluated, setIsEvaluated] = useState<boolean>(false);
  const [isAccess, setIsAccess] = useState<boolean>(false);
  const [isAccessible, setIsAccessible] = useState<boolean>(false);
  const [cookies] = useCookies(['TOKEN', 'USER-INFORMATION']);
  const fileInput = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  // let commentSortList = ["추천순", "최신순"];

  const getCurrentPost = useCallback(
    async (postId: number) => {
      try {
        const { data } = await axios({
          method: 'get',
          url: `/idea/token/${postId}`,
        });
        setPost(data.data);
        const debate = await axios({
          method: 'get',
          url: `/debate/${postId}`,
        });

        if (isClosed === undefined && data.data.debate.closed) {
          setIsClosed(true);
        }

        let count = 0;
        const proposals = debate.data.data.proposals;
        for (let i = 0; i < proposals.length; i++) {
          if (proposals[i].isSelected) {
            count += 1;
          }
        }

        let date = data.data.debate.createdAt;
        const dateStr =
          date.substring(0, 4) +
          '년 ' +
          date.substring(5, 7) +
          '월 ' +
          date.substring(8, 10) +
          '일';

        const props = {
          tokenNumber: data.data.debate.finalTokenId,
          originalToken: postId,
          owner: data.data.author.nickname,
          mintDate: dateStr,
          contributors: count,
          txHash: data.data.debate.finalTokenTxHash,
        };
        setAdoptedIdeaProps(props);
        openEvaluationButton(postId, data.data.debate.closed);
      } catch (e) {
        navigate(-1);
      }
    },
    [isClosed, navigate]
  );

  const openEvaluationButton = async (postId: number, closed: boolean) => {
    const { data } = await axios({
      method: 'get',
      url: `/debate-access/expert/${postId}`,
    });

    if (data.data.includes(cookies['USER-INFORMATION'].id) && closed) {
      setIsExpert(true);
    }
  };

  const getComment = useCallback(
    async (postId: number) => {
      try {
        const { data } = await axios({
          method: 'get',
          url: `/proposal/${postId}`,
        });
        data.data.forEach((value: CommentProps) => {
          let flag = false;
          value.voters.forEach((voter: string, index: number) => {
            if (voter === cookies['USER-INFORMATION'].id) {
              flag = true;
            }
          });
          setIsRecommended((prev) => [
            ...prev,
            {
              id: value.id,
              isRecommended: flag,
            },
          ]);
        });
        setCommentList(data.data);
      } catch (e) {
        console.error(e);
      }
    },
    [cookies]
  );

  useEffect(() => {
    const postId = Number(searchParams.get('id'));
    setPostId(postId);
    getCurrentPost(postId);
    getComment(postId);
  }, [searchParams, isClicked, getCurrentPost, getComment]);

  const onClickDropButton = () => {
    setIsDrop(!isDrop);
  };

  const handleSubmit = async () => {
    const form = new FormData();

    form.append('tokenId', postId.toString());
    form.append('title', commentTitle);
    form.append('content', comment);

    if (attachment) {
      attachment.forEach((value) => form.append('attachments', value.file));
    }

    if (isChecked) {
      form.append('isClosingProposal', 'true');
    }

    try {
      await axios({
        method: 'post',
        url: `/proposal`,
        data: form,
        headers: {
          Authorization: `Bearer ${cookies['TOKEN']}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      getComment(postId);
      setCommentTitle('');
      setComment('');
      setAttachment([]);
      setIsChecked(false);
    } catch (e) {
      console.error(e);
    }
  };

  // const onClickSort = () => {
  //   if (commentSort === "추천순") {
  //     setCommentSort("최신순");
  //   } else {
  //     setCommentSort("추천순");
  //   }
  // };

  const onClickRecommend = async (id: string, index: number) => {
    await axios({
      method: 'post',
      url: `/vote-for-proposal`,
      data: qs.stringify({
        proposalId: id,
      }),
      headers: {
        Authorization: `Bearer ${cookies['TOKEN']}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    let copiedInfo = [...isRecommended];
    copiedInfo[index].isRecommended = !copiedInfo[index].isRecommended;
    setIsRecommended(copiedInfo);
    setIsClicked(!isClicked);
  };

  const handleClick = () => {
    fileInput.current?.click();
  };

  const onFileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    const file = target.files as FileList;
    setAttachment([]);

    if (file === undefined) {
      return;
    }

    Array.from(file).forEach((f) => {
      setAttachment((prev) => [
        ...prev,
        {
          file: f,
          name: f.name,
        },
      ]);
    });
  };

  const handleCancle = () => {
    setIsEvaluationOpened(!isEvaluationOpened);
  };

  const handleEvaluationOpen = async () => {
    const { data } = await axios({
      method: 'get',
      url: `/debate-evaluation/one/${postId}`,
      headers: {
        Authorization: `Bearer ${cookies['TOKEN']}`,
      },
    });

    if (data.data == null) {
      setIsEvaluationOpened(!isEvaluationOpened);
    } else {
      setIsEvaluated(!isEvaluated);
    }
  };

  return (
    <>
      {isEvaluated && (
        <Modal>
          <>
            <ModalLogo>
              <img
                src={require('assets/modal-logo.png')}
                alt="modal logo"
                height={150}
              />
            </ModalLogo>
            <ModalDescription>이미 평가한 토론방입니다.</ModalDescription>
            <ModalButton onClick={() => setIsEvaluated(false)}>
              확인
            </ModalButton>
          </>
        </Modal>
      )}
      {isClosed && (
        <Modal>
          <>
            <ModalLogo>
              <img
                src={require('assets/modal-logo.png')}
                alt="modal logo"
                height={150}
              />
            </ModalLogo>
            <ModalDescription>종료된 토론방입니다.</ModalDescription>
            <ModalButton onClick={() => setIsClosed(false)}>확인</ModalButton>
          </>
        </Modal>
      )}
      {isEvaluationOpened && (
        <Modal>
          <Evaluation tokenId={postId} handleCancle={handleCancle} />
        </Modal>
      )}
      <Wrapper>
        <Container>
          <ContentBox>
            <Header>
              <Category>
                {post?.interestField.id !== undefined
                  ? LabelItems.slice(1)[post?.interestField.id - 1].title
                  : ''}
              </Category>
              <Title>{post?.title}</Title>
              <Writer>{post?.author.nickname}</Writer>
              <HeaderBottom>
                <SummaryContainer>
                  <Summary>
                    <Svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_867_392)">
                        <path
                          d="M7.99998 14.6663C4.31798 14.6663 1.33331 11.6817 1.33331 7.99967C1.33331 4.31767 4.31798 1.33301 7.99998 1.33301C11.682 1.33301 14.6666 4.31767 14.6666 7.99967C14.6666 11.6817 11.682 14.6663 7.99998 14.6663ZM7.99998 13.333C9.41447 13.333 10.771 12.7711 11.7712 11.7709C12.7714 10.7707 13.3333 9.41416 13.3333 7.99967C13.3333 6.58519 12.7714 5.22863 11.7712 4.22844C10.771 3.22824 9.41447 2.66634 7.99998 2.66634C6.58549 2.66634 5.22894 3.22824 4.22874 4.22844C3.22855 5.22863 2.66665 6.58519 2.66665 7.99967C2.66665 9.41416 3.22855 10.7707 4.22874 11.7709C5.22894 12.7711 6.58549 13.333 7.99998 13.333ZM8.66665 7.99967H11.3333V9.33301H7.33331V4.66634H8.66665V7.99967Z"
                          fill="#838383"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_867_392">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </Svg>
                    <span>{post?.createdAt.substring(0, 10)}</span>
                  </Summary>
                  <Summary>
                    <Svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_867_386)">
                        <path
                          d="M6.66671 2H9.33337C10.7479 2 12.1044 2.5619 13.1046 3.5621C14.1048 4.56229 14.6667 5.91885 14.6667 7.33333C14.6667 8.74782 14.1048 10.1044 13.1046 11.1046C12.1044 12.1048 10.7479 12.6667 9.33337 12.6667V15C6.00004 13.6667 1.33337 11.6667 1.33337 7.33333C1.33337 5.91885 1.89528 4.56229 2.89547 3.5621C3.89567 2.5619 5.25222 2 6.66671 2ZM8.00004 11.3333H9.33337C9.85866 11.3333 10.3788 11.2299 10.8641 11.0289C11.3494 10.8278 11.7904 10.5332 12.1618 10.1618C12.5332 9.79033 12.8279 9.34937 13.0289 8.86407C13.2299 8.37877 13.3334 7.85862 13.3334 7.33333C13.3334 6.80805 13.2299 6.2879 13.0289 5.8026C12.8279 5.3173 12.5332 4.87634 12.1618 4.50491C11.7904 4.13347 11.3494 3.83883 10.8641 3.63782C10.3788 3.4368 9.85866 3.33333 9.33337 3.33333H6.66671C5.60584 3.33333 4.58843 3.75476 3.83828 4.50491C3.08813 5.25505 2.66671 6.27247 2.66671 7.33333C2.66671 9.74 4.30804 11.3107 8.00004 12.9867V11.3333Z"
                          fill="#838383"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_867_386">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </Svg>
                    <span>{commentList?.length}</span>
                  </Summary>
                </SummaryContainer>
                {isExpert && (
                  <EvaluationButton onClick={handleEvaluationOpen}>
                    평가하기
                  </EvaluationButton>
                )}
              </HeaderBottom>
            </Header>
            {adoptedIdeaProps.tokenNumber && (
              <InformationContainer>
                <AdoptedIdea props={adoptedIdeaProps} />
                <BottomContainer>
                  <ViewShareContainer
                    onClick={() =>
                      navigate(
                        `/forum/idea/share?id=${adoptedIdeaProps.originalToken}`
                      )
                    }
                  >
                    지분 보기
                  </ViewShareContainer>
                  <EtherButton
                    onClick={() =>
                      window.open(
                        `https://goerli.etherscan.io/tx/${adoptedIdeaProps.txHash}`,
                        '_blank'
                      )
                    }
                  >
                    <Svg>
                      <svg
                        width="10"
                        height="10"
                        viewBox="0 0 101 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M80.8074 93.8876H7.47404V18.8876H47.474V12.2209H0.807373V100.554H87.474V53.8876H80.8074V93.8876Z"
                          fill="#1F56F1"
                        />
                        <path
                          d="M60.8074 0.554199V7.22087H89.4267L36.7838 59.864L41.4976 64.5777L94.1407 11.9348V40.5542H100.807V0.554199H60.8074Z"
                          fill="#1F56F1"
                        />
                      </svg>
                    </Svg>
                    <div>EtherScan</div>
                  </EtherButton>
                </BottomContainer>
              </InformationContainer>
            )}
            <Body>
              <Top>
                <Image src={post?.thumbnail} />
                <FileContainer>
                  {post?.attachments.map((item, id) => {
                    return <FileBox key={id} detail={item} />;
                  })}
                </FileContainer>
              </Top>
              {isDrop && (
                <ViewContainer isDrop={isDrop}>
                  <Viewer initialValue={post?.content} />
                </ViewContainer>
              )}
              <MoreViewButtonWrapper isDrop={isDrop}>
                {isDrop ? (
                  <Svg
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    cursor={'pointer'}
                    onClick={onClickDropButton}
                  >
                    <path
                      d="M7.00026 2.82734L2.05026 7.77734L0.636257 6.36334L7.00026 -0.000656128L13.3643 6.36334L11.9503 7.77734L7.00026 2.82734Z"
                      fill="black"
                    />
                  </Svg>
                ) : (
                  <Svg
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    cursor={'pointer'}
                    onClick={onClickDropButton}
                  >
                    <path
                      d="M6.99974 5.17266L11.9497 0.222656L13.3637 1.63666L6.99974 8.00066L0.635742 1.63666L2.04974 0.222656L6.99974 5.17266Z"
                      fill="black"
                    />
                  </Svg>
                )}
              </MoreViewButtonWrapper>
            </Body>
            {!post?.debate.closed && (
              <CommentSection>
                <CommentCount>의견 {commentList?.length}</CommentCount>
                <CommentForm>
                  <CommentTitleInput
                    maxLength={45}
                    placeholder="제목 추가 (45자 이내)"
                    value={commentTitle}
                    required
                    onChange={(e) => setCommentTitle(e.currentTarget.value)}
                  />
                  <CommentInput
                    placeholder="새 의견을 제안해 주세요"
                    value={comment}
                    required
                    onChange={(e) => setComment(e.currentTarget.value)}
                  />
                  <CommentBottom>
                    <InputContainer>
                      <Label>
                        <InputButton onClick={handleClick}>
                          파일 업로드
                        </InputButton>
                      </Label>
                      <FileInput>
                        <InputWrapper>
                          <InputBox
                            ref={fileInput}
                            type="file"
                            multiple
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => onFileHandler(e)}
                          />
                          <Input
                            placeholder="선택된 파일 없음"
                            value={
                              attachment.length > 1
                                ? `파일 ${attachment.length}개`
                                : attachment[0]
                                ? attachment[0].name.slice(0, 20) + '...'
                                : '선택된 파일 없음'
                            }
                            readOnly
                          />
                        </InputWrapper>
                      </FileInput>
                    </InputContainer>
                    {cookies['USER-INFORMATION'].id === post?.author.id && (
                      <CheckBox>
                        <IconContainer onClick={() => setIsChecked(!isChecked)}>
                          {isChecked ? (
                            <>{checkedIcon()}</>
                          ) : (
                            <>{unCheckedIcon()}</>
                          )}
                        </IconContainer>
                        <span>토론 종료</span>
                      </CheckBox>
                    )}

                    <SubmitButton onClick={handleSubmit} isChecked={isChecked}>
                      제안하기
                    </SubmitButton>
                  </CommentBottom>
                </CommentForm>
              </CommentSection>
            )}
          </ContentBox>
        </Container>
        {commentList && commentList?.length > 0 && (
          <CommentLists>
            <CommentListHeader>
              {/* {commentSortList.map((item, id) => {
                  return (
                    <CommentSort
                      key={id}
                      checked={item === commentSort}
                      onClick={onClickSort}
                    >
                      {item}
                    </CommentSort>
                  );
                })} */}
            </CommentListHeader>
            {isRecommended.length > 0 &&
              commentList.map((comment, index) => (
                <Comment
                  key={index}
                  isSelected={comment.isSelected}
                  isClosingProposal={comment.isClosingProposal}
                >
                  {comment.isSelected &&
                    (comment.isClosingProposal ? (
                      <SelectedLabel
                        isClosingProposal={comment.isClosingProposal}
                      >
                        토론 종료
                      </SelectedLabel>
                    ) : (
                      <SelectedLabel
                        isClosingProposal={comment.isClosingProposal}
                      >
                        채택 완료
                      </SelectedLabel>
                    ))}
                  <CommentHeader
                    isExpert={comment.userId.isExpert}
                    isClosingProposal={comment.isClosingProposal}
                  >
                    <CommentTitle>{comment.title}</CommentTitle>
                    <AuthorInfo>
                      <ProfileImage
                        src={
                          comment?.userId.profile?.length > 0
                            ? process.env.REACT_APP_S3_BUCKET_URL +
                              comment.userId.profile
                            : require('@assets/clicked-general-member.png')
                        }
                      />
                      {comment.userId.isExpert && (
                        <ExpertTag>
                          <span>전문가</span>
                        </ExpertTag>
                      )}
                    </AuthorInfo>
                    <CommentInfo>
                      <Information>{comment.userId.nickname}</Information>
                      <Information>
                        채택수 {comment.userId.selectedProposal}
                      </Information>
                    </CommentInfo>
                  </CommentHeader>
                  <CommentText>{comment.content}</CommentText>
                  {comment.attachments.length > 0 && (
                    <CommentFiles>
                      {comment.attachments.map((item, id) => {
                        return <FileBox key={id} detail={item} />;
                      })}
                    </CommentFiles>
                  )}
                  <SummaryContainer>
                    <Summary>
                      <Svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_931_1805)">
                          <path
                            d="M8.00004 14.6673C4.31804 14.6673 1.33337 11.6827 1.33337 8.00065C1.33337 4.31865 4.31804 1.33398 8.00004 1.33398C11.682 1.33398 14.6667 4.31865 14.6667 8.00065C14.6667 11.6827 11.682 14.6673 8.00004 14.6673ZM8.00004 13.334C9.41453 13.334 10.7711 12.7721 11.7713 11.7719C12.7715 10.7717 13.3334 9.41514 13.3334 8.00065C13.3334 6.58616 12.7715 5.22961 11.7713 4.22941C10.7711 3.22922 9.41453 2.66732 8.00004 2.66732C6.58555 2.66732 5.229 3.22922 4.2288 4.22941C3.22861 5.22961 2.66671 6.58616 2.66671 8.00065C2.66671 9.41514 3.22861 10.7717 4.2288 11.7719C5.229 12.7721 6.58555 13.334 8.00004 13.334ZM8.66671 8.00065H11.3334V9.33398H7.33337V4.66732H8.66671V8.00065Z"
                            fill="#3C3C3C"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_931_1805">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </Svg>
                      <span>{comment.createdAt.substring(0, 10)}</span>
                    </Summary>
                    <Summary>
                      <Svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        xmlns="http://www.w3.org/2000/svg"
                        cursor={post?.debate.closed ? 'default' : 'pointer'}
                        onClick={
                          post?.debate.closed
                            ? () => {}
                            : () => onClickRecommend(comment.id, index)
                        }
                      >
                        <g clipPath="url(#clip0_931_1799)">
                          <path
                            d="M9.73329 5.33359H14C14.3536 5.33359 14.6927 5.47407 14.9428 5.72412C15.1928 5.97417 15.3333 6.31331 15.3333 6.66693V8.06959C15.3335 8.24384 15.2995 8.41642 15.2333 8.57759L13.17 13.5876C13.1196 13.7097 13.0341 13.8142 12.9243 13.8876C12.8145 13.9611 12.6854 14.0003 12.5533 14.0003H1.33329C1.15648 14.0003 0.986912 13.93 0.861888 13.805C0.736864 13.68 0.666626 13.5104 0.666626 13.3336V6.66693C0.666626 6.49012 0.736864 6.32055 0.861888 6.19552C0.986912 6.0705 1.15648 6.00026 1.33329 6.00026H3.65463C3.76136 6.00029 3.86653 5.97469 3.96131 5.92562C4.0561 5.87654 4.13771 5.80543 4.19929 5.71826L7.83463 0.566928C7.88058 0.501803 7.94835 0.455302 8.02565 0.435855C8.10295 0.416408 8.18466 0.425303 8.25596 0.460928L9.46529 1.06559C9.80563 1.2357 10.0775 1.51714 10.2358 1.86314C10.394 2.20914 10.4292 2.59888 10.3353 2.96759L9.73329 5.33359ZM4.66663 7.05893V12.6669H12.1066L14 8.06959V6.66693H9.73329C9.53022 6.6669 9.32985 6.62049 9.14744 6.53123C8.96504 6.44198 8.80544 6.31224 8.68081 6.15192C8.55618 5.9916 8.46981 5.80492 8.4283 5.60614C8.38679 5.40736 8.39124 5.20173 8.44129 5.00493L9.04329 2.63959C9.06213 2.56581 9.05514 2.4878 9.02349 2.41855C8.99183 2.34929 8.93741 2.29296 8.86929 2.25893L8.42863 2.03893L5.28863 6.48693C5.12196 6.72293 4.90863 6.91626 4.66663 7.05893ZM3.33329 7.33359H1.99996V12.6669H3.33329V7.33359Z"
                            fill={
                              isRecommended[index].isRecommended
                                ? '#1F56F1'
                                : '#3C3C3C'
                            }
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_931_1799">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </Svg>
                      <span>{comment?.voters.length}</span>
                    </Summary>
                  </SummaryContainer>
                </Comment>
              ))}
          </CommentLists>
        )}
      </Wrapper>
    </>
  );
}

const ContentBox = styled.div`
  width: 588px;
  min-width: 588px;

  display: flex;
  flex-direction: column;
`;

export default Post;
