import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import Footer from 'components/global/footer/Footer';
import MyPageBar from 'components/mypage/nav/MyPageBar';
import MyPageBar2 from 'components/mypage/nav/MyPageBar2';
import Header from './header/Header';

const Wrapper = styled.div`
  width: 1024px;
  display: flex;
  justify-content: center;

  /* flex-direction: row; */

  /* justify-content: space-between; */
`;

const Container = styled.div`
  display: flex;
  margin-top: 30px;
`;

const LayoutDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const OutletDiv = styled.div`
  width: 940px;
`;

function MyPageLayout(): JSX.Element {
  return (
    <LayoutDiv>
      <Header page={'main'} />
      <Wrapper>
        <Container>
          <MyPageBar2 />
          <OutletDiv>
            <Outlet />
          </OutletDiv>
        </Container>
      </Wrapper>
      <Footer />
    </LayoutDiv>
  );
}

export default MyPageLayout;
