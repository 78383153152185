import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Wrapper = styled.footer`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 51px;
  background-color: white;
  /* position: absolute; */
  bottom: 0;
`;

const Container = styled.div`
  display: flex;
  max-width: 1920px;
  width: 100%;
  justify-content: space-between;
`;

const Policy = styled.div`
  padding-top: 6px;
  padding-left: 20px;
`;

const Span = styled.span`
  padding-left: 20px;
  font-size: ${({ theme }) => theme.fonts.size.xs};
`;

const Logo = styled.div`
  align-item: center;
  justify-content: center;
  display: flex;
  padding-right: 40px;
`;

const PolicyLink = styled(Link)`
  color: black;
`;

function Footer(): JSX.Element {
  return (
    <Wrapper>
      <Container>
        <Policy>
          <Span>
            <PolicyLink to="/policy">Privacy Policy</PolicyLink>
          </Span>
          <Span>
            <PolicyLink to="/terms">Terms of Service</PolicyLink>
          </Span>
        </Policy>
        <Logo>
          <img src={require('assets/cedu-logo.png')} alt="logo" height={30} />
        </Logo>
      </Container>
    </Wrapper>
  );
}

export default Footer;
