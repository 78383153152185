import { Desktop, Mobile, Tablet } from 'hooks/MediaQuery';
import MobileHome from 'components/home/MobileHome';
import TabletHome from 'components/home/TabletHome';
import DesktopHome from 'components/home/DesktopHome';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { HomeProps } from 'components/home/HomeProps';
import HeaderToMain from '@components/global/HeaderToMain';

function Home(): JSX.Element {
  const [countList, setCountList] = useState<HomeProps>({
    ideaCount: 0,
    nftCount: 0,
    ongoingDebateCount: 0,
  });

  const getCountList = async () => {
    try {
      const { data } = await axios({
        method: 'get',
        url: `/idea/mainpage`,
      });
      setCountList(data.data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getCountList();
  }, []);

  return (
    <>
      <Desktop>
        <DesktopHome countList={countList} />
      </Desktop>
      <Tablet>
        <TabletHome countList={countList} />
      </Tablet>
      <Mobile>
        <MobileHome countList={countList} />
      </Mobile>
    </>
  );
}

export default Home;
