import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

function PageController({
  postCount,
  currentPage,
  uri,
  pagePostCount,
}: {
  postCount: number;
  currentPage: number;
  uri: string;
  pagePostCount: number;
}) {
  const [pageCount, setPageCount] = useState(0);
  const url = (page: number) =>
    uri.includes('?') ? `${uri}&page=${page}` : `/${uri}?page=${page}`;

  useEffect(() => {
    setPageCount(Math.ceil(postCount / pagePostCount));
  }, [postCount, pagePostCount]);

  return (
    <Container>
      {currentPage !== 1 && (
        <Link to={url(currentPage - 1)}>
          <Svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="32"
              y="32"
              width="32"
              height="32"
              rx="4"
              transform="rotate(180 32 32)"
              fill="#90BFFF"
            />
            <g clipPath="url(#clip0_1032_387)">
              <path
                d="M14.8283 15.9993L19.7783 20.9493L18.3643 22.3633L12.0003 15.9993L18.3643 9.63528L19.7783 11.0493L14.8283 15.9993Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_1032_387">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(28 28) rotate(180)"
                />
              </clipPath>
            </defs>
          </Svg>
        </Link>
      )}
      <Indexes>
        {Array.from({ length: pageCount }, (_, i) => (
          <Index
            to={url(i + 1)}
            cur={(i + 1 === currentPage).toString()}
            key={i}
          >
            <Page cur={i + 1 === currentPage}>{i + 1}</Page>
          </Index>
        ))}
      </Indexes>
      {currentPage !== pageCount && (
        <Link to={url(currentPage + 1)}>
          <Svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="32" height="32" rx="4" fill="#90BFFF" />
            <g clipPath="url(#clip0_674_271)">
              <path
                d="M17.1717 16.0007L12.2217 11.0507L13.6357 9.63672L19.9997 16.0007L13.6357 22.3647L12.2217 20.9507L17.1717 16.0007Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_674_271">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(4 4)"
                />
              </clipPath>
            </defs>
          </Svg>
        </Link>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
`;

const Svg = styled.svg`
  margin: 4px 5px 0 5px;
  width: 26px;
  height: 26px;
`;

const Indexes = styled.ol`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Index = styled(Link)<{ cur: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  width: 24px;
  height: 24px;
  border: 2px solid
    ${(props) =>
      props.cur === 'true'
        ? ({ theme }) => theme.colors.main
        : ({ theme }) => theme.colors.blue3};
  border-radius: 4px;
`;

const Page = styled.div<{ cur: boolean }>`
  color: ${(props) =>
    props.cur
      ? ({ theme }) => theme.colors.main
      : ({ theme }) => theme.colors.blue3};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  font-size: ${({ theme }) => theme.fonts.size.sm};
`;

export default PageController;
