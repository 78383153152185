import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { HomeProps } from './HomeProps';
import useIntersectionObsever from 'hooks/UseIntersectionObserver';

const IdeaCount = ({ countList }: { countList: HomeProps }): JSX.Element => {
  const [scroll, setScroll] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const isInViewport = useIntersectionObsever(ref);

  const duration = 2000;
  const start = 0;
  const frameRate = 1000 / 60;

  const totalFrame = Math.round(duration / frameRate);

  const [ideaCount, setIdeaCount] = useState<number>(start);
  const [nftCount, setNftCount] = useState<number>(start);
  const [debateCount, setDebateCount] = useState<number>(start);

  function easeOutExpo(t: number): number {
    return t === 1 ? 1 : 1 - Math.pow(2, -10 * t);
  }

  useEffect(() => {
    let currentNumber = start;
    const counter = setInterval(() => {
      const progress = easeOutExpo(++currentNumber / totalFrame);
      setIdeaCount(Math.round(countList.ideaCount * progress));
      setNftCount(Math.round(countList.nftCount * progress));
      setDebateCount(Math.round(countList.ongoingDebateCount * progress));

      if (progress === 1) {
        clearInterval(counter);
      }
    }, frameRate);

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll); //clean up
    };
  }, [isInViewport, scroll]);

  const handleScroll = () => {
    if (isInViewport) {
      setScroll(true);
      return;
    }
  };

  return (
    <IdeaCountDiv>
      <ElementDiv>
        <IdeaElement>
          <Count ref={ref}>{ideaCount}+</Count>
          <CountTitle>제안된 아이디어</CountTitle>
        </IdeaElement>
        <IdeaElement>
          <Count>{nftCount}+</Count>
          <CountTitle>발급된 아이디어 NFT</CountTitle>
        </IdeaElement>
        <IdeaElement>
          <Count>{debateCount}+</Count>
          <CountTitle>현재 토론 진행 중인 아이디어</CountTitle>
        </IdeaElement>
      </ElementDiv>
    </IdeaCountDiv>
  );
};

const IdeaCountDiv = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  text-align: center;

  background: #3577fc;
`;

const IdeaElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 276px;
  flex-direction: column;
  align-items: center;
  margin: 0 100px 200px 100px;
`;

const ElementDiv = styled.div`
  display: flex;
  margin-top: 100px;
`;

const Count = styled.div`
  top: 100px;
  text-align: center;
  color: white;
  font-size: 50.5px;
`;

const CountTitle = styled.div`
  text-align: center;
  color: white;
  font-size: 21.33px;
  margin-top: 20px;
`;

export default IdeaCount;
