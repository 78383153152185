import { MenuProps } from "./MenuProps";

// TODO: 각 페이지 만들어지면 경로 수정v
export const MenuItems: MenuProps[] = [
  {
    id: 1,
    title: "Idea",
    path: "/mypage/idea",
  },
  {
    id: 2,
    title: "Vote",
    path: "/mypage/vote",
  },
  {
    id: 3,
    title: "NFT",
    path: "/mypage/nft",
  },
  {
    id: 4,
    title: "정보 수정",
    path: "/mypage/privacy",
  },
];
