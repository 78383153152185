import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { AdoptedIdeaProps } from "./AdoptedIdeaProps";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ContainerBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  ${({ theme }) => theme.media.mobile} {
    width: 320px;
  }
  position: relative;
  display: flex;
  flex-direction: column;
  width: 420px;
  background: ${({ theme }) => theme.colors.gray7};
  padding: 30px;
  border-radius: 10px;
`;

const Stamp = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  opacity: 0.7;
`;

const Title = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
  font-size: ${({ theme }) => theme.fonts.size.md};
  margin-bottom: 10px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

const Logo = styled.div`
  display: flex;
`;

const ExplanationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0 0 20px;
`;

const TokenNumber = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  font-size: ${({ theme }) => theme.fonts.size.md};
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const DetailRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: space-between;
`;

const DetailTitle = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
  font-size: ${({ theme }) => theme.fonts.size.base};
`;

const DetailContent = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: ${({ theme }) => theme.fonts.size.base};
  color: ${({ theme }) => theme.colors.gray3};
  margin-left: 30px;
`;

function AdoptedIdea({ props }: { props: AdoptedIdeaProps }): JSX.Element {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Box>
        <ContainerBox>
          <Container>
            <Title>최종 채택된 아이디어입니다!</Title>
            <Stamp>
              <img
                src={require("assets/final-adopted-mark.png")}
                alt="member"
                height={140}
              />
            </Stamp>
            <ContentContainer>
              <Logo>
                <img
                  src={require("assets/rectangle-logo.png")}
                  alt="member"
                  height={140}
                />
              </Logo>
              <ExplanationContainer>
                <TokenNumber>IDEA TOKEN #{props.tokenNumber}</TokenNumber>
                <DetailContainer>
                  <DetailRowContainer>
                    <DetailTitle>Original idea token ID</DetailTitle>
                    <DetailContent>{props.originalToken}</DetailContent>
                  </DetailRowContainer>
                  <DetailRowContainer>
                    <DetailTitle>Owned by</DetailTitle>
                    <DetailContent>{props.owner}</DetailContent>
                  </DetailRowContainer>
                  <DetailRowContainer>
                    <DetailTitle>Minted at</DetailTitle>
                    <DetailContent>{props.mintDate}</DetailContent>
                  </DetailRowContainer>
                  <DetailRowContainer>
                    <DetailTitle>Contributors</DetailTitle>
                    <DetailContent>
                      {props.contributors.toLocaleString()}
                    </DetailContent>
                  </DetailRowContainer>
                </DetailContainer>
              </ExplanationContainer>
            </ContentContainer>
          </Container>
        </ContainerBox>
      </Box>
    </Wrapper>
  );
}

export default AdoptedIdea;
