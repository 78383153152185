import styled from 'styled-components';
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';

import PageController from 'components/global/PageController';
import { useCookies } from 'react-cookie';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px;
  max-width: 1290px;
  width: 100%;
  margin-top: 100px;
`;

const CategoryTitle = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.md};
  color: ${({ theme }) => theme.colors.gray4};
  margin-bottom: 10px;
`;

const CategoryDetailTitle = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.xl};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  color: ${({ theme }) => theme.colors.gray6};
  margin-bottom: 40px;
`;

const BoardContainer = styled.div`
  width: 100%;
  box-shadow: 0px 3px 3px 3px rgba(0, 0, 0, 0.05);
`;

const BoardHead = styled.div`
  width: 100%;
  border-collapse: collapse;
`;

const Row = styled.div<{ idx: number }>`
  ${({ theme }) => theme.media.mobile} {
    padding: 15px 0 15px 10px;
  }
  width: 100% - 40px;
  padding: 15px 0 15px 30px;
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.9fr 2.1fr 0.5fr 0.7fr 0.6fr;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray1};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  color: ${({ theme }) => theme.colors.gray5};
  background: ${(props) =>
    props.idx % 2 === 0
      ? ({ theme }) => theme.colors.white
      : ({ theme }) => theme.colors.gray0};
  div {
    display: flex;
    place-items: center;
    &:last-child {
      border-right: none;
    }
    flex-wrap: wrap;
    word-break: break-all;
  }
  &:first-child {
    font-size: ${({ theme }) => theme.fonts.size.base};
    font-weight: ${({ theme }) => theme.fonts.weight.medium};
    color: ${({ theme }) => theme.colors.gray4};
    background: ${({ theme }) => theme.colors.gray7};
    border-bottom: none;
    padding: 15px 0 15px 30px;
    ${({ theme }) => theme.media.mobile} {
      padding: 15px 0 15px 10px;
    }
  }
`;

const Page = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

const ToggleBar = styled.div<{ isBlocked: boolean }>`
  ${({ theme }) => theme.media.mobile} {
    width: 25px;
    height: 17px;
  }
  position: relative;
  width: 40px;
  height: 24px;
  margin-right: 5px;
  border-radius: 14px;
  background-color: ${({ theme, isBlocked }) =>
    isBlocked === true ? theme.colors.main : theme.colors.gray1};
  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  :active {
    div {
      transform: scale(0.9);
      box-shadow: 0 0 1px 1.5px rgba(0, 0, 0, 0.1) inset;
    }
  }
`;

const Toggle = styled.div<{ isBlocked: boolean }>`
  ${({ theme }) => theme.media.mobile} {
    width: 10px;
    height: 10px;
    top: 3.5px;
    left: ${({ isBlocked }) => (isBlocked === true ? '11.5px' : '4px')};
  }
  position: absolute;
  top: 4px;
  left: ${({ isBlocked }) => (isBlocked === true ? '20px' : '4px')};
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
  transition: background-color 0.2s ease-in-out, left 0.2s ease-in-out;
`;

const SortList = styled.div`
  display: flex;
  width: 100%;
  justify-conent: flex-start;
  align-items: center;
  margin-bottom: 15px;
`;

const Sort = styled.div<{ checked: boolean }>`
  padding: 1px 15px;
  color: ${(props) =>
    props.checked
      ? ({ theme }) => theme.colors.gray6
      : ({ theme }) => theme.colors.gray3};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  cursor: pointer;
  border-right: 2px solid ${({ theme }) => theme.colors.blue4};
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    border: 0;
  }
`;

interface UserProps {
  id: string;
  createdAt: string;
  nickname: string;
  email: string;
  isBlocked: boolean;
  isExpert: boolean;
  isAdmin: boolean;
  walletAddress: string;
  NFTCount: number;
}

function UserPage(): JSX.Element {
  let sortList = [
    {
      label: '전체',
      value: 'all',
    },
    {
      label: '일반 회원',
      value: 'general',
    },
    {
      label: '전문가',
      value: 'expert',
    },
  ];

  const [cookies] = useCookies(['TOKEN']);
  const [clickToggle, setClickToggle] = useState<boolean>(false);
  const [boardsCount, setBoardsCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [userInfo, setUserInfo] = useState<UserProps[]>([]);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [sort, setSort] = useState({
    label: '전체',
    value: 'all',
  });

  const getList = useCallback(
    async (page: Number) => {
      const { data } = await axios({
        method: 'get',
        url: `/admin/user?page=${page}&type=${sort.value}`,
        headers: {
          Authorization: `Bearer ${cookies['TOKEN']}`,
        },
      });

      setUserInfo(data.data.data);
      setBoardsCount(data.data.count);
    },
    [cookies, sort]
  );

  useEffect(() => {
    let page = Number(searchParams.get('page'));
    setPage(Number(searchParams.get('page')));
    getList(page);
  }, [searchParams, page, clickToggle, sort, getList]);

  useEffect(() => {
    navigate(`/admin/user-management?page=1`);
  }, [navigate, sort]);

  const onClickToggle = async (idx: number, userId: string) => {
    await axios({
      method: 'patch',
      url: `/admin/block/${userId}`,
      headers: {
        Authorization: `Bearer ${cookies['TOKEN']}`,
      },
    });

    setClickToggle(!clickToggle);
  };

  return (
    <Wrapper>
      <Container>
        <CategoryTitle>회원</CategoryTitle>
        <CategoryDetailTitle>유저 관리</CategoryDetailTitle>
        <SortList>
          <>
            {sortList.map((item, index) => {
              <Sort
                key={index}
                checked={item.label === sort.label}
                onClick={() => setSort(item)}
              >
                {item.label}
              </Sort>;
            })}
          </>
        </SortList>
        <BoardContainer>
          <BoardHead>
            <Row idx={-1}>
              <div>No</div>
              <div>Nickname</div>
              <div>Email</div>
              <div>Wallet Address</div>
              <div>회원 차단</div>
              <div>전문가 여부</div>
              <div>보유중인 NFT</div>
            </Row>
          </BoardHead>
          {userInfo.map(
            (item, idx) =>
              item.isAdmin === false && (
                <Row key={idx} idx={idx}>
                  <div>{9 * (page - 1) + idx + 1}</div>
                  <div>{item.nickname}</div>
                  <div>{item.email}</div>
                  <div>{item.walletAddress}</div>
                  <div>
                    <ToggleBar
                      isBlocked={item.isBlocked}
                      onClick={() => onClickToggle(idx, item.id)}
                    >
                      <Toggle isBlocked={item.isBlocked} />
                    </ToggleBar>
                  </div>
                  <div>
                    {item.isExpert === true ? '전문가회원' : '일반회원'}
                  </div>
                  <div>{item.NFTCount.toLocaleString()}</div>
                </Row>
              )
          )}
        </BoardContainer>
        <Page>
          <PageController
            postCount={boardsCount}
            currentPage={page}
            pagePostCount={9}
            uri={'admin/user-management'}
          />
        </Page>
      </Container>
    </Wrapper>
  );
}

export default UserPage;
