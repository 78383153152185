import React, { useState } from 'react';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { ExpertModalState } from 'atoms/ModalState';

const ExpertModal = (props: any) => {
  const { onClick } = props;
  const [isOpen, setIsOpen] = useRecoilState(ExpertModalState);
  const [message, setMessage] = useState([
    '일반회원으로 가입 하셨을 경우, 마이페이지에서 전문가 신청이 가능합니다. (아이디 중복가입 불가)',
  ]);

  return (
    <ModalDiv>
      <ModalLogo>
        <img
          src={require('assets/modal-logo.png')}
          alt="modal logo"
          height={150}
        />
      </ModalLogo>
      <ModalDescription>{message}</ModalDescription>

      <ModalButton
        onClick={() => {
          setIsOpen(true);
        }}
      >
        확인
      </ModalButton>
    </ModalDiv>
  );
};

const ModalDescription = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  font-size: 16px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  span {
    margin-bottom: 5px;
  }
`;

const ModalDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 639px;
  height: 600px;
  background-color: white;
`;

const ModalLogo = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 30px;
`;

const ModalButton = styled.button`
  width: 200px;
  height: 40px;
  cursor: pointer;
  border-radius: 18.5px;
  background-color: #ffffff;
  color: #1f56f1;
  border: 1.5px solid #1f56f1;
  border-radius: 25px;
  margin-top: 40px;
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  &:hover {
    background-color: #1f56f1;
    color: white;
  }
`;

export default ExpertModal;
