import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import useIntersectionObsever from 'hooks/UseIntersectionObserver';
import { HomeProps } from './HomeProps';
import MobileContent from './MobileContent';
import { ContentData } from './ContentsData';
import Statistics from './Statistics';
import Footer from '@components/global/footer/Footer';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 320px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 591px;
  background: linear-gradient(162deg, #1f56f1 24.24%, #6d94ff 88.72%);
  justify-content: center;
  align-items: center;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  font-size: 28.43px;
  color: ${({ theme }) => theme.colors.white};
  line-height: 25px;
`;

const HeaderExplanation = styled.div`
  display: flex;
  font-weight: ${({ theme }) => theme.fonts.weight.semibold};
  font-size: 16px;
  color: ${({ theme }) => theme.colors.white};
  margin-top: 20px;
  line-height: 15px;
`;

const MoreButton = styled.button<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 191px;
  height: 51px;
  font-weight: ${({ theme }: any) => theme.fonts.weight.semibold};
  font-size: ${({ theme }: any) => theme.fonts.size.base};
  color: ${(props: any) => props.color};
  background: ${(props: any) => props.bg};
  border-radius: 28px;
  border: ${(props: any) => props.border};
  :hover {
    background: ${({ theme }: any) => theme.colors.blue5};
  }
  cursor: pointer;
`;

const Svg = styled.svg`
  margin-right: 5px;
`;

const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 28px;
`;

const MainLogo = styled.img`
  width: 236.999px;
  height: 193.983px;
`;

const SubLogo = styled.img`
  position: absolute;
  width: 40px;
  height: 55px;
  left: 80px;
  top: 90px;
`;

const Body = styled.div`
  width: 100%;
  height: 1146px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
`;

const ContentImage = styled.img`
  margin: 0 10px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: 0 10px;
`;

const ContentTitle = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  font-size: ${({ theme }) => theme.fonts.size.lg};
  color: ${({ theme }) => theme.colors.main};
`;

const ContentExplanation = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  font-size: ${({ theme }) => theme.fonts.size.base};
  color: ${({ theme }) => theme.colors.gray6};
  margin-top: 30px;
  line-height: 23px;
`;

const FooterElement = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
`;

const FooterCount = styled.div`
  position: absolute;
  top: 32px;
  text-align: center;
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  font-size: ${({ theme }) => theme.fonts.size.xl};
  color: ${({ theme }) => theme.colors.white};
`;

const FooterTitle = styled.div`
  position: absolute;
  top: 54px;
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  font-size: ${({ theme }) => theme.fonts.size.xs};
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
`;

function easeOutExpo(t: number): number {
  return t === 1 ? 1 : 1 - Math.pow(2, -10 * t);
}

const MobileContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 76px;
  width: 100%;
`;

const ButtonDiv = styled.div`
  width: 100%;
  height: 128px;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 36px;
`;

function MobileHome({ countList }: { countList: HomeProps }): JSX.Element {
  const navigate = useNavigate();
  // const [scroll, setScroll] = useState(false);
  // const ref = useRef<HTMLDivElement>(null);
  // const isInViewport = useIntersectionObsever(ref);

  // const start = 0;
  // const duration = 2000;
  // const [ideaCount, setIdeaCount] = useState<number>(start);
  // const [nftCount, setNftCount] = useState<number>(start);
  // const [debateCount, setDebateCount] = useState<number>(start);
  // const frameRate = 1000 / 60;
  // const totalFrame = Math.round(duration / frameRate);

  // useEffect(() => {
  //   let currentNumber = start;
  //   const counter = setInterval(() => {
  //     const progress = easeOutExpo(++currentNumber / totalFrame);
  //     setIdeaCount(Math.round(countList.ideaCount * progress));
  //     setNftCount(Math.round(countList.nftCount * progress));
  //     setDebateCount(Math.round(countList.ongoingDebateCount * progress));

  //     if (progress === 1) {
  //       clearInterval(counter);
  //     }
  //   }, frameRate);

  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll); //clean up
  //   };
  // }, [isInViewport, scroll]);

  // const handleScroll = () => {
  //   if (isInViewport) {
  //     setScroll(true);
  //     return;
  //   }
  // };

  const onClick = () => {
    navigate('/community?page=1');
  };

  return (
    <Wrapper>
      <MobileContainer>
        <Header>
          <HeaderContainer>
            <HeaderTitle>
              <div>탈 중앙 집단지성 기반</div>
              <div>아이디어 서비스</div>
            </HeaderTitle>
            <HeaderExplanation>
              의견을 다른 사람과 함께 완성하고 블록체인에 기록하세요
            </HeaderExplanation>
            <ButtonDiv>
              <MoreButton
                onClick={() => navigate('/community?page=1')}
                color="white"
                bg="black"
                border="none"
              >
                <Svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.172 6.99992L6.808 1.63592L8.222 0.221924L16 7.99992L8.222 15.7779L6.808 14.3639L12.172 8.99992H0V6.99992H12.172Z"
                    fill="#fff"
                  />
                </Svg>
                아이디어 구경하기
              </MoreButton>
              {/* <MoreButton
                onClick={onClick}
                color="black"
                bg="white"
                border="2px solid black"
              >
                <Svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="#000"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.172 6.99992L6.808 1.63592L8.222 0.221924L16 7.99992L8.222 15.7779L6.808 14.3639L12.172 8.99992H0V6.99992H12.172Z"
                    fill="#000"
                  />
                </Svg>
                아이디어 등록하기
              </MoreButton> */}
            </ButtonDiv>
          </HeaderContainer>
          <LogoContainer>
            <MainLogo src={require('assets/dan_main.png')} />
          </LogoContainer>
        </Header>
        <Body>
          {ContentData.map((itm, idx) => (
            <MobileContent
              key={idx}
              title={itm.title}
              desc={itm.desc}
              img={itm.img}
            />
          ))}
          <Statistics countList={countList} />
          <Footer />
        </Body>
      </MobileContainer>
    </Wrapper>
  );
}

export default MobileHome;
