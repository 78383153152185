import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import Footer from 'components/global/footer/Footer';
import Navigation from './header/Header';
import MenuBar from 'components/admin/MenuBar';
import MenuBar2 from 'components/admin/MenuBar2';

const Wrapper = styled.div`
  display: flex;
  width: 1024px;
  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
  }
  margin-top: 80px;
`;

const AdminLayoutDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

function AdminLayout(): JSX.Element {
  return (
    <AdminLayoutDiv>
      <Navigation page={'main'} />
      <Wrapper>
        <MenuBar />
        <Outlet />
      </Wrapper>
      <Footer />
    </AdminLayoutDiv>
  );
}

export default AdminLayout;
