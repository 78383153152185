import { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 100px;
  padding: 40px;
  width: 40%;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 15px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 3px 5px 0px ${({ theme }) => theme.colors.gray2};
`;

const Success = styled.div``;
const Fail = styled.div``;

function Invitation(): JSX.Element {
  const [token, setToken] = useState<string>();
  const [success, setSuccess] = useState<boolean>();
  const [debateRoomInfo, setDebateRoomInfo] = useState<IDebateRoom | null>(
    null
  );

  useEffect(() => {
    const query = window.location.search;

    const params = new URLSearchParams(query);

    let token = params.get('token');
    if (token) {
      setToken(token);
      let debateRoomInfo: IDebateRoom = jwt_decode(token);
      setDebateRoomInfo(debateRoomInfo);

      axios
        .get(
          // `${process.env.REACT_APP_API_URL}/debate-access/add?token=${token}`
          `http://49.50.165.200:8080/debate-access/add?token=${token}`
        )
        .then((res) => {
          res.data.success ? setSuccess(true) : setSuccess(false);
        });
    }
  }, []);
  return (
    <Wrapper>
      <Container>
        {success ? (
          <Success>토론방 #{debateRoomInfo?.tokenId}에 참여되셨습니다.</Success>
        ) : (
          <Fail>
            유효하지 않은 토론방입니다. 진행 중인 토론방이 맞는지 확인해주세요
          </Fail>
        )}
      </Container>
    </Wrapper>
  );
}

export default Invitation;

interface IDebateRoom {
  userId: string;
  tokenId: number;
  title: string;
  thumbnail: string;
}
