import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useRecoilState } from 'recoil';
import { LoginState } from 'atoms/LoginState';

import MainLayout from 'components/global/MainLayout';
import UserLayout from 'components/global/UserLayout';
import Policy from 'components/global/footer/sub-routes/PrivacyPolicy';
import Terms from 'components/global/footer/sub-routes/Terms';
import Home from 'pages/Home';
import Login from 'pages/Login';
import SignUp from 'pages/SignUp';
import Community from 'pages/Community';
import Editor from 'components/community/Editor';
import CommunityPost from 'components/community/idea/Post';
import ForumPost from 'components/forum/idea/Post';
import Forum from 'pages/Forum';
import GeneralMember from 'pages/sign-up/GeneralMember';
import ExpertMember from 'pages/sign-up/ExpertMember';
import Invitation from 'pages/Invitation';
import Idea from 'components/mypage/Idea';
import Vote from 'components/mypage/Vote';
import NFT from 'components/mypage/NFT';
import Privacy from 'components/mypage/Privacy';
import MyPageLayout from 'components/global/MyPageLayout';
import AdminLayout from 'components/global/AdminLayout';
import ExpertPage from 'components/admin/ExpertPage';
import UserPage from 'components/admin/UserPage';
import Share from 'pages/Share';
import NotFound from 'pages/NotFound';
import NoAuth from 'pages/NoAuth';
import UserAuth from 'components/forum/UserAuth';
import Navigation from '@components/global/header/Header';
import ScrollTop from 'components/global/ScrollTop';
import OperatePage from '@components/admin/OperatePage';

function Router(): JSX.Element {
  const [loginState, setLoginState] = useRecoilState(LoginState);
  const [cookies] = useCookies(['TOKEN', 'ISADMIN']);

  useEffect(() => {
    setLoginState(!!cookies['TOKEN']);
  }, [cookies, setLoginState]);

  const noAuth = (
    isAccessible: boolean,
    setIsAccess: (value: boolean) => void
  ) => {
    return <NoAuth isAccessible={isAccessible} setIsAccess={setIsAccess} />;
  };

  return (
    <BrowserRouter>
      <ScrollTop />
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/community" element={<Community />} />
          <Route
            path="/community/editor"
            element={loginState ? <Editor /> : <Login />}
          />
          <Route
            path="/community/idea"
            element={loginState ? <CommunityPost /> : <Login />}
          />
          <Route path="/forum" element={<Forum />} />
          <Route
            path="/forum/idea"
            element={
              loginState ? (
                <UserAuth forumPost={<ForumPost />} noAuth={noAuth} />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/forum/idea/share"
            element={loginState ? <Share /> : <Login />}
          />
        </Route>
        <Route
          element={
            loginState ? (
              <MyPageLayout />
            ) : (
              <>
                <Navigation page={'user'} />
                <Login />
              </>
            )
          }
        >
          <Route path="/mypage/idea" element={<Idea />} />
          <Route path="/mypage/vote" element={<Vote />} />
          <Route path="/mypage/nft" element={<NFT />} />
          <Route path="/mypage/privacy" element={<Privacy />} />
        </Route>
        <Route element={<UserLayout />}>
          <Route
            path="/login"
            element={loginState ? <Navigate to="/" /> : <Login />}
          />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/sign-up/general" element={<GeneralMember />} />
          <Route path="/sign-up/expert" element={<ExpertMember />} />
          <Route path="/invitation" element={<Invitation />} />
        </Route>
        <Route
          element={
            cookies['ISADMIN'] === 'true' ? (
              <AdminLayout />
            ) : (
              <>
                <Navigation page={'user'} />
                <Login />
              </>
            )
          }
        >
          <Route path="/admin/user-management" element={<UserPage />} />
          <Route path="/admin/expert-approval" element={<ExpertPage />} />
          <Route path="/admin/operate" element={<OperatePage />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
