import Footer from 'components/global/footer/Footer';
import { Outlet } from 'react-router-dom';
import Header from './header/Header';
import styled from 'styled-components';

function MainLayout({ children }: any, { ...rest }): JSX.Element {
  return (
    <MainDiv>
      <Header page={'main'} />
      {/* <Content>{children}</Content> */}
      <Outlet />
      <Footer />
    </MainDiv>
  );
}

const MainDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export default MainLayout;
