import styled from "styled-components";
import { FileProps } from "./FileProps";

const URL = process.env.REACT_APP_S3_BUCKET_URL;

const Download = styled.div`
  display: flex;
  width: 90%;
  height: 60px;
  margin: 5px 0;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  display: grid;
  grid-template-columns: 0.3fr 2fr 0.3fr;
  div {
    margin: auto 0;
  }
  ${({ theme }) => theme.media.mobile} {
    padding: 0 10px;
  }
`;

const FolderIcon = styled.div`
  text-align: right;
`;

const Data = styled.div`
  padding-left: 10px;
`;

const Name = styled.div`
  color: ${({ theme }) => theme.colors.gray6};
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
`;

const DownloadIcon = styled.div`
  cursor: pointer;
`;

const Svg = styled.svg`
  width: 24px;
  height: 24px;
  ${({ theme }) => theme.media.mobile} {
    width: 20px;
    height: 20px;
  }
`;

function FileBox({ detail }: { detail: FileProps }): JSX.Element {
  const temp = detail?.fileName.split(".");
  let extension = temp[temp.length - 1];
  let fileName =
    detail?.fileName[0] === "h"
      ? detail?.fileName
          .split("/")[4]
          .substring(14)
          .slice(0, -Number(extension.length) - 1)
      : detail?.fileName
          .split("/")[1]
          .substring(14)
          .slice(0, -Number(extension.length) - 1);

  if (fileName.length >= 30) {
    fileName = fileName.substring(0, 31) + "...";
  }
  fileName += "." + extension;

  return (
    <Download>
      <FolderIcon>
        <Svg
          width="24"
          height="26"
          viewBox="0 0 24 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23.25 9.25V24.2412C23.2512 24.4054 23.22 24.5682 23.1582 24.7203C23.0965 24.8724 23.0053 25.0108 22.8901 25.1277C22.7748 25.2446 22.6377 25.3376 22.4864 25.4015C22.3352 25.4654 22.1729 25.4989 22.0088 25.5H1.99125C1.66227 25.5 1.34674 25.3694 1.114 25.1369C0.881252 24.9044 0.750331 24.589 0.75 24.26V1.74C0.75 1.06875 1.30875 0.5 1.9975 0.5H14.5V8C14.5 8.33152 14.6317 8.64946 14.8661 8.88388C15.1005 9.1183 15.4185 9.25 15.75 9.25H23.25ZM23.25 6.75H17V0.50375L23.25 6.75Z"
            fill="#3C3C3C"
          />
        </Svg>
      </FolderIcon>
      <Data>
        <Name>{fileName}</Name>
      </Data>
      <DownloadIcon>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={
            detail?.fileName[0] === "h"
              ? detail?.fileName
              : URL + detail?.fileName
          }
        >
          <Svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_837_1390)">
              <path
                d="M3.75 15V25H26.25V15"
                stroke="#838383"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16 6.66602C16 6.11373 15.5523 5.66602 15 5.66602C14.4477 5.66602 14 6.11373 14 6.66602L16 6.66602ZM14.2929 20.7065C14.6834 21.097 15.3166 21.097 15.7071 20.7065L22.0711 14.3425C22.4616 13.952 22.4616 13.3188 22.0711 12.9283C21.6805 12.5378 21.0474 12.5378 20.6569 12.9283L15 18.5851L9.34315 12.9283C8.95262 12.5378 8.31946 12.5378 7.92893 12.9283C7.53841 13.3188 7.53841 13.952 7.92893 14.3425L14.2929 20.7065ZM14 6.66602L14 19.9993L16 19.9993L16 6.66602L14 6.66602Z"
                fill="#838383"
              />
            </g>
            <defs>
              <clipPath id="clip0_837_1390">
                <rect width="30" height="30" fill="white" />
              </clipPath>
            </defs>
          </Svg>
        </a>
      </DownloadIcon>
    </Download>
  );
}

export default FileBox;
