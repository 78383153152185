import { atom } from 'recoil';
import { selector } from 'recoil';

export const emailAddressState = atom({
  key: 'emailAddressState',
  default: '',
});

export const emailIdState = atom({
  key: 'emailIdState',
  default: '',
});

export const birthState = atom<string>({
  key: 'birthState',
  default: '',
});

export const informationState = selector({
  key: 'informationState',
  get: ({ get }) => {
    const information = get(emailAddressState);
    return information;
  },
});
