import styled from 'styled-components';
import { Card } from 'antd';
import { IdeaListProps } from '../../post/IdeaProps';
import { LabelItems } from '@components/sign-up/CategoryItems';

const Idea = styled.div`
  width: 241px;
  height: 398px;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  box-shadow: 5px 3px 11px rgba(17, 38, 146, 0.08);
  border-radius: 10px;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 225px;
  justify-content: space-between;
`;

const Profile = styled.img`
  width: 22px;
  height: 22px;
  margin-right: 4px;
  object-fit: cover;
  border-radius: 30px;
  border: 0.5px solid ${({ theme }) => theme.colors.gray2};
`;

const Info = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 4px;
`;

const Category = styled.div`
  color: ${({ theme }) => theme.colors.gray6};
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
`;

const Writer = styled.div`
  color: ${({ theme }) => theme.colors.gray3};
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
`;

const Thumbnail = styled.img`
  width: 225px;
  height: 244px;
  object-fit: cover;
  border-radius: 15px;
  margin-top: 12px;
  margin-bottom: 12px;
`;

const SummaryContainer = styled.div`
  justify-content: space-between;
  display: flex;
  width: 225px;
`;

const Summary = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${({ theme }) => theme.colors.gray5};
  /* font-size: ${({ theme }) => theme.fonts.size.xxs}; */
  font-size: 12px;
  font-weight: ${({ theme }) => theme.fonts.weight.regular};

  span {
    margin: 2px 20px 0 0;
    color: ${({ theme }) => theme.colors.gray3};
    font-size: 16px;
  }
`;

const Svg = styled.svg`
  margin-right: 5px;
  width: 16px;
  height: 16px;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.gray6};
  height: 48px;
  width: 225px;
  /* font-size: ${({ theme }) => theme.fonts.size.sm}; */
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
`;

const User = styled.div`
  display: flex;
  height: 38px;
  width: 225px;
`;

const CardContainer = styled.div`
  width: 250px;
  height: 380px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const Progress = styled.div<any>`
  text-align: right;
  width: 100px;
  height: 19px;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  color: ${(props: any) =>
    props.progress === 1
      ? '#29BC49'
      : props.progress === 2
      ? '#1F56F1'
      : '#828282'};
`;

function Cards({ id, post }: { id: string; post: IdeaListProps }): JSX.Element {
  return (
    <Idea key={id}>
      <CardContainer>
        <CardHeader>
          <User>
            <Profile
              src={
                post?.author_profile?.length > 0
                  ? process.env.REACT_APP_S3_BUCKET_URL + post.author_profile
                  : require('@assets/clicked-general-member.png')
              }
            />
            <Info>
              <Category>
                {LabelItems.slice(1)[post.interest_id - 1].title}
              </Category>
              <Writer>{post.author_nickname.slice(0, 6)}</Writer>
            </Info>
          </User>
          <Progress progress={post.state}>
            {post.state === 1
              ? '채택진행중'
              : post.state === 2
              ? '채택완료'
              : '미채택'}
          </Progress>
        </CardHeader>
        <Thumbnail src={post.thumbnail || 'error'} />
        <Title>
          {post.title.length > 14
            ? `${post.title.slice(0, 23)}...`
            : post.title}
        </Title>
        <SummaryContainer>
          <Summary>
            <Svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_709_543)">
                <path
                  d="M21.4094 15.0703L21.3646 14.9896L20.1722 12.802L18.9888 10.6413L18.9708 10.6055C18.666 10.0317 18.0743 9.67304 17.4377 9.66407L18.1191 8.98269C18.4688 8.62407 18.4688 8.05924 18.1191 7.70959L11.5653 1.1558C11.2156 0.806141 10.6508 0.806141 10.3012 1.1558L6.01565 5.44131C5.66599 5.79097 5.66599 6.36476 6.01565 6.71442L8.9653 9.66407H8.60668C8.02392 9.66407 7.49496 9.942 7.16323 10.3992C7.10944 10.462 7.06461 10.5337 7.02875 10.6055L5.82737 12.802L4.63496 14.9896L4.59013 15.0703C4.51841 15.1958 4.48254 15.3392 4.48254 15.4917V24.4572C4.48254 24.9592 4.88599 25.3537 5.3791 25.3537H20.6205C21.1136 25.3537 21.517 24.9592 21.517 24.4572V15.4917C21.517 15.3392 21.4812 15.1958 21.4094 15.0703ZM7.91634 6.07787L10.9288 3.06545L16.2184 8.34614L14.9005 9.66407L13.197 11.3586L7.91634 6.07787ZM8.60668 11.4572H10.7674L12.5694 13.2592C12.9191 13.6089 13.4839 13.6089 13.8336 13.2592L15.6356 11.4572H17.4019L19.1053 14.5951H6.89427L8.60668 11.4572ZM19.7239 23.5606H6.27565V16.3882H19.7239V23.5606Z"
                  fill="#8E8E8E"
                />
                <path
                  d="M16.1377 12.8018C16.1377 13.3039 15.7342 13.6984 15.2411 13.6984H10.7584C10.6239 13.6984 10.4894 13.6715 10.3729 13.6087C10.1846 13.528 10.0411 13.3756 9.95147 13.1873C9.89768 13.0708 9.86182 12.9453 9.86182 12.8018C9.86182 12.3087 10.2653 11.9053 10.7584 11.9053H11.2156L12.5694 13.2591C12.9191 13.6087 13.4839 13.6087 13.8335 13.2591L15.1873 11.9053H15.2411C15.7342 11.9053 16.1377 12.3087 16.1377 12.8018Z"
                  fill="#8E8E8E"
                />
              </g>
              <defs>
                <clipPath id="clip0_709_543">
                  <rect
                    width="17.0345"
                    height="24.4579"
                    fill="white"
                    transform="translate(4.48254 0.895508)"
                  />
                </clipPath>
              </defs>
            </Svg>

            <span>{post.idea_vote_count}</span>
          </Summary>

          {Number(post.state) > 1 ? (
            <Summary>마감</Summary>
          ) : (
            <Summary>
              마감까지
              {` ${post.dueDate[0]}일 ${post.dueDate[1]}시간 ${post.dueDate[2]}분 `}
              남음
            </Summary>
          )}
        </SummaryContainer>
      </CardContainer>
    </Idea>
  );
}

export default Cards;
